import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminPost,
  valiadteEmail,
  valiadteNumber,
} from "../../redux/Authentication/AuthSlice";
import {
  createEmployee,
  emailExists,
  mobileExists,
} from "../../redux/Employee/employeeSlice";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import {
//   adminPost,
//   valiadteEmail,
//   valiadteNumber,
// } from "../../../redux/Authentication/AuthSlice";

const useStyles = makeStyles({
  textfield: {
    // Example margin
    height: "35px",
    backgroundColor: "red", // Adjust height to a more practical value
  },
});

const AddEmployee = () => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((store) => store.auth);
  const [email, setEmail] = useState("");
  const [Cnfemail, setCnfEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailExistError, setEmailExistError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCnf, setMobileCnf] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [mobileExistError, setMobileExistError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [Password, setPassword] = useState("");
  const [PasswordError, setPasswordError] = useState("");

  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState("");
  const [allowedState, setAllowedState] = useState([]);
  const [allowedStateError, setAllowedStateError] = useState("");
  const [btnPress, setbtnPress] = useState(false);
  const [twoWheelerCharge, setTwoWheelerCharge] = useState("");
  const [threeWheelerCharge, setThreeWheelerCharge] = useState("");
  const [FourWheelerCharge, setFourWheelerCharge] = useState("");
  const [constructionEqpCharge, setConstructionEqpCharge] = useState("");
  const [comvehicleSCVCharge, setComvehicleSCVCharge] = useState("");
  const [comvehicleHCVCharge, setComvehicleHCVCharge] = useState("");
  const [comvehicleLCVCharge, setComvehicleLCVCharge] = useState("");
  const [farmEqpCharge, setFarmEqpCharge] = useState("");

  const [filterState, setFilterState] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const stateList = [
    { id: 1, name: "Andhra Pradesh" },
    { id: 2, name: "Arunachal Pradesh" },
    { id: 3, name: "Assam" },
    { id: 4, name: "Bihar" },
    { id: 5, name: "Chhattisgarh" },
    { id: 6, name: "Goa" },
    { id: 7, name: "Gujarat" },
    { id: 8, name: "Haryana" },
    { id: 9, name: "Himachal Pradesh" },
    { id: 10, name: "Jharkhand" },
    { id: 11, name: "Karnataka" },
    { id: 12, name: "Kerala" },
    { id: 13, name: "Madhya Pradesh" },
    { id: 14, name: "Maharashtra" },
    { id: 15, name: "Manipur" },
    { id: 16, name: "Meghalaya" },
    { id: 17, name: "Mizoram" },
    { id: 18, name: "Nagaland" },
    { id: 19, name: "Odisha" },
    { id: 20, name: "Punjab" },
    { id: 21, name: "Rajasthan" },
    { id: 22, name: "Sikkim" },
    { id: 23, name: "Tamil Nadu" },
    { id: 24, name: "Telangana" },
    { id: 25, name: "Tripura" },
    { id: 26, name: "Uttar Pradesh" },
    { id: 27, name: "Uttarakhand" },
    { id: 28, name: "West Bengal" },
    { id: 29, name: "Andaman and Nicobar Islands" },
    { id: 30, name: "Chandigarh" },
    { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
    { id: 32, name: "Delhi" },
    { id: 33, name: "Ladakh" },
    { id: 34, name: "Lakshadweep" },
    { id: 35, name: "Puducherry" },
  ];

  const [charges, setCharges] = useState([]);

  useEffect(() => {
    if (loginData && loginData.role === "businessAssociate") {
      const state = loginData.permittedState.map((indState, index) => ({
        id: index,
        name: indState,
      }));
      setFilterState(state);
    }
  }, [loginData]);

  const handleState = (state) => {
    if (!allowedState.includes(state)) {
      setAllowedState([...allowedState, state]);
    } else {
      const stateList = allowedState.filter((indState) => indState !== state);
      setAllowedState(stateList);
    }
    setCharges([...charges, {
        state: state,
        twoWheelerCharge: '',
        threeWheelerCharge: '',
        fourWheelerCharge: '',
        constructionEqpCharge: '',
        commercialSCVVehicleCharge: '',
        commercialHCVVehicleCharge: '',
        commercialLCVVehicleCharge: ''
      }]);
  };

  useEffect(() => {
    if (btnPress === true) {
      if (name === "") {
        setNameError("please enter title");
      } else {
        setNameError("");
      }
      if (Password === "") {
        setPasswordError("please enter Password");
      } else {
        setPasswordError("");
      }
      if (Cnfemail === "") {
        setEmailError("please enter email");
      } else {
        setEmailError("");
      }
      if (mobileCnf.length !== 10) {
        setMobileError("please enter mobile");
      } else {
        setMobileError("");
      }
      if (role === "") {
        setRoleError("Please select one of the role");
      } else {
        setRoleError("");
      }
      if (allowedState.length === 0) {
        setAllowedStateError("Please select one of the state");
      } else {
        setAllowedStateError("");
      }
    }
  }, [btnPress, name, Password, Cnfemail, mobileCnf, role, allowedState]);

  const setNumberChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileError("");
    setMobileExistError("");
    setMobileCnf(value);
    if (value.length === 10) {
      const verifyNumber = await dispatch(mobileExists(value));
      if (verifyNumber.payload.success) {
        setMobileError("Number already exist");
        setMobileExistError("Mobile number already exists");
      } else {
        setMobileExistError("");
        setMobileError("");
        setMobileCnf(value);
      }
    } else {
      setMobileError("");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const emailChange = async (email) => {
    setEmail(email);
    setEmailError("");
    setEmailExistError("");

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    const verifyEmail = await dispatch(emailExists(email));

    if (verifyEmail.payload.success) {
      setEmailExistError("Email already exists.");
      setEmailError("Email already exists.");
    } else {
      setCnfEmail(email);
    }
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and contain at least one number and one special character."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleKeyUp = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // Validate password on each keyup event
    // validatePassword(newPassword);
  };

  const handleBlur = () => {
    // Validate password on blur event
    validatePassword(Password);
  };

  const handleChange = (e, state, chargeType) => {
    const { value } = e.target;
    setCharges(prevCharges =>
      prevCharges.map(charge =>
        charge.state === state ? { ...charge, [chargeType]: value } : charge
      )
    );
  };

  const cancelClick = async (e) => {
    e.preventDefault();
    setEmail("");
    setCnfEmail("");
    setMobile("");
    setMobileCnf("");
    setName("");
    setPassword("");
    setRole("");
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (name === "") {
      setNameError("please enter title");
    } else {
      setNameError("");
    }
    if (Password === "") {
      setPasswordError("please enter Password");
    } else {
      setPasswordError("");
    }
    if (Cnfemail === "") {
      setEmailError("please enter email");
    } else {
      setEmailError("");
    }
    if (mobileCnf.length !== 10) {
      setMobileError("please enter mobile");
    } else {
      setMobileError("");
    }
    if (role === "") {
      setRoleError("Please select role");
    } else {
      setRoleError("");
    }
    if (allowedState.length === 0) {
      setAllowedStateError("Please select one of the state");
    } else {
      setAllowedStateError("");
    }
    if (
      name !== "" &&
      Password !== "" &&
      Cnfemail !== "" &&
      mobileCnf !== "" &&
      role !== "" &&
      allowedState.length > 0
    ) {
      const formData = {
        name: name,
        password: Password,
        role: role,
        email: Cnfemail,
        phone: mobileCnf,
        permittedState: allowedState,
        charges:charges
    };
      const admin = await dispatch(createEmployee(formData));
      if (admin.payload.success) {
        alert("Employee created successfully");
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setPassword("");
        setRole("");
        setAllowedState([]);
        setbtnPress(false);
      }
    } else {
      setbtnPress(true);
    }
  };

  console.log(charges,'charges');

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Employee Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className={
                          nameError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="lName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Phone :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Phone"
                        variant="outlined"
                        className={
                          mobileError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={mobileCnf}
                        minLength={10}
                        maxLength={10}
                        onChange={(e) => setNumberChange(e)}
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                  <div>{mobileExistError}</div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="lName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Email :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Email"
                        variant="outlined"
                        className={
                          emailError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={email}
                        onChange={(e) => emailChange(e.target.value)}
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                  <div>{emailExistError}</div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="datepicker-basic"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Password :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Password"
                        variant="outlined"
                        className={
                          PasswordError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={Password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setPasswordError("");
                        }}
                        onKeyUp={handleKeyUp}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div>
                    {PasswordError && (
                      <div className="text-red-500 text-xs mt-1">
                        {PasswordError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between py-1 px-5">
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ fontWeight: "600", color: "#000066" }}
              >
                Role Assign
              </h4>
            </div>
            <div className="px-5">
              {roleError !== "" ? (
                <>
                  <p style={{ color: "red" }}>Please select one of the role</p>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <div className="radio-wrapper-6">
                    <input
                      className="tgl tgl-light"
                      id="executive"
                      type="radio"
                      name="role"
                      value="executive"
                      checked={role === "executive"}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                    <label
                      className="text-sm font-medium text-default-900 ps-2"
                      htmlFor="executive"
                    >
                      Executive
                    </label>
                  </div>
                </div>
                {loginData?.role === "businessAssociate" ? (
                  <></>
                ) : (
                  <div className="space-y-1">
                    <div className="radio-wrapper-6">
                      <input
                        className="tgl tgl-light"
                        id="backendEmployee"
                        type="radio"
                        name="role"
                        value="businessAssociate"
                        checked={role === "businessAssociate"}
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      />
                      <label
                        className="text-sm font-medium text-default-900 ps-2"
                        htmlFor="backendEmployee"
                      >
                        Business Associate
                      </label>
                    </div>
                  </div>
                )}
                <div className="space-y-1">
                  <div className="radio-wrapper-6">
                    <input
                      className="tgl tgl-light"
                      id="agent"
                      type="radio"
                      name="role"
                      value="agent"
                      checked={role === "agent"}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                    <label
                      className="text-sm font-medium text-default-900 ps-2"
                      htmlFor="agent"
                    >
                      Agent
                    </label>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="radio-wrapper-6">
                    <input
                      className="tgl tgl-light"
                      id="agent"
                      type="radio"
                      name="role"
                      value="Coordinater"
                      checked={role === "Coordinater"}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                    <label
                      className="text-sm font-medium text-default-900 ps-2"
                      htmlFor="agent"
                    >
                      Coordinater
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between py-1 px-5">
              <h4 className="text-lg font-medium text-default-950 capitalize">
                Choose State
              </h4>
            </div>
            <div className="px-5" style={{ color: "red" }}>
              {allowedStateError}
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-1 gap-4">
                <div className="space-y-1">
                  <div
                    className="radio-wrapper-6"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {loginData.role === "businessAssociate" ? (
                      <>
                        {filterState.map((indState, index) => (
                          <span style={{ margin: "10px", width: "15%" }}>
                            <input
                              className="tgl tgl-light"
                              id={`state-${index}`}
                              type="checkbox"
                              name="state"
                              value={indState.name}
                              checked={allowedState.includes(indState.name)}
                              onChange={(e) => {
                                handleState(e.target.value);
                              }}
                            />
                            <label
                              className="text-sm font-medium text-default-900 ps-2"
                              htmlFor={`state-${index}`}
                            >
                              {indState.name}
                            </label>
                          </span>
                        ))}
                      </>
                    ) : (
                      <>
                        {stateList.map((indState, index) => (
                          <span style={{ margin: "10px", width: "15%" }}>
                            <input
                              className="tgl tgl-light"
                              id={`state-${index}`}
                              type="checkbox"
                              name="state"
                              value={indState.name}
                              checked={allowedState.includes(indState.name)}
                              onChange={(e) => {
                                handleState(e.target.value);
                              }}
                            />
                            <label
                              className="text-sm font-medium text-default-900 ps-2"
                              htmlFor={`state-${index}`}
                            >
                              {indState.name}
                            </label>
                          </span>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {charges.map((charge, index) => (
              <div key={index} className="py-2 px-5">
                <div className="flex items-center justify-between py-2">
                  <h4
                    className="text-lg font-medium capitalize"
                    style={{ fontWeight: 600, color: "#000066" }}
                  >
                    Charges for various vehicle: {charge.state}
                  </h4>
                </div>
                <div className="grid md:grid-cols-3 gap-4">
                  {[
                    "twoWheelerCharge",
                    "threeWheelerCharge",
                    "fourWheelerCharge",
                    "constructionEqpCharge",
                    "commercialSCVVehicleCharge",
                    "commercialHCVVehicleCharge",
                    "commercialLCVVehicleCharge",
                    "farmEqpCharge",
                  ].map((chargeType, idx) => {
                    const label = chargeType
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())
                      .replace("Charge", " Charge");
                    return (
                      <div key={idx} className="space-y-1">
                        <label
                          htmlFor={chargeType}
                          className="text-sm font-medium"
                          style={{ fontWeight: 600, color: "#000066" }}
                        >
                          {label}:
                        </label>
                        <div className="textfieldcontainer">
                          <TextField
                            label={label}
                            variant="outlined"
                            className="textfield"
                            type="text"
                            id={chargeType}
                            value={charge[chargeType]}
                            onChange={(e) =>
                              handleChange(e, charge.state, chargeType)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              onClick={(e) => cancelClick(e)}
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={(e) => handlesubmit(e)}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
