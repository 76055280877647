import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { createOrganisationLogo, orgLogoActions, updateLogo, uploadOrganisationLogo } from '../../redux/Logo/OrgLogoSlice';
import BrandLogo from './BrandLogo';

const OrganisationLogo = () => {
    const { isOrganisationLoading, organisationLogo,logoId } = useSelector((store) => store.orgLogo);
    const dispatch = useDispatch()
    const [organisationError, setOrganisationError] = useState("")
    const [btnPress, setbtnPress] = useState(false);

    useEffect(() => {
        if (btnPress === true) {
            if (isOrganisationLoading) {
                setOrganisationError("Please upload a valid image")
            } else {
                setOrganisationError("")
            }
        }
    }, [btnPress, isOrganisationLoading])

    const handleOrganisationLogo = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadOrganisationLogo({ logo: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isOrganisationLoading) {
            setOrganisationError("Please upload a image to submit")
        } else {
            setOrganisationError("")
        }
        if (!isOrganisationLoading) {
            const formData = {
                _id:logoId,
                logo: organisationLogo
            }
            const logo = await dispatch(updateLogo(formData))
            if (logo.payload.success) {
                alert("Logo uploaded successfully")
                dispatch(orgLogoActions.removeOrgLogo())
                setbtnPress(false)
                setOrganisationError("")
            }
        } else {
            setbtnPress(true)
        }
    }
    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className='p-3 space-y-6'>
                    <div className="space-y-1">
                        <label
                            htmlFor="vehicleWeight"
                            className="text-sm font-medium text-default-900"
                        >
                            Upload organisation Logo *
                        </label>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                            <input type="file" onChange={handleOrganisationLogo}
                                className={
                                    organisationError === ""
                                        ? "block  rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                                        : "block  py-2.5 px-4 text-default-800 text-sm  focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                }
                            />
                            <button style={{ backgroundColor: '#4D44B5', padding: '8px 15px', color: '#fff', borderRadius: '10px' }} onClick={(e) => handleSubmit(e)}>Submit</button>
                        </div>
                        <div>
                            {isOrganisationLoading ? (
                                <div></div>
                            ) : (
                                <img src={organisationLogo} style={{ height: '100px', width: '100px' }} alt="img" />
                            )}
                        </div>
                    </div>
                    <BrandLogo/>
                </div>
            </div>
        </>
    )
}

export default OrganisationLogo