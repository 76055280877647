import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminPost,
  valiadteEmail,
  valiadteNumber,
} from "../../redux/Authentication/AuthSlice";
import { createEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import {
  CaseActions,
  UploadChasisImage,
  UploadFrontImage,
  UploadOpenBonnet,
  UploadRearUnderPart,
  UploadRight45Image,
  createCase,
  selfieWithVehicle,
  selfieWithVehicleUpload,
  sendVehicleNumber,
  uploadAnyDamage,
  uploadExtraImage,
  uploadExtraImage1,
  uploadExtraImage2,
  uploadExtraImage3,
  uploadFrontUnderCar,
  uploadGlassInsideImage,
  uploadGlassOutside,
  uploadLeft45,
  uploadLeftImage,
  uploadOdometerImage,
  uploadOpenDickey,
  uploadRcBackImage,
  uploadRcFront,
  uploadRearImage,
  uploadRightImage,
  uploadRpmImage,
  uploadSignature,
  uploadTyreImage,
  uploadformatImage,
} from "../../redux/Cases/CaseSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import FourWheelerEditReport4 from "../Reports/FourWheelerEditReport4";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { TextField } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import ImageUpload from "./UploadImage";

const AddConstructionWheelerCase = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { totalClients } = useSelector((store) => store.client);
  const {
    selfieWithVehicleImage,
    isSelfieWithAgentLoading,
    chasisImage,
    isChasisImageLoading,
    IsformatImageLoading,
    formatImage,
    isOpenBonnetLoading,
    openBonnet,
    isRight45ImageLoading,
    right45image,
    frontimage,
    isFrontImageLoading,
    frontUnderCarImage,
    isFrontUnderCarImageLoading,
    isLeft45Loading,
    left45image,
    isLeftImageLoading,
    leftImage,
    rearImage,
    isRearImageLoading,
    rearUnderPartImage,
    isRearUnderPartLoading,
    openDickeyImage,
    isOpenDickeyImageLoading,
    rightImage,
    isRightImageLoading,
    tyreImage,
    isTyreImageLoading,
    glassOutsideImage,
    isGlassOutsideImageLoading,
    glassInsideImage,
    isGlassInsideImageLoading,
    odometerImage,
    isOdometerImageLoading,
    rpmImage,
    isRpmImageLoading,
    anyDamage,
    isAnyDamageLoading,
    extraImage,
    isExtraImageLoading,
    extraImage1,
    isExtraImage1Loading,
    extraImage2,
    isExtraImage2Loading,
    extraImage3,
    isExtraImage3Loading,
    signatureImage,
    isSignatureImageLoading,
    rcFrontImage,
    isRcFrontImageLoading,
    rcBackImage,
    isRcBackImageLoading,
  } = useSelector((store) => store.cases);
  const [assetType, setAssetType] = useState("");
  const [assetTypeError, setAssetTypeError] = useState("");
  const [caseType, setCaseType] = useState("");
  const [caseTypeError, setCaseTypeError] = useState("");
  const [valuationPurpose, setValuationPurpose] = useState(
    "Assesment of fair market value"
  );
  const [valuationPurposeError, setValuationPurposeError] = useState("");
  const [requesterID, setRequesterID] = useState("");
  const [requesterName, setRequesterName] = useState("");
  const [requesterNameError, setRequesterNameError] = useState("");
  const [requesterBranch, setRequesterBranch] = useState("");
  const [requesterBranchError, setRequesterBranchError] = useState("");
  const [requesterPhone, setRequesterPhone] = useState("");
  const [requesterPhoneError, setRequesterPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [Cnfemail, setCnfEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCnf, setMobileCnf] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [productType, setProductType] = useState("");
  const [productTypeError, setProductTypeError] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productBrandError, setProductBrandError] = useState("");
  const [vehicleLocation, setVehicleLocation] = useState("");
  const [vehicleLocationError, setVehicleLocationError] = useState("");
  const [model, setModel] = useState("");
  const [ModelError, setModelError] = useState("");
  const [variant, setVariant] = useState("");
  const [variantError, setVariantError] = useState("");
  const [loanNo, setLoanNo] = useState("");
  const [loanNoError, setLoanNoError] = useState("");
  const [docVerified, setDocVerified] = useState("");
  const [regdOwner, setRegdOwner] = useState("");
  const [regdOwnerError, setRegdOwnerError] = useState("");
  const [regdDate, setRegdDate] = useState("");
  const [regdDateError, setRegdDateError] = useState("");
  const [mfgDate, setMfgdate] = useState("");
  const [numberPlateType, setNumberPlateType] = useState("");
  const [regdNo, setRegdNo] = useState("");
  const [regdNoError, setRegdNoError] = useState("");
  const [vehicleData, setVehicleData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [engNo, setEngNo] = useState("");
  const [engNoError, setEngNoError] = useState("");
  const [chasisNo, setChasisNo] = useState("");
  const [chasisNoError, setChasisNoError] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [bodyTypeError, setBodyTypeError] = useState("");
  const [meterReading, setMeterReading] = useState("");
  const [fueltype, setFueltype] = useState("");
  const [fueltypeError, setFueltypeError] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleColorError, setVehicleColorError] = useState("");
  const [ownerSerialNo, setOwnerSerialNo] = useState("");
  const [ownerSerialNoError, setOwnerSerialNoError] = useState("");
  const [hypothecationBank, setHypothecationBank] = useState("");
  const [hypothecationBankError, setHypothecationBankError] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [insuranceStatus, setInsuranceStatus] = useState("");
  const [cubicCapacity, setCubicCapacity] = useState("");
  const [cubicCapacityError, setCubicCapacityError] = useState("");
  const [seatingCapacity, setSeatingCapacity] = useState("");
  const [eatingCapacityError, setSeatingCapacityError] = useState("");
  const [vehicleWeight, setVehicleWeight] = useState("");
  const [vehicleWeightError, setVehicleWeightError] = useState("");

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [videoRecord, setVideoRecord] = useState("");

  const [role, setRole] = useState("");
  const fileTypes = ["JPG", "PNG", "GIF"];

  const [airConditioner, setAirConditioner] = useState("");
  const [cdCharge, setCdCharge] = useState("");
  const [seatCover, setSeatCover] = useState("");
  const [fogLamps, setFogLamps] = useState("");
  const [transmission, setTransmission] = useState("");
  const [odometer, setOdometer] = useState("");
  const [accidental, setAccidental] = useState("");
  const [otherRepair, setotherRepair] = useState("");
  const [fitnessExpiry, setFitnessExpiry] = useState("");
  const [roadtaxValidity, setroadtaxValidity] = useState("");
  const [idvValue, setIdvValue] = useState("");
  const [insuranceExpiry, setInsuranceExpiry] = useState("");
  const [engineCondition, setEngineCondition] = useState("");
  const [engineConditionError, setEngineConditionError] = useState("");
  const [wheelType, setWheelType] = useState("");
  const [totalTyre, setTotalTyre] = useState("");
  const [availableTyre, setAvailableTyre] = useState("");
  const [missingTyre, setMissingTyre] = useState("");
  const [spareTyre, setSpareTyre] = useState("");
  const [interiorCondition, setInteriorCondition] = useState("");
  const [interiorConditionError, setInteriorConditionError] = useState("");
  const [exteriorCondition, setExteriorCondition] = useState("");
  const [exteriorConditionError, setExteriorConditionError] = useState("");
  const [headLamp, setHeadLamp] = useState("");
  const [tailLamp, setTailLamp] = useState("");
  const [frontIndicators, setFrontIndicators] = useState("");
  const [rearIndicators, setRearIndicators] = useState("");
  const [maintenanceLevel, setMaintenanceLevel] = useState("");
  const [frontBrakeCondition, setFrontBreakCondition] = useState("");
  const [frontBrakeConditionError, setFrontBreakConditionError] = useState("");
  const [rearBrakeCondition, setRearBreakCondition] = useState("");
  const [rearBrakeConditionError, setRearBreakConditionError] = useState("");
  const [constructionEngineThere, setConstructionEngineThere] = useState("");
  const [constructionfluidLeaks, setConstructionFluidLeaks] = useState("");
  const [constructionEngineShield, setConstructionEngineShield] = useState("");
  const [
    constructionTransmissionCondition,
    setConstructionTransmissionCondition,
  ] = useState("");
  const [
    constructionTransmissionConditionError,
    setConstructionTransmissionConditionError,
  ] = useState("");
  const [constructionSuspension, setConstructionSuspension] = useState("");
  const [constructionSuspensionError, setConstructionSuspensionError] =
    useState("");
  const [constructionHandBrake, setConstructionHandBrake] = useState("");
  const [constructionTyreType, setConstructionTyreType] = useState("");
  const [constructionLhFront, setConstructionLhFront] = useState("");
  const [constructionLhFrontError, setConstructionLhFrontError] = useState("");
  const [constructionRhFront, setConstructionRhFront] = useState("");
  const [constructionRhFrontError, setConstructionRhFrontError] = useState("");
  const [constructionLhRear, setConstructionLhRear] = useState("");
  const [constructionLhRearError, setConstructionLhRearError] = useState("");
  const [constructionRhRear, setConstructionRhRear] = useState("");
  const [constructionRhRearError, setConstructionRhRearError] = useState("");
  const [constructionSpare, setConstructionSpare] = useState("");
  const [constructionSpareError, setConstructionSpareError] = useState("");
  const [constructionSteering, setConstructionSteering] = useState("");
  const [constructionSteeringCondition, setConstructionSteeringCondition] =
    useState("");
  const [constructionDashboard, setConstructionDashboard] = useState("");
  const [constructionSeat, setConstructionSeat] = useState("");
  const [constructionInterior, setConstructionInterior] = useState("");
  const [constructionAirbag, setConstructionAirbag] = useState("");
  const [constructionAntiLock, setConstructionAntiLock] = useState("");
  const [constructionPowerWindowFront, setConstructionPowerWindowFront] =
    useState("");
  const [constructionPowerWindowRear, setConstructionPowerWindowRear] =
    useState("");
  const [constructionSeatBelt, setConstructionSeatBelt] = useState("");
  const [
    constructionInsideRearViewMirror,
    setConstructionInsideRearViewMirror,
  ] = useState("");
  const [constructionBonnet, setConstructionBonnet] = useState("");
  const [constructionFrontBumper, setConstructionFrontBumper] = useState("");
  const [constructionRearBumper, setConstructionRearBumper] = useState("");
  const [constructionWindScreen, setConstructionWindScreen] = useState("");
  const [constructionWindScreenGlass, setConstructionWindScreenGlass] =
    useState("");
  const [constructionRearWindScreenGlass, setConstructionRearWindScreenGlass] =
    useState("");
  const [constructionRhDoorGlass, setConstructionRhDoorGlass] = useState("");
  const [constructionRhSideBody, setConstructionRhSideBody] = useState("");
  const [constructionLhSideBody, setConstructionLhSideBody] = useState("");
  const [constructionLhDoorGlass, setConstructionLhDoorGlass] = useState("");
  const [constructionOutsideRearView, setConstructionOutsideRearView] =
    useState("");
  const [constructionPaint, setConstructionPaint] = useState("");
  const [constructionPaintCondition, setConstructionPaintCondition] =
    useState("");
  const [
    constructionOverAllBodyCondition,
    setConstructionOverAllBodyCondition,
  ] = useState("");
  const [constructionBattery, setConstructionBattery] = useState("");
  const [constructionInstrumentCluster, setConstructionInstrumentCluster] =
    useState("");
  const [constructionFogLampCondition, setConstructionFogLampCondition] =
    useState("");
  const [constructionSunroof, setConstructionSunroof] = useState("");
  const [constructionMusicSyetem, setConstructionMusicSyetem] = useState("");
  const [constructionRoofRails, setConstructionRoofRails] = useState("");
  const [constructionAntenna, setConstructionAntenna] = useState("");
  const [constructionReverseCamera, setConstructionReverseCamera] =
    useState("");
  const [yourRemark, setYourRemark] = useState("");
  const [exShowRoomPrice, setExShowRoomPrice] = useState("");
  const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [estimatedPriceError, setEstimatedPriceError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false)
  const [clientList, setClientList] = useState([])
  useEffect(() => {
    const client = totalClients.map((indClient, index) => ({
      id: index,
      _id: indClient._id,
      name: indClient.name,
      phone: indClient.phone,
      organisation: indClient.organisation
    }));
    setClientList(client);
  }, [totalClients])
  const handleRequester = (e) => {
    const { organisation, phone, _id } = e.target.value
    setRequesterID(_id)
    // setRequesterBranch(organisation)
    setRequesterPhone(phone)
  }

  const selectCaseType = [
    {
      id: 1,
      name: "Revaluation",
    },
    {
      id: 2,
      name: "Repossession",
    },
    {
      id: 3,
      name: "Retail",
    },
    {
      id: 4,
      name: "Insurance Idv",
    },
    {
      id: 5,
      name: "Repurchase",
    },
  ];
  const selectCaseChange = (e) => {
    const { value } = e.target;
    setCaseType(value);
  };
  const selectAssetType = [
    {
      id: 1,
      name: "Two Wheeler",
    },
    {
      id: 2,
      name: "Four Wheeler",
    },
    {
      id: 3,
      name: "Commercial",
    },
    {
      id: 4,
      name: "Construction Equipment",
    },
    {
      id: 5,
      name: "Three Wheeler",
    },
    {
      id: 6,
      name: "Farm Equipment",
    },
  ];
  const selectAssetChange = (e) => {
    const { value } = e.target;

    setAssetType(value);
  };

  const handleRequesterPhn = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setRequesterPhone(value);
  };

  const stateList = [
    { id: 1, name: "Andhra Pradesh" },
    { id: 2, name: "Arunachal Pradesh" },
    { id: 3, name: "Assam" },
    { id: 4, name: "Bihar" },
    { id: 5, name: "Chhattisgarh" },
    { id: 6, name: "Goa" },
    { id: 7, name: "Gujarat" },
    { id: 8, name: "Haryana" },
    { id: 9, name: "Himachal Pradesh" },
    { id: 10, name: "Jharkhand" },
    { id: 11, name: "Karnataka" },
    { id: 12, name: "Kerala" },
    { id: 13, name: "Madhya Pradesh" },
    { id: 14, name: "Maharashtra" },
    { id: 15, name: "Manipur" },
    { id: 16, name: "Meghalaya" },
    { id: 17, name: "Mizoram" },
    { id: 18, name: "Nagaland" },
    { id: 19, name: "Odisha" },
    { id: 20, name: "Punjab" },
    { id: 21, name: "Rajasthan" },
    { id: 22, name: "Sikkim" },
    { id: 23, name: "Tamil Nadu" },
    { id: 24, name: "Telangana" },
    { id: 25, name: "Tripura" },
    { id: 26, name: "Uttar Pradesh" },
    { id: 27, name: "Uttarakhand" },
    { id: 28, name: "West Bengal" },
    { id: 29, name: "Andaman and Nicobar Islands" },
    { id: 30, name: "Chandigarh" },
    { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
    { id: 32, name: "Delhi" },
    { id: 33, name: "Ladakh" },
    { id: 34, name: "Lakshadweep" },
    { id: 35, name: "Puducherry" },
  ];
  const selectStateChange = (e) => {
    const { value } = e.target;
    setState(value);
  };
  useEffect(() => {
    if (state) {
      const formData = {
        country: "India",
        state: state.toLocaleUpperCase(),
      };
      const getCity = async () => {
        try {
          const config = {
            headers: { "Content-Type": "application/json" },
          };
          const url =
            "https://countriesnow.space/api/v0.1/countries/state/cities";
          const resp = await axios.post(url, formData, config);
          if (!resp.data.error) {
            const citiesData = resp.data.data.map((city, index) => ({
              id: index + 1,
              name: city.toLowerCase().replace(/\s/g, "_"),
            }));
            setCityList(citiesData);
          } else {
            console.error("Error in response:", resp.data.error); // Log the error for debugging
          }
        } catch (error) {
          console.error("Error:", error); // Log any other errors for debugging
        }
      };

      getCity();
    }
  }, [state]);

  const selectCityChange = (e) => {
    const { value } = e.target;
    setCity(value);
  };

  const handleRegdNo = async (e) => {
    const inputValue = e.target.value.toUpperCase();
    setRegdNo(inputValue);
    setDataFetched(false); // Reset data fetched status

    if (inputValue.length === 9 || inputValue.length === 10) {
      try {
        // let newData = await fetchVehicleData(inputValue);
        const formData = {
          regdNo: inputValue,
        };
        const vehicle = await dispatch(sendVehicleNumber(formData));
        setVehicleData(vehicle.payload.response.result);
        setDataFetched(true);
      } catch (error) {
        setVehicleData(null); // Reset vehicle data
      }
    } else {
      setVehicleData(null); // Reset vehicle data if input length is invalid
    }
  };

  useEffect(() => {
    // Fetch data again if required properties are null after initial fetch
    if (dataFetched && vehicleData) {
      const {
        rcFinancer,
        vehicleTaxUpto,
        permitValidUpto,
        permitType,
        vehicleSeatCapacity,
        grossVehicleWeight,
        regAuthority,
      } = vehicleData;

      if (
        !rcFinancer ||
        !vehicleTaxUpto ||
        !permitValidUpto ||
        !permitType ||
        !vehicleSeatCapacity ||
        !grossVehicleWeight ||
        !regAuthority
      ) {
        // Required properties are still null, fetch again
        handleRegdNo({ target: { value: regdNo } });
      }
    }
  }, [dataFetched, vehicleData, regdNo]);

  const documents = [
    { name: "Verified", id: 1 },
    { name: "Not Verified", id: 2 },
  ];
  const selectDocuementVerifiedTypeChange = (e) => {
    const { value } = e.target;
    setDocVerified(value);
  };
  const number = [
    { name: "White", id: 1 },
    { name: "Yellow", id: 2 },
    { name: "Blue", id: 3 },
    { name: "Black", id: 4 },
  ];
  const selectNumberPlateTypeChange = (e) => {
    const { value } = e.target;
    setNumberPlateType(value);
  };
  const options = [
    { name: "Yes", id: 1 },
    { name: "No", id: 2 },
  ];
  const Features = [
    { name: "Digital", id: 1 },
    { name: "Normal", id: 2 },
  ];
  const tranmission = [
    { name: "Manual", id: 1 },
    { name: "Auto", id: 2 },
  ];
  const condition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
    { name: "NA", id: 6 },
  ];
  const reqCondition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
  ];
  const list = [
    { name: "10", id: 1 },
    { name: "20", id: 2 },
    { name: "30", id: 3 },
    { name: "40", id: 4 },
    { name: "50", id: 5 },
    { name: "60", id: 6 },
    { name: "70", id: 7 },
    { name: "80", id: 8 },
    { name: "90", id: 9 },
    { name: "100", id: 10 },
  ];
  const qualityList = [
    { name: "Original", id: 1 },
    { name: "Replaced", id: 2 },
  ];
  const wheelTypeList = [
    { name: "Alloy", id: 1 },
    { name: "Steel", value: 2 },
    { name: "Rim", value: 3 },
  ];
  const carSteeringTypeList = [
    { name: "RACK AND PINION STEERING", id: 1 },
    { name: "POWER STEERING", id: 2 },
    { name: "RECIRCULATING BALL STEERING", id: 3 },
  ];
  const selectInsuranceStatusChange = (e) => {
    const { value } = e.target;

    setInsuranceStatus(value);
  };

  const handleFileChange = (event) => {
    setSelectedVideo(event.target.files[0]);
  };

  const handleUpload = async () => {
    setShowLoader(true);
    if (selectedVideo) {
      const formData = new FormData();
      formData.append("file", selectedVideo);
      try {
        const res = await axios.post(`${Baseurl}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setVideoRecord(res.data.filePath);
        document.getElementById("videouploading").value = "";
        setShowLoader(false);
        alert('file uploaded')
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.log("No video selected.");
    }
  };

  const handleSelfieWithVehicleChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    await dispatch(selfieWithVehicleUpload(formData));
  };
  const handleChasisChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    dispatch(UploadChasisImage(formData));
  };
  const handleFormatChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    dispatch(uploadformatImage(formData));
  };
  const handleOpenBonnetChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(UploadOpenBonnet(formData));
  };
  const handleright45Change = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    dispatch(UploadRight45Image(formData));
  };
  const frontImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(UploadFrontImage(formData));
  };
  const frontUnderCarImg = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadFrontUnderCar(formData));
  };
  const handlesetLeft45Image = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadLeft45(formData));
  };
  const handlesetLeftImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadLeftImage(formData));
  };
  const handlesetRearImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRearImage(formData));
  };
  const handlerearUnderPartImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(UploadRearUnderPart(formData));
  };
  const handlerearOpenDickeyImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadOpenDickey(formData));
  };
  const HandlerightImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRightImage(formData));
  };
  const handlesetTyreImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadTyreImage(formData));
  };
  const handleGlassOutsideImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadGlassOutside(formData));
  };
  const handleGlassInsideImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadGlassInsideImage(formData));
  };
  const handleOdometerImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadOdometerImage(formData));
  };
  const handleRpmImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRpmImage(formData));
  };
  const handleAnyDamage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadAnyDamage(formData));
  };
  const handleExtraImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadExtraImage(formData));
  };
  const handleExtraImage1 = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadExtraImage1(formData));
  };
  const handleExtraImage2 = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadExtraImage2(formData));
  };
  const handleExtraImage3 = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadExtraImage3(formData));
  };
  const handleSignature = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadSignature(formData));
  };
  const handleFrontRc = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRcFront(formData));
  };
  const handleBackRc = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRcBackImage(formData));
  };


  const selectSeatCoverChange = (e) => {
    const { value } = e.target;
    setSeatCover(value);
  };
  const selectFogLampsChange = (e) => {
    const { value } = e.target;
    setFogLamps(value);
  };
  const selectTransmissionChange = (e) => {
    const { value } = e.target;
    setTransmission(value);
  };
  const selectOdometerChange = (e) => {
    const { value } = e.target;
    setOdometer(value);
  };
  const selectAccidentalChange = (e) => {
    const { value } = e.target;

    setAccidental(value);
  };

  const selectotherRepairlChange = (e) => {
    const { value } = e.target;

    setotherRepair(value);
  };
  const selectEngineConditionChange = (e) => {
    const { value } = e.target;

    setEngineCondition(value);
  };
  const selectWheelTypeChange = (e) => {
    const { value } = e.target;

    setWheelType(value);
  };
  const selectInteriorConditionChange = (e) => {
    const { value } = e.target;
    setInteriorCondition(value);
  };
  const selectExteriorConditionChange = (e) => {
    const { value } = e.target;

    setExteriorCondition(value);
  };
  const selectHeadLampChange = (e) => {
    const { value } = e.target;

    setHeadLamp(value);
  };
  const selectTailLampChange = (e) => {
    const { value } = e.target;

    setTailLamp(value);
  };
  const selectFrontIndicatorsChange = (e) => {
    const { value } = e.target;

    setFrontIndicators(value);
  };
  const selectRearIndicatorsChange = (e) => {
    const { value } = e.target;

    setRearIndicators(value);
  };
  const selectMaintenanceLevelChange = (e) => {
    const { value } = e.target;

    setMaintenanceLevel(value);
  };
  const selectFrontBreakConditionChange = (e) => {
    const { value } = e.target;

    setFrontBreakCondition(value);
  };
  const selectRearBreakConditionChange = (e) => {
    const { value } = e.target;

    setRearBreakCondition(value);
  };
  const selectConstructionEngineThereChange = (e) => {
    const { value } = e.target;

    setConstructionEngineThere(value);
  };
  const selectConstructionFluidLeaksChange = (e) => {
    const { value } = e.target;

    setConstructionFluidLeaks(value);
  };
  const selectConstructionEngineShieldChange = (e) => {
    const { value } = e.target;

    setConstructionEngineShield(value);
  };
  const selectConstructionTransmissionConditionChange = (e) => {
    const { value } = e.target;

    setConstructionTransmissionCondition(value);
  };
  const selectConstructionSuspensionChange = (e) => {
    const { value } = e.target;

    setConstructionSuspension(value);
  };
  const selectConstructionHandBrakeChange = (e) => {
    const { value } = e.target;

    setConstructionHandBrake(value);
  };
  const selectConstructionLhFrontChange = (e) => {
    const { value } = e.target;

    setConstructionLhFront(Number(value));
  };
  const selectConstructionRhFrontChange = (e) => {
    const { value } = e.target;

    setConstructionRhFront(Number(value));
  };
  const selectConstructionLhRearChange = (e) => {
    const { value } = e.target;

    setConstructionLhRear(Number(value));
  };
  const selectConstructionRhRearChange = (e) => {
    const { value } = e.target;

    setConstructionRhRear(Number(value));
  };
  const selectConstructionSpareTyreChange = (e) => {
    const { value } = e.target;

    setConstructionSpare(Number(value));
  };
  const selectConstructionSteeringTypeChange = (e) => {
    const { value } = e.target;

    setConstructionSteering(value);
  };
  const selectConstructionSteeringConditionChange = (e) => {
    const { value } = e.target;

    setConstructionSteeringCondition(value);
  };
  const selectConstructionDashboardChange = (e) => {
    const { value } = e.target;

    setConstructionDashboard(value);
  };
  const selectConstructionSeatChange = (e) => {
    const { value } = e.target;

    setConstructionSeat(value);
  };
  const selectConstructionInteriorChange = (e) => {
    const { value } = e.target;

    setConstructionInterior(value);
  };
  const selectConstructionAirbagChange = (e) => {
    const { value } = e.target;

    setConstructionAirbag(value);
  };
  const selectConstructionAntiLockChange = (e) => {
    const { value } = e.target;

    setConstructionAntiLock(value);
  };
  const selectConstructionPowerWindowfrontChange = (e) => {
    const { value } = e.target;

    setConstructionPowerWindowFront(value);
  };
  const selectConstructionPowerWindowRearChange = (e) => {
    const { value } = e.target;

    setConstructionPowerWindowRear(value);
  };
  const selectConstructionSeatBeltChange = (e) => {
    const { value } = e.target;

    setConstructionSeatBelt(value);
  };
  const selectConstructionInsideRearViewMirrorChange = (e) => {
    const { value } = e.target;

    setConstructionInsideRearViewMirror(value);
  };
  const selectConstructionBonnetChange = (e) => {
    const { value } = e.target;

    setConstructionBonnet(value);
  };
  const selectConstructionFrontBumperChange = (e) => {
    const { value } = e.target;

    setConstructionFrontBumper(value);
  };
  const selectConstructionRearBumperChange = (e) => {
    const { value } = e.target;

    setConstructionRearBumper(value);
  };
  const selectConstructionWindScreenChange = (e) => {
    const { value } = e.target;

    setConstructionWindScreen(value);
  };
  const selectConstructionWindScreenGlassChange = (e) => {
    const { value } = e.target;

    setConstructionWindScreenGlass(value);
  };
  const selectConstructionRearWindScreenGlassChange = (e) => {
    const { value } = e.target;

    setConstructionRearWindScreenGlass(value);
  };
  const selectConstructionRhDoorGlassChange = (e) => {
    const { value } = e.target;

    setConstructionRhDoorGlass(value);
  };

  const selectConstructionLhSideBodyChange = (e) => {
    const { value } = e.target;

    setConstructionRhSideBody(value);
  };
  const selectConstructionLhSideBodysChange = (e) => {
    const { value } = e.target;

    setConstructionLhSideBody(value);
  };
  const selectConstructionLhDoorGlassChange = (e) => {
    const { value } = e.target;

    setConstructionLhDoorGlass(value);
  };
  const selectConstructionOutsideRearViewChange = (e) => {
    const { value } = e.target;

    setConstructionOutsideRearView(value);
  };
  const selectConstructionPaintChange = (e) => {
    const { value } = e.target;

    setConstructionPaint(value);
  };
  const selectConstructionPaintConditionChange = (e) => {
    const { value } = e.target;

    setConstructionPaintCondition(value);
  };
  const selectConstructionOverAllBodyConditionChange = (e) => {
    const { value } = e.target;

    setConstructionOverAllBodyCondition(value);
  };
  const selectConstructionBatteryChange = (e) => {
    const { value } = e.target;

    setConstructionBattery(value);
  };
  const selectConstructionInstrumentClusterChange = (e) => {
    const { value } = e.target;

    setConstructionInstrumentCluster(value);
  };

  const selectConstructionFogLampConditionChange = (e) => {
    const { value } = e.target;

    setConstructionFogLampCondition(value);
  };
  const selectConstructionSunroofChange = (e) => {
    const { value } = e.target;

    setConstructionSunroof(value);
  };
  const selectConstructionMusicSyetemChange = (e) => {
    const { value } = e.target;

    setConstructionMusicSyetem(value);
  };
  const selectConstructionRoofRailsChange = (e) => {
    const { value } = e.target;

    setConstructionRoofRails(value);
  };
  const selectConstructionAntennaChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionAntenna(value);
  };
  const selectConstructionReverseCameraChange = (e) => {
    const { value } = e.target;

    setConstructionReverseCamera(value);
  };
  const selectYourRemarkChange = (e) => {
    const { value } = e.target;

    setYourRemark(value);
  };

  const [btnPress, setbtnPress] = useState(false);

  useEffect(() => {
    if (btnPress === true) {
      if (name === "") {
        setNameError("please enter title");
      } else {
        setNameError("");
      }
      if (Cnfemail === "") {
        setEmailError("please enter email");
      } else {
        setEmailError("");
      }
      if (mobileCnf.length !== 10) {
        setMobileError("please enter mobile");
      } else {
        setMobileError("");
      }
    }
  }, [btnPress, name, Cnfemail, mobileCnf]);

  const setNumberChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileError("");
    setMobile(value);
    if (value.length === 10) {
      // const verifyNumber = await dispatch(valiadteNumber(value));
      // if (verifyNumber.payload.success) {
      //     setMobileError("Number already exist");
      // } else {
      // }
      setMobileError("");
      setMobileCnf(value);
    } else {
      setMobileError("Please Enter Valid Number");
    }
  };

  const emailChange = async (e) => {
    const value = e;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setEmailError("");
    if (value.length > 0) {
      if (value.match(mailformat)) {
        // const verifyEmail = await dispatch(valiadteEmail(value));
        // if (verifyEmail.payload.success) {
        //     setEmailError("Number already exist");
        // } else {
        // }
        setEmailError("");
        setCnfEmail(value);
      } else {
        setEmailError("please enter correct Email format");
      }
    } else {
      setEmailError("please enter correct Email format");
    }
  };

  const cancelClick = async (e) => {
    e.preventDefault();
    setEmail("");
    setCnfEmail("");
    setMobile("");
    setMobileCnf("");
    setName("");
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true)
    if (name === "") {
      setNameError("please enter title");
    } else {
      setNameError("");
    }
    if (Cnfemail === "") {
      setEmailError("please enter email");
    } else {
      setEmailError("");
    }
    if (mobileCnf.length !== 10) {
      setMobileError("please enter mobile");
    } else {
      setMobileError("");
    }
    if (name !== "" && Cnfemail !== "" && mobileCnf !== "") {
      const formData = {
        assetType: assetType,
        caseType: caseType,
        valuationPurpose: valuationPurpose,
        vehicleLocation: vehicleLocation,
        requesterID: requesterID,
        requesterName: requesterName,
        requesterBranch: requesterBranch,
        requesterPhone: requesterPhone,
        name: name,
        mobile: mobileCnf,
        email: Cnfemail,
        address: address,
        state: state,
        city: city,
        regdNo: regdNo,
        productType: productType,
        brand: productBrand,
        model: model,
        variant: variant,
        loanAgreementNo: loanNo,
        documentVerification: docVerified,
        regdOwner: regdOwner,
        regdDate: regdDate,
        numberPlate: numberPlateType,
        engNo: engNo,
        chasisNo: chasisNo,
        bodyType: bodyType,
        manufacturingDate: mfgDate,
        fueltype: fueltype,
        vehicleColor: vehicleColor,
        ownerSerialNo: ownerSerialNo,
        hypothecationBank: hypothecationBank,
        cubicCapacity: cubicCapacity,
        seatingCapacity: seatingCapacity,
        vehicleWeight: vehicleWeight,
        videos: videoRecord,
        images: {
          selfieWithVehicle: selfieWithVehicleImage,
          chasisImage: chasisImage,
          format: formatImage,
          openBonnet: openBonnet,
          right45: right45image,
          frontImage: frontimage,
          frontUnderCar: frontUnderCarImage,
          left45: left45image,
          leftImage: leftImage,
          rearImage: rearImage,
          rearUnderPart: rearUnderPartImage,
          openDickey: openDickeyImage,
          rightImage: rightImage,
          tyreImage: tyreImage,
          glassOutside: glassOutsideImage,
          glassInside: glassInsideImage,
          odometerImage: odometerImage,
          rpmImage: rpmImage,
          anyDamage: anyDamage,
          extraImage: extraImage,
          extraImage1: extraImage1,
          extraImage2: extraImage2,
          extraImage3: extraImage3,
        },
        signNdoc: {
          sign: signatureImage,
          rcFront: rcFrontImage,
          rcBack: rcBackImage,
        },
        accessories: {
          seatCover: seatCover,
          fogLamps: fogLamps,
        },
        additionalFeatures: {
          transmission: transmission,
          odometer: odometer,
        },
        otherDetails: {
          accidental: accidental,
          otherRepair: otherRepair,
          fitnessExpiry: fitnessExpiry,
          roadTaxValidity: roadtaxValidity,
          IDVValue: idvValue,
          insuranceExpiry: insuranceExpiry,
          engineCondition: engineCondition,
          frontBrakeCondition: frontBrakeCondition,
          rearBrakeCondition: rearBrakeCondition,
          wheelsType: wheelType,
          totalTyre: Number(totalTyre),
          availableTyre: Number(availableTyre),
          missingTyre: Number(missingTyre),
          spareTyre: Number(spareTyre),
          interiorCondition: interiorCondition,
          exteriorCondition: exteriorCondition,
          headLamp: headLamp,
          tailLamp: tailLamp,
          frontIndicators: frontIndicators,
          rearIndicators: rearIndicators,
          levelOfMaintenance: maintenanceLevel,
        },
        construction: {
          engine: {
            engine: constructionEngineThere,
            fluidLeak: constructionfluidLeaks,
            engineShield: constructionEngineShield,
          },
          transmission: {
            transmissionCondition: constructionTransmissionCondition,
          },
          suspension: {
            suspensionCondition: constructionSuspension,
          },
          brakes: {
            handBrake: constructionHandBrake,
          },
          TyresNConditions: {
            type: constructionTyreType,
            lhFront: constructionLhFront,
            rhFront: constructionRhFront,
            lhRear: constructionLhRear,
            rhRear: constructionRhRear,
            spare: constructionSpare,
          },
          vehicleInterior: {
            steering: constructionSteering,
            steeringCondition: constructionSteeringCondition,
            dashboard: constructionDashboard,
            seats: constructionSeat,
            interiorsTrims: constructionInterior,
            airBag: constructionAirbag,
            antilockBrakeSystem: constructionAntiLock,
            powerWindowFront: constructionPowerWindowFront,
            powerWindowRear: constructionPowerWindowRear,
            seatBelts: constructionSeatBelt,
            insideRearViewMirror: constructionInsideRearViewMirror,
          },
          vehicleExterior: {
            bonnet: constructionBonnet,
            frontBumper: constructionFrontBumper,
            rearBumper: constructionRearBumper,
            windScreen: constructionWindScreen,
            windscreenGlass: constructionWindScreenGlass,
            rearWindScreenGlass: constructionRearWindScreenGlass,
            rhDoorGlasses: constructionRhDoorGlass,
            rhSideBody: constructionRhSideBody,
            lhSideBody: constructionLhSideBody,
            lhDoorGlasses: constructionLhDoorGlass,
            outsideRearViewMirror: constructionOutsideRearView,
            paint: constructionPaint,
            paintCondition: constructionPaintCondition,
            overallBodyCondition: constructionOverAllBodyCondition,
          },
          electricals: {
            battery: constructionBattery,
            instrumentClustor: constructionInstrumentCluster,
            fogLamps: constructionFogLampCondition,
            fogLampCondition: constructionFogLampCondition,
          },
          accessories: {
            sunroof: constructionSunroof,
            musicStytem: constructionMusicSyetem,
            roofRails: constructionRoofRails,
            antenna: constructionAntenna,
            reverseCamera: constructionReverseCamera,
          },
        },
        yourRemark: yourRemark,
        valuation: estimatedPrice,
        marketPrice: exShowRoomPrice,
        status: 4,
        statusText: "Case Submitted",
      };
      const newcase = await dispatch(createCase(formData));
      if (newcase.payload.success) {
        alert("Case created successfully");
        dispatch(CaseActions.afterPost());
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setRole("");
        setAddress("");
        setState("");
        setCity("");
        setProductType("");
        setProductBrand("");
        setModel("");
        setVariant("");
        setLoanNo("");
        setDocVerified("");
        setRegdOwner("");
        setRegdDate("");
        setMfgdate("");
        setNumberPlateType("");
        setRegdNo("");
        setEngNo("");
        setChasisNo("");
        setBodyType("");
        setFueltype("");
        setVehicleColor("");
        setOwnerSerialNo("");
        setHypothecationBank("");
        setCubicCapacity("");
        setSeatingCapacity("");
        setVehicleWeight("");
        setSeatCover("");
        setFogLamps("");
        setTransmission("");
        setAccidental("");
        setFitnessExpiry("");
        setInsuranceExpiry("");
        setButtonLoading(false)
        navigate("/admin/submittedcases");
      }
    } else {
      setbtnPress(true);
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff" }}
              >
                Add Construction Case Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Case Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-caseType">Case Type</InputLabel>
                    <Select
                      labelId="demo-simple-caseType"
                      id="demo-simple-select"
                      value={caseType}
                      label="Case Type"
                      onChange={(e) => selectCaseChange(e)}
                    >
                      {selectCaseType.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fAssetType"
                    className="text-sm font-medium text-default-900"
                  >
                    Asset Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Asset-Type">
                      Asset Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Asset-Type"
                      id="demo-simple-select"
                      value={assetType}
                      label="Asset Type"
                      onChange={(e) => selectAssetChange(e)}
                    >
                      {selectAssetType.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fValuationPurpose"
                    className="text-sm font-medium text-default-900"
                  >
                    Purpose Of Valuation *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Purpose Of Valuation"
                      variant="outlined"
                      className={
                        valuationPurposeError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={valuationPurpose}
                      onChange={(e) => setValuationPurpose(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Valuation Requested By *
                  </label>
                  <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                    <InputLabel id="demo-simple-requesterName" className="inputcenter">Valuation Requested By</InputLabel>
                    <Select
                      labelId="demo-simple-requesterName"
                      id="demo-simple-select"
                      value={requesterName}
                      label="requesterName"
                      onChange={(e) => { handleRequester(e); setRequesterName(e.target.value.name) }}
                      style={{ height: '38px' }}
                      inputProps={{ style: { textAlign: 'center' } }}
                    >
                      {clientList.map((test, index) =>
                        <MenuItem value={test} key={index}>{test.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Name"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterName}
                      onChange={(e) => setRequesterName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Branch Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Branch Name"
                      variant="outlined"
                      className={
                        requesterBranchError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterBranch}
                      onChange={(e) => setRequesterBranch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Phone *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Branch Name"
                      variant="outlined"
                      className={
                        requesterPhoneError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterPhone}
                      onChange={(e) => handleRequesterPhn(e)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Name"
                      variant="outlined"
                      className={
                        nameError !== "" ? "textfield error_class" : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Phone *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Phone"
                      variant="outlined"
                      className={
                        mobileError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={mobile}
                      onChange={(e) => setNumberChange(e)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Email *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Email"
                      variant="outlined"
                      className={
                        emailError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={email}
                      onChange={(e) => emailChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="Address"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Address *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Address"
                      variant="outlined"
                      className={
                        addressError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fState"
                    className="text-sm font-medium text-default-900"
                  >
                    Select State *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-State-Type">
                      State Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-State-Type"
                      id="demo-simple-select"
                      value={state}
                      label="State"
                      onChange={(e) => selectStateChange(e)}
                    >
                      {stateList.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fCity"
                    className="text-sm font-medium text-default-900"
                  >
                    Select City *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-City-Type">
                      City Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-City-Type"
                      id="demo-simple-select"
                      value={city}
                      label="City"
                      onChange={(e) => selectCityChange(e)}
                    >
                      {cityList.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="regdNo"
                    className="text-sm font-medium text-default-900"
                  >
                    RegdNo *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Regd No"
                      variant="outlined"
                      className={
                        regdNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={regdNo}
                      onChange={(e) => setRegdNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehcileLocation"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Location *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Location"
                      variant="outlined"
                      className={
                        vehicleLocationError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={vehicleLocation}
                      onChange={(e) => setVehicleLocation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="productType"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle brand *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Location"
                      variant="outlined"
                      className={
                        productBrandError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={productBrand}
                      onChange={(e) => setProductBrand(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="model"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle model *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Location"
                      variant="outlined"
                      className={
                        ModelError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="variant"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle variant *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle variant"
                      variant="outlined"
                      className={
                        variantError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={variant}
                      onChange={(e) => setVariant(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="loanNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Loan Agreement No *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Loan Agreement No"
                      variant="outlined"
                      className={
                        loanNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={loanNo}
                      onChange={(e) => setLoanNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fDocVerified"
                    className="text-sm font-medium text-default-900"
                  >
                    Document Verified *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Document-Verified">
                      Doc Verified
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Document-Verified"
                      id="demo-simple-select"
                      value={docVerified}
                      label="Doc Verified"
                      onChange={(e) => selectDocuementVerifiedTypeChange(e)}
                    >
                      {documents.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="regdOwner"
                    className="text-sm font-medium text-default-900"
                  >
                    Regd Owner *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Regd Owner"
                      variant="outlined"
                      className={
                        regdOwnerError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={regdOwner}
                      onChange={(e) => setRegdOwner(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="regdOwner"
                    className="text-sm font-medium text-default-900"
                  >
                    Regd Date *
                  </label>
                  <input
                    type="date"
                    className={
                      regdDateError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="regdDate"
                    placeholder="Enter product regd date"
                    value={regdDate}
                    onChange={(e) => setRegdDate(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fManufacturingDate"
                    className="text-sm font-medium text-default-900"
                  >
                    Manufacturing Date <span style={{ color: "red" }}>**</span>
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fManufacturingDate"
                    placeholder="Enter Manufacturing Date"
                    value={mfgDate}
                    onChange={(e) => setMfgdate(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fNumberPlate"
                    className="text-sm font-medium text-default-900"
                  >
                    Number Plate Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Number-Plate-Type">
                      Number Plate Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Number-Plate-Type"
                      id="demo-simple-select"
                      value={numberPlateType}
                      label="Number Plate Type"
                      onChange={(e) => selectNumberPlateTypeChange(e)}
                    >
                      {number.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine No *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Engine No"
                      variant="outlined"
                      className={
                        engNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={engNo}
                      onChange={(e) => setEngNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="chasisNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Chasis No *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Chasis No"
                      variant="outlined"
                      className={
                        chasisNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={chasisNo}
                      onChange={(e) => setChasisNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="bodyType"
                    className="text-sm font-medium text-default-900"
                  >
                    Body Type *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Body Type"
                      variant="outlined"
                      className={
                        bodyTypeError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={bodyType}
                      onChange={(e) => setBodyType(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMeterReading"
                    className="text-sm font-medium text-default-900"
                  >
                    Meter Reading *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Body Type"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={meterReading}
                      onChange={(e) => setMeterReading(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fueltype"
                    className="text-sm font-medium text-default-900"
                  >
                    Fuel Type*
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Body Type"
                      variant="outlined"
                      className={
                        fueltypeError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={fueltype}
                      onChange={(e) => setFueltype(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fueltype"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Color *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Color"
                      variant="outlined"
                      className={
                        vehicleColorError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={vehicleColor}
                      onChange={(e) => setVehicleColor(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                  >
                    Gross Weight Of Vehicle *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Gross Weight Of Vehicle"
                      variant="outlined"
                      className={
                        vehicleWeightError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={vehicleWeight}
                      onChange={(e) => setVehicleWeight(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="ownerSerialNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Owner Serial No *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Owner Serial No"
                      variant="outlined"
                      className={
                        ownerSerialNoError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={ownerSerialNo}
                      onChange={(e) => setOwnerSerialNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="ownerSerialNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Hypothecation Bank Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Hypothecation Bank"
                      variant="outlined"
                      className={
                        hypothecationBankError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={hypothecationBank}
                      onChange={(e) => setHypothecationBank(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fInsuranceCompany"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Company Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Hypothecation Bank"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={insuranceCompany}
                      onChange={(e) => setInsuranceCompany(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fInsuranceStatus"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Expired *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Insurance-Expired">
                      Insurance Expired
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Insurance-Expired"
                      id="demo-simple-select"
                      value={insuranceStatus}
                      label="Insurance Status"
                      onChange={(e) => selectInsuranceStatusChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="cubicCapacity"
                    className="text-sm font-medium text-default-900"
                  >
                    Cubic Capacity *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Cubic Capacity"
                      variant="outlined"
                      className={
                        cubicCapacityError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={cubicCapacity}
                      onChange={(e) => setCubicCapacity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="seatingCapacity"
                    className="text-sm font-medium text-default-900"
                  >
                    Seating Capacity *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Seating Capacity"
                      variant="outlined"
                      className={
                        eatingCapacityError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={seatingCapacity}
                      onChange={(e) => setSeatingCapacity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-span-3 space-y-1">
                  <label
                    htmlFor="fLoanAgreement"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Video *
                  </label>
                  {showLoader === true ? (
                    <>
                      <div
                        style={{
                          height: "250px",
                          width: "50%",
                          backgroundColor: "#000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    </>
                  ) : (
                    <>
                      <iframe
                        title="Video Player"
                        width="50%" // Set width to 100% to fill the container
                        height="250px" // Set height to auto to maintain aspect ratio
                        src={videoRecord}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </>
                  )}
                  <input
                    type="file"
                    id="videouploading"
                    onChange={handleFileChange}
                    accept="video/*"
                  />
                  {selectedVideo ? (
                    <div>
                      <button
                        onClick={handleUpload}
                        style={{
                          backgroundColor: "#ff6600",
                          color: "#fff",
                          padding: "5px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        Upload Video
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        style={{
                          backgroundColor: "#ff6600",
                          opacity: ".5",
                          color: "#fff",
                          padding: "5px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        Upload Video
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-span-3 space-y-1">
                  <ImageUpload />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                  >
                    Selfie With Vehicle *
                  </label>
                  <input
                    type="file"
                    onChange={handleSelfieWithVehicleChange}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isSelfieWithAgentLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={selfieWithVehicleImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="Chasis Image"
                    className="text-sm font-medium text-default-900"
                  >
                    Chasis Image *
                  </label>
                  <input
                    type="file"
                    onChange={handleChasisChange}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isChasisImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={chasisImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="formatfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Product Format Image *
                  </label>
                  <input
                    type="file"
                    onChange={handleFormatChange}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {IsformatImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={formatImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="openBonnetfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Open Bonnet Image *
                  </label>
                  <input
                    type="file"
                    onChange={handleOpenBonnetChange}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isOpenBonnetLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={openBonnet}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="openBonnetfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Right 45* Image *
                  </label>
                  <input
                    type="file"
                    onChange={handleright45Change}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRight45ImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={right45image}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontImage"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Image *
                  </label>
                  <input
                    type="file"
                    onChange={frontImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isFrontImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={frontimage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontUnderCar"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Under Car Image *
                  </label>
                  <input
                    type="file"
                    onChange={frontUnderCarImg}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isFrontUnderCarImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={frontUnderCarImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="left45file"
                    className="text-sm font-medium text-default-900"
                  >
                    Left 45* Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlesetLeft45Image}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isLeft45Loading ? (
                      <div></div>
                    ) : (
                      <img
                        src={left45image}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="leftfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Left Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlesetLeftImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isLeftImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={leftImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Rear Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlesetRearImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRearImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rearImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearunderpartfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Rear Under Part Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlerearUnderPartImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRearUnderPartLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rearUnderPartImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearunderpartfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Open Dickey Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlerearOpenDickeyImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isOpenDickeyImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={openDickeyImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="handlerightImage"
                    className="text-sm font-medium text-default-900"
                  >
                    Open Handle Right Image *
                  </label>
                  <input
                    type="file"
                    onChange={HandlerightImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRightImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rightImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="tyreImage"
                    className="text-sm font-medium text-default-900"
                  >
                    Tyre Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlesetTyreImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isTyreImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={tyreImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="glassOutside"
                    className="text-sm font-medium text-default-900"
                  >
                    Glass Outside Image*
                  </label>
                  <input
                    type="file"
                    onChange={handleGlassOutsideImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isGlassOutsideImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={glassOutsideImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="glassOutside"
                    className="text-sm font-medium text-default-900"
                  >
                    Glass Inside Image*
                  </label>
                  <input
                    type="file"
                    onChange={handleGlassInsideImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isGlassInsideImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={glassInsideImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="odometer"
                    className="text-sm font-medium text-default-900"
                  >
                    Odometer Image*
                  </label>
                  <input
                    type="file"
                    onChange={handleOdometerImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isOdometerImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={odometerImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rpmFile"
                    className="text-sm font-medium text-default-900"
                  >
                    RPM Image*
                  </label>
                  <input
                    type="file"
                    onChange={handleRpmImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRpmImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rpmImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Any Damage *
                  </label>
                  <input
                    type="file"
                    onChange={handleAnyDamage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isAnyDamageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={anyDamage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Extra Damage *
                  </label>
                  <input
                    type="file"
                    onChange={handleExtraImage}
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isExtraImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={extraImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Extra Image 1 *
                  </label>
                  <input
                    type="file"
                    onChange={handleExtraImage1}
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isExtraImage1Loading ? (
                      <div></div>
                    ) : (
                      <img
                        src={extraImage1}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Extra Image 2 *
                  </label>
                  <input
                    type="file"
                    onChange={handleExtraImage2}
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isExtraImage2Loading ? (
                      <div></div>
                    ) : (
                      <img
                        src={extraImage2}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Extra Image 3 *
                  </label>
                  <input
                    type="file"
                    onChange={handleExtraImage3}
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isExtraImage3Loading ? (
                      <div></div>
                    ) : (
                      <img
                        src={extraImage3}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="signature"
                    className="text-sm font-medium text-default-900"
                  >
                    Upload Your Signature*
                  </label>

                  <input
                    type="file"
                    onChange={handleSignature}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isSignatureImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={signatureImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontRc"
                    className="text-sm font-medium text-default-900"
                  >
                    Upload Frontside of your RC*
                  </label>
                  <input
                    type="file"
                    onChange={handleFrontRc}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRcFrontImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rcFrontImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="backRc"
                    className="text-sm font-medium text-default-900"
                  >
                    Upload Frontside of your RC*
                  </label>
                  <input
                    type="file"
                    onChange={handleBackRc}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRcBackImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rcBackImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="seatCover"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat Cover *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Seat-Cover">
                      Seat Cover
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Seat-Cover"
                      id="demo-simple-select"
                      value={seatCover}
                      label="Seat Cover"
                      onChange={(e) => selectSeatCoverChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fogLamps"
                    className="text-sm font-medium text-default-900"
                  >
                    Fog Lamps *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Fog-Lamps">
                      Fog Lamps
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Fog-Lamps"
                      id="demo-simple-select"
                      value={fogLamps}
                      label="Seat Cover"
                      onChange={(e) => selectFogLampsChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="transmission"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-transmission">
                      Transmission
                    </InputLabel>
                    <Select
                      labelId="demo-simple-transmission"
                      id="demo-simple-select"
                      value={transmission}
                      label="Transmission"
                      onChange={(e) => selectTransmissionChange(e)}
                    >
                      {tranmission.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="odometer"
                    className="text-sm font-medium text-default-900"
                  >
                    Odometer *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Odometer">Odometer</InputLabel>
                    <Select
                      labelId="demo-simple-Odometer"
                      id="demo-simple-select"
                      value={odometer}
                      label="Odometer"
                      onChange={(e) => selectOdometerChange(e)}
                    >
                      {Features.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="accidental"
                    className="text-sm font-medium text-default-900"
                  >
                    Accidental *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Accidental">
                      Accidental
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Accidental"
                      id="demo-simple-select"
                      value={accidental}
                      label="Accidental"
                      onChange={(e) => selectAccidentalChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="otherRepair"
                    className="text-sm font-medium text-default-900"
                  >
                    Other Repair *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Other-Repair">
                      Other Repair
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Other-Repair"
                      id="demo-simple-select"
                      value={otherRepair}
                      label="OtherRepair"
                      onChange={(e) => selectotherRepairlChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fitnessExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Fitness Expiry *
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fitnessExpiry"
                    placeholder="Enter Fitness Validity"
                    value={fitnessExpiry}
                    onChange={(e) => setFitnessExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="roadtaxValidity"
                    className="text-sm font-medium text-default-900"
                  >
                    Roadtax Validity *
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="roadtaxValidity"
                    placeholder="Enter Road Tax Validity"
                    value={roadtaxValidity}
                    onChange={(e) => setroadtaxValidity(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="idvValue"
                    className="text-sm font-medium text-default-900"
                  >
                    Idv Value *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Idv Value"
                      variant="outlined"
                      className="textfield"
                      type="text"
                      id="text"
                      value={idvValue}
                      onChange={(e) => setIdvValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="insuranceExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Expiry *
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="insuranceExpiry"
                    placeholder="Enter Insurance Expiry"
                    value={insuranceExpiry}
                    onChange={(e) => setInsuranceExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engineCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Condition <span style={{ color: "red" }}>**</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Engine-Condition">
                      Engine Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Engine-Condition"
                      id="demo-simple-select"
                      value={engineCondition}
                      label="Engine Condition"
                      onChange={(e) => selectEngineConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="wheelType"
                    className="text-sm font-medium text-default-900"
                  >
                    Wheel Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Wheel-Type">
                      Wheel Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Wheel-Type"
                      id="demo-simple-select"
                      value={wheelType}
                      label="wheel Type"
                      onChange={(e) => selectWheelTypeChange(e)}
                    >
                      {wheelTypeList.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === wheelType}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="totalTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Total Tyre * (Type In Numbers)
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Total Tyre"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={totalTyre}
                      onChange={(e) => setTotalTyre(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="availableTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Available Tyre * (Type In Numbers)
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Available Tyre"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={availableTyre}
                      onChange={(e) => setAvailableTyre(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="missingTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Missing Tyre * (Type In Numbers)
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Missing Tyre"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={missingTyre}
                      onChange={(e) => setMissingTyre(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="spareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare Tyre * (Type In Numbers)
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Spare Tyre"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={spareTyre}
                      onChange={(e) => setSpareTyre(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="interiorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Interior Condition <span style={{ color: "red" }}>**</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Interior-Condition">
                      Exterior Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Interior-Condition"
                      id="demo-simple-select"
                      value={interiorCondition}
                      label="Interior Condition"
                      onChange={(e) => selectInteriorConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === interiorCondition}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="exteriorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Exterior Condition <span style={{ color: "red" }}>**</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Exterior-Condition">
                      Exterior Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Exterior-Condition"
                      id="demo-simple-select"
                      value={exteriorCondition}
                      label="Exterior Condition"
                      onChange={(e) => selectExteriorConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === exteriorCondition}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="headLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Head Lamp *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Head-Lamp">
                      Head Lamp
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Head-Lamp"
                      id="demo-simple-select"
                      value={headLamp}
                      label="Head Lamp"
                      onChange={(e) => selectHeadLampChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === headLamp}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="tailLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Tail Lamp *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Tail-Lamp">
                      Tail Lamp
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Tail-Lamp"
                      id="demo-simple-select"
                      value={tailLamp}
                      label="Tail Lamp"
                      onChange={(e) => selectTailLampChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === tailLamp}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Indicators *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Front-Indicators">
                      Front Indicators
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Front-Indicators"
                      id="demo-simple-select"
                      value={frontIndicators}
                      label="Front Indicators"
                      onChange={(e) => selectFrontIndicatorsChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === frontIndicators}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Indicators *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Rear-Indicators">
                      Rear Indicators
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Rear-Indicators"
                      id="demo-simple-select"
                      value={rearIndicators}
                      label="Rear Indicators"
                      onChange={(e) => selectRearIndicatorsChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === rearIndicators}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="maintenanceLevel"
                    className="text-sm font-medium text-default-900"
                  >
                    Maintenance Level *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Maintenance Level">
                      Maintenance Level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Maintenance Level"
                      id="demo-simple-select"
                      value={maintenanceLevel}
                      label="Maintenance Level"
                      onChange={(e) => selectMaintenanceLevelChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === maintenanceLevel}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontBrakeCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Break Condition{" "}
                    <span style={{ color: "red" }}>**</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-FrontBrake-Condition">
                      Front Break Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-FrontBrake-Condition"
                      id="demo-simple-select"
                      value={frontBrakeCondition}
                      label="FrontBrake Condition"
                      onChange={(e) => selectFrontBreakConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === frontBrakeCondition}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearBrakeCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Break Condition{" "}
                    <span style={{ color: "red" }}>**</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-RearBrake-Condition">
                      Rear Break Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-RearBrake-Condition"
                      id="demo-simple-select"
                      value={rearBrakeCondition}
                      label="RearBrake Condition"
                      onChange={(e) => selectRearBreakConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === rearBrakeCondition}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionEngineThere"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine There *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Construction-EngineThere">
                      Engine There
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Construction-EngineThere"
                      id="demo-simple-select"
                      value={constructionEngineThere}
                      label="Construction EngineThere"
                      onChange={(e) => selectConstructionEngineThereChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionEngineThere}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionfluidLeaks"
                    className="text-sm font-medium text-default-900"
                  >
                    Fluid Leaks *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Construction-FluidLeaks">
                      {" "}
                      Fluid Leaks{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Construction-FluidLeaks"
                      id="demo-simple-select"
                      value={constructionfluidLeaks}
                      label="Construction FluidLeaks"
                      onChange={(e) => selectConstructionFluidLeaksChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionfluidLeaks}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionEngineShield"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Shield *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Construction-EngineShield">
                      {" "}
                      Engine Shield
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Construction-EngineShield"
                      id="demo-simple-select"
                      value={constructionEngineShield}
                      label="Construction EngineShield"
                      onChange={(e) => selectConstructionEngineShieldChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionEngineShield}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionTransmissionCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission Condition{" "}
                    <span style={{ color: "red" }}>**</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Construction-Transmission">
                      {" "}
                      Transmission Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Construction-Transmission"
                      id="demo-simple-select"
                      value={constructionTransmissionCondition}
                      label="Construction Transmission"
                      onChange={(e) =>
                        selectConstructionTransmissionConditionChange(e)
                      }
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={
                            test.name === constructionTransmissionCondition
                          }
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSuspension"
                    className="text-sm font-medium text-default-900"
                  >
                    Suspension Condition{" "}
                    <span style={{ color: "red" }}>**</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Construction-Suspension">
                      Suspension Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Construction-Suspension"
                      id="demo-simple-select"
                      value={constructionSuspension}
                      label="Construction Suspension"
                      onChange={(e) => selectConstructionSuspensionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionSuspension}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionHandBrake"
                    className="text-sm font-medium text-default-900"
                  >
                    Hand Brake *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Construction-HandBrake">
                      Hand Brake
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Construction-HandBrake"
                      id="demo-simple-select"
                      value={constructionHandBrake}
                      label="Construction HandBrake"
                      onChange={(e) => selectConstructionHandBrakeChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionHandBrake}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionTyreType"
                    className="text-sm font-medium text-default-900"
                  >
                    Tyre Type *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Tyre Type"
                      variant="outlined"
                      className={"textfield"}
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="constructionTyreType"
                      value={constructionTyreType}
                      onChange={(e) => setConstructionTyreType(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionLhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Lh Front *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-LhFront">
                      Lh Front
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-LhFront"
                      id="demo-simple-select"
                      value={constructionLhFront}
                      label="Exterior LhFront"
                      onChange={(e) => selectConstructionLhFrontChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionLhFront}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Rh Front *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-RhFront">
                      Rh Front
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-RhFront"
                      id="demo-simple-select"
                      value={constructionRhFront}
                      label="Exterior RhFront"
                      onChange={(e) => selectConstructionRhFrontChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionRhFront}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionLhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Lh Rear *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-LhRear">
                      Lh Rear
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-LhRear"
                      id="demo-simple-select"
                      value={constructionLhRear}
                      label="Construction LhRear"
                      onChange={(e) => selectConstructionLhRearChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionLhRear}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Rh Rear *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-RhRear">
                      Rh Rear
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-RhRear"
                      id="demo-simple-select"
                      value={constructionRhRear}
                      label="Construction RhRear"
                      onChange={(e) => selectConstructionRhRearChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionRhRear}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSpareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare Tyre *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Spare">
                      Spare Tyre
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Spare"
                      id="demo-simple-select"
                      value={constructionSpare}
                      label="Construction Spare"
                      onChange={(e) => selectConstructionSpareTyreChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionSpare}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSteeringType"
                    className="text-sm font-medium text-default-900"
                  >
                    Steering Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Steering">
                      {" "}
                      Steering Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Steering"
                      id="demo-simple-select"
                      value={constructionSteering}
                      label="Construction Steering"
                      onChange={(e) => selectConstructionSteeringTypeChange(e)}
                    >
                      {carSteeringTypeList.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionSteering}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSteeringCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Steering Condition *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-SteeringCondition">
                      Steering Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-SteeringCondition"
                      id="demo-simple-select"
                      value={constructionSteeringCondition}
                      label="Construction SteeringCondition"
                      onChange={(e) =>
                        selectConstructionSteeringConditionChange(e)
                      }
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionSteeringCondition}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionDashboard"
                    className="text-sm font-medium text-default-900"
                  >
                    Dashboard *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Dashboard">
                      Dashboard
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Dashboard"
                      id="demo-simple-select"
                      value={constructionDashboard}
                      label="Construction Dashboard"
                      onChange={(e) => selectConstructionDashboardChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionDashboard}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSeat"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Seat">
                      Seat
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Seat"
                      id="demo-simple-select"
                      value={constructionSeat}
                      label="Construction Seat"
                      onChange={(e) => selectConstructionSeatChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionSeat}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionInterior"
                    className="text-sm font-medium text-default-900"
                  >
                    Interior *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Interior">
                      Interior
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Interior"
                      id="demo-simple-select"
                      value={constructionInterior}
                      label="Construction Interior"
                      onChange={(e) => selectConstructionInteriorChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionInterior}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionAirbag"
                    className="text-sm font-medium text-default-900"
                  >
                    Airbag *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Airbag">
                      Airbag
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Airbag"
                      id="demo-simple-select"
                      value={constructionAirbag}
                      label="Construction Airbag"
                      onChange={(e) => selectConstructionAirbagChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionAirbag}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionAntiLock"
                    className="text-sm font-medium text-default-900"
                  >
                    AntiLock *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-AntiLock">
                      AntiLock
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-AntiLock"
                      id="demo-simple-select"
                      value={constructionAntiLock}
                      label="Construction AntiLock"
                      onChange={(e) => selectConstructionAntiLockChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionAntiLock}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="constructionPowerWindowFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Power Window Front Available *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-PowerWindowFront">
                      Power Window Front Available
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-PowerWindowFront"
                      id="demo-simple-select"
                      value={constructionPowerWindowFront}
                      label="Construction PowerWindowFront"
                      onChange={(e) =>
                        selectConstructionPowerWindowfrontChange(e)
                      }
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionPowerWindowFront}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionPowerWindowRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Power Window Rear Available *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-PowerWindowRear">
                      {" "}
                      Power Window Rear Available
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-PowerWindowRear"
                      id="demo-simple-select"
                      value={constructionPowerWindowRear}
                      label="Construction PowerWindowRear"
                      onChange={(e) =>
                        selectConstructionPowerWindowRearChange(e)
                      }
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionPowerWindowRear}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSeatBelt"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat Belt *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-SeatBelt">
                      Seat Belt
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-SeatBelt"
                      id="demo-simple-select"
                      value={constructionSeatBelt}
                      label="Construction SeatBelt"
                      onChange={(e) => selectConstructionSeatBeltChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionSeatBelt}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionInsideRearViewMirror"
                    className="text-sm font-medium text-default-900"
                  >
                    Inside Rear View Mirror Condition *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-InsideRearViewMirror">
                      Inside Rear View Mirror Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-InsideRearViewMirror"
                      id="demo-simple-select"
                      value={constructionInsideRearViewMirror}
                      label="Construction InsideRearViewMirror"
                      onChange={(e) =>
                        selectConstructionInsideRearViewMirrorChange(e)
                      }
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={
                            test.name === constructionInsideRearViewMirror
                          }
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionBonnet"
                    className="text-sm font-medium text-default-900"
                  >
                    Bonnet *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Bonnet">
                      Bonnet
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Bonnet"
                      id="demo-simple-select"
                      value={constructionBonnet}
                      label="Construction Bonnet"
                      onChange={(e) => selectConstructionBonnetChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionBonnet}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionFrontBumper"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Bumper *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Front Bumper">
                      Front Bumper
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Front Bumper"
                      id="demo-simple-select"
                      value={constructionFrontBumper}
                      label="Construction Front Bumper"
                      onChange={(e) => selectConstructionFrontBumperChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionFrontBumper}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRearBumper"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Bumper *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-RearBumper">
                      Rear Bumper
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-RearBumper"
                      id="demo-simple-select"
                      value={constructionRearBumper}
                      label="Construction RearBumper"
                      onChange={(e) => selectConstructionRearBumperChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionRearBumper}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionWindScreen"
                    className="text-sm font-medium text-default-900"
                  >
                    Wind Screen *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-WindScreen">
                      {" "}
                      Wind Screen
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-WindScreen"
                      id="demo-simple-select"
                      value={constructionWindScreen}
                      label="Construction WindScreen"
                      onChange={(e) => selectConstructionWindScreenChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionWindScreen}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionWindScreenGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    Wind Screen Glass *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-WindScreen">
                      {" "}
                      Wind Screen Glass
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-WindScreen"
                      id="demo-simple-select"
                      value={constructionWindScreenGlass}
                      label="Construction WindScreen"
                      onChange={(e) =>
                        selectConstructionWindScreenGlassChange(e)
                      }
                    >
                      {qualityList.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionWindScreenGlass}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRearWindScreenGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Wind Screen Glass *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-RearWindScreenGlass">
                      Rear Wind Screen Glass
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-RearWindScreenGlass"
                      id="demo-simple-select"
                      value={constructionRearWindScreenGlass}
                      label="Construction RearWindScreenGlass"
                      onChange={(e) =>
                        selectConstructionRearWindScreenGlassChange(e)
                      }
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={
                            test.name === constructionRearWindScreenGlass
                          }
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRhDoorGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    RhDoor Glass *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-RhDoorGlass">
                      RhDoor Glass
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-RhDoorGlass"
                      id="demo-simple-select"
                      value={constructionRhDoorGlass}
                      label="Construction RhDoorGlass"
                      onChange={(e) => selectConstructionRhDoorGlassChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionRhDoorGlass}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRhSideBody"
                    className="text-sm font-medium text-default-900"
                  >
                    RhSide Body *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-RhSideBody">
                      RhSide Body
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-RhSideBody"
                      id="demo-simple-select"
                      value={constructionRhSideBody}
                      label="Construction RhSideBody"
                      onChange={(e) => selectConstructionLhSideBodyChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionRhSideBody}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionLhSideBody"
                    className="text-sm font-medium text-default-900"
                  >
                    LhSide Body *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-LhSideBody">
                      LhSide Body
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-LhSideBody"
                      id="demo-simple-select"
                      value={constructionLhSideBody}
                      label="Construction LhSideBody"
                      onChange={(e) => selectConstructionLhSideBodysChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionLhSideBody}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionLhDoorGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    LhDoor Glass *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-LhDoorGlass">
                      LhDoor Glass
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-LhDoorGlass"
                      id="demo-simple-select"
                      value={constructionLhDoorGlass}
                      label="Construction LhDoorGlass"
                      onChange={(e) => selectConstructionLhDoorGlassChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionLhDoorGlass}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionOutsideRearView"
                    className="text-sm font-medium text-default-900"
                  >
                    Outside Rear View *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-OutsideRearView">
                      Outside Rear View
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-OutsideRearView"
                      id="demo-simple-select"
                      value={constructionOutsideRearView}
                      label="Construction OutsideRearView"
                      onChange={(e) =>
                        selectConstructionOutsideRearViewChange(e)
                      }
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionOutsideRearView}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionPaint"
                    className="text-sm font-medium text-default-900"
                  >
                    Paint *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Paint">
                      Paint
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Paint"
                      id="demo-simple-select"
                      value={constructionPaint}
                      label="Construction Paint"
                      onChange={(e) => selectConstructionPaintChange(e)}
                    >
                      {qualityList.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionPaint}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionPaintCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Paint Condition *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-PaintCondition">
                      Paint Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-PaintCondition"
                      id="demo-simple-select"
                      value={constructionPaintCondition}
                      label="Construction PaintCondition"
                      onChange={(e) =>
                        selectConstructionPaintConditionChange(e)
                      }
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionPaintCondition}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionOverAllBodyCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Over AllBody Condition *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-OverAllBodyCondition">
                      Over AllBody Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-OverAllBodyCondition"
                      id="demo-simple-select"
                      value={constructionOverAllBodyCondition}
                      label="Construction OverAllBodyCondition"
                      onChange={(e) =>
                        selectConstructionOverAllBodyConditionChange(e)
                      }
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={
                            test.name === constructionOverAllBodyCondition
                          }
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionBattery"
                    className="text-sm font-medium text-default-900"
                  >
                    Battery *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Battery">
                      Battery
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Battery"
                      id="demo-simple-select"
                      value={constructionBattery}
                      label="Construction Battery"
                      onChange={(e) => selectConstructionBatteryChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionBattery}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionInstrumentCluster"
                    className="text-sm font-medium text-default-900"
                  >
                    Instrument Cluster *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-InstrumentCluster">
                      Instrument Cluster
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-InstrumentCluster"
                      id="demo-simple-select"
                      value={constructionInstrumentCluster}
                      label="Construction InstrumentCluster"
                      onChange={(e) =>
                        selectConstructionInstrumentClusterChange(e)
                      }
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionInstrumentCluster}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="constructionFogLampCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    FogLamp Condition *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-FogLampCondition">
                      FogLamp Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-FogLampCondition"
                      id="demo-simple-select"
                      value={constructionFogLampCondition}
                      label="Construction FogLampCondition"
                      onChange={(e) =>
                        selectConstructionFogLampConditionChange(e)
                      }
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionFogLampCondition}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSunroof"
                    className="text-sm font-medium text-default-900"
                  >
                    Sunroof *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Sunroof">
                      Sunroof
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Sunroof"
                      id="demo-simple-select"
                      value={constructionSunroof}
                      label="Construction Sunroof"
                      onChange={(e) => selectConstructionSunroofChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionSunroof}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionMusicSyetem"
                    className="text-sm font-medium text-default-900"
                  >
                    Music System *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-MusicSyetem">
                      Music System{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-MusicSyetem"
                      id="demo-simple-select"
                      value={constructionMusicSyetem}
                      label="Construction MusicSyetem"
                      onChange={(e) => selectConstructionMusicSyetemChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionMusicSyetem}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRoofRails"
                    className="text-sm font-medium text-default-900"
                  >
                    Roof Rails *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-RoofRails">
                      {" "}
                      Roof Rails{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-RoofRails"
                      id="demo-simple-select"
                      value={constructionRoofRails}
                      label="Construction RoofRails"
                      onChange={(e) => selectConstructionRoofRailsChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionRoofRails}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionAntenna"
                    className="text-sm font-medium text-default-900"
                  >
                    Antenna *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-Antenna">
                      Antenna{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-Antenna"
                      id="demo-simple-select"
                      value={constructionAntenna}
                      label="Construction Antenna"
                      onChange={(e) => setConstructionAntenna(e.target.value)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionAntenna}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionReverseCamera"
                    className="text-sm font-medium text-default-900"
                  >
                    Reverse Camera *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-ReverseCamera">
                      Reverse Camera{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-ReverseCamera"
                      id="demo-simple-select"
                      value={constructionReverseCamera}
                      label="Construction ReverseCamera"
                      onChange={(e) => selectConstructionReverseCameraChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === constructionReverseCamera}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="yourRemark"
                    className="text-sm font-medium text-default-900"
                  >
                    Your Remark On Vehicle *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-construction-yourRemark">
                      Your Remark On Vehicle
                    </InputLabel>
                    <Select
                      labelId="demo-simple-construction-yourRemark"
                      id="demo-simple-select"
                      value={yourRemark}
                      label="Construction yourRemark"
                      onChange={(e) => selectYourRemarkChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem
                          value={test.name}
                          key={index}
                          selected={test.name === yourRemark}
                        >
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Ex-ShowRoom Price <span style={{ color: "red" }}>**</span>
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Ex-ShowRoom Price"
                      variant="outlined"
                      className={
                        exShowRoomPriceError === ""
                          ? `textfield`
                          : `textfield error_class`
                      }
                      style={{ marginTop: "3px" }}
                      type="text"
                      id="text"
                      value={exShowRoomPrice}
                      onChange={(e) => setExShowRoomPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fEstimatedPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Estimated Price <span style={{ color: "red" }}>**</span>
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Estimated Price"
                      variant="outlined"
                      className={
                        estimatedPriceError === ""
                          ? `textfield`
                          : `textfield error_class`
                      }
                      style={{ marginTop: "3px" }}
                      type="text"
                      id="text"
                      value={estimatedPrice}
                      onChange={(e) => setEstimatedPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "80px" }}
          >
            <button
              onClick={(e) => cancelClick(e)}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
              style={{ outline: "none" }}
            >
              <i className="ph-bold ph-x text-lg" /> cancel
            </button>
            {
              buttonLoading ?
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{ background: "grey", outline: "none", border: "none" }}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Submitting
                </button>
                :
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  onClick={(e) => handlesubmit(e)}
                  style={{ background: "#4D44B5", outline: "none", border: "none" }}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Submit
                </button>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default AddConstructionWheelerCase;
