import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { CSVLink } from "react-csv";
import { Switch } from "antd";

const GetAllCase = () => {
    const { totalAdmins } = useSelector((store) => store.auth);
    const { caseList } = useSelector((store) => store.cases);
    const { employeeList } = useSelector((store) => store.employee);
    const { loginData } = useSelector((store) => store.auth)
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [urlPath, setUrlPath] = useState('')

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [filteredCase, setFilteredCase] = useState([]);
    const [filteredEmployee, setFilteredEmployee] = useState([]);
    const [filteredForEmployee, setFilteredForEmployee] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [loadData, setLoadData] = useState([]);
    const [role, setRole] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        const getAllReports = caseList.slice().reverse()
        if (loginData?.role === 'businessAssociate') {
            const data = getAllReports.filter((indCase) =>
                loginData.permittedState.includes(indCase.state)
            )
            setFilteredData(data)
            setFilteredCase(data)
        } else {
            setFilteredData(getAllReports)
            setFilteredCase(getAllReports)
        }
    }, [caseList])

    useEffect(() => {
        const uniqueEmployees = removeDuplicateEmployees(employeeList);

        // Set the filteredEmployee state with the unique list of employees
        setFilteredEmployee(uniqueEmployees);
    }, [employeeList]);

    // Function to remove duplicate employees based on 'role'
    const removeDuplicateEmployees = (employees) => {
        // Use a map to keep track of unique roles
        const roleMap = new Map();
        const uniqueEmployees = [];

        // Iterate over each employee
        employees.forEach((employee) => {
            const role = employee.role;

            // Check if role is not already added to the map
            if (!roleMap.has(role)) {
                // Add role to the map to mark it as seen
                roleMap.set(role, true);

                // Add the employee to the unique list
                uniqueEmployees.push(employee);
            }
        });

        return uniqueEmployees;
    };


    useEffect(() => {
        let finalexport = [];
        let formdata = {};
        for (let index = 0; index < filteredData.length; index++) {

            const currentDate = new Date(filteredData[index].submittedAt);
            const createdDate = new Date(filteredData[index].createdAt);

            const element = filteredData[index];
            formdata = {
                "No": index + 1,
                "Case Id": element.caseId,
                "Name": element.name,
                "Mobile": element.mobile,
                "Email": element.email,
                "Client Name": element.requesterName,
                "Client Branch": element.requesterBranch,
                "Client Phone": element.requesterPhone,
                "Loan No": element.loanAgreementNo,
                "Case Type": element.caseType,
                "Vehicle Type": element.assetType,
                "Vehicle Make": element.brand,
                "Vehicle Model": element.model,
                "Vehicle Variant": element.variant,
                "Reg No.": element.regdNo,
                "Chasis No.": element.chasisNo,
                "Engine No.": element.engNo,
                "Meter Reading": element.meterReading,
                "Date Of Reg": element.regdDate,
                "Fuel Type": element.fueltype,
                "Vehicle Color": element.vehicleColor,
                "Number Plate": element.numberPlate,
                "Insurance Expiry": element.otherDetails.insuranceExpiry,
                "Vehicle Exshowroom Price": element.marketPrice,
                "Estimated Market Price": element.valuation,
                "Vehicle Location": element.vehicleLocation,
                "Vehicle City": element.city,
                "Vehicle State": element.state,
                "Case Created Date": createdDate.toDateString(),
                "Case Created Time": createdDate.toLocaleTimeString(),
                "Field Service By": element.fieldServiceBy,
                "Field Service Done Date": currentDate.toDateString(),
                "Field Service Done Time": currentDate.toLocaleTimeString(),
                "Field Service Remark": element.yourRemark,
                "paymentStatus": element.paymentStatus,
                "paymentRemark": element.paymentRemark,
                "Pdf Report Url": element.reportUrl,
                "Selfie With Vehicle": element.selfieWithVehicle,
                "Video Url": element.videos,
                'fieldServiceByName': element.fieldServiceBy
            }
            finalexport = [...finalexport, formdata];
        }
        setLoadData([...finalexport]);
    }, [filteredData])


    const handleStartDateChange = (e) => {
        const date = e.target.value;
        setStartDate(date);
        filterData(date, endDate);
    };

    const handleEndDateChange = (e) => {
        const date = e.target.value;
        setEndDate(date);
        filterData(startDate, date);
    };

    const filterData = (start, end) => {
        if (start && end) {
            const startDate = new Date(start);
            const endDate = new Date(end);

            // Filter the cases based on date range
            if (startDate === endDate) {
                const filtered = filteredCase.filter((item) =>
                    item.createdAt === startDate
                )
                setFilteredData(filtered);
            } else {
                const filtered = filteredCase.filter(item => {
                    const itemDate = new Date(item.createdAt);
                    return itemDate >= startDate && itemDate <= endDate;
                });
                setFilteredData(filtered);
            }
        }
    };

    const selectRoleChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setRole(text);
        const employeeNew = employeeList.filter((individualEmployee) =>
            individualEmployee.role === text
        )
        setFilteredForEmployee(employeeNew)
    };
    const selectNameChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setName(text);
        const employeeNew = employeeList.filter((individualEmployee) =>
            individualEmployee.name === text
        )
        setFilterName(employeeNew)
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full" style={{ marginBottom: '60px' }}>
                <div className="p-3 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px' }}>
                            {/* Start Date Input */}
                            <label htmlFor="startDateInput">Start Date:</label>
                            <input
                                type="date"
                                id="startDateInput"
                                value={startDate}
                                onChange={handleStartDateChange}
                                style={{ borderColor: "#4D44B5", borderRadius: '5px' }}
                            />

                            {/* End Date Input */}
                            <label htmlFor="endDateInput">End Date:</label>
                            <input
                                type="date"
                                id="endDateInput"
                                value={endDate}
                                onChange={handleEndDateChange}
                                style={{ borderColor: "#4D44B5", borderRadius: '5px' }}
                            />
                        </div>
                        <div className="p-1">
                            <CSVLink
                                data={loadData}
                                filename={"instavaluer-Submitted-data.csv"}
                                onClick={() => { }}
                            >
                                <button
                                    className="mt-0 btn btn-primary mb-2 download-responses-button"
                                    style={{
                                        background: "#3270fc",
                                        border: "1px solid #3270fc",
                                        padding: "12px",
                                        color: "#fff",
                                        borderRadius: "3px",
                                    }}
                                >
                                    Download Report
                                </button>
                            </CSVLink>
                        </div>
                    </div>
                    {/* <div className="flex items-center flex-start w-full print:hidden" style={{ gap: '20px' }}>
                        <div className="space-y-1">
                            <select
                                className={`border-default-200 w-full`}
                                data-trigger
                                id="seatCover"
                                placeholder="This is a search placeholder"
                                value={filteredEmployee._id}
                                name={filteredEmployee.role}
                                style={{ borderColor: "#4D44B5", borderRadius: '5px' }}
                                onChange={(e) => selectRoleChange(e)}
                            >
                                <option value="0" hidden selected>
                                    Choose Employee Role
                                </option>
                                {filteredEmployee.map((test, index) => (
                                    <option
                                        key={index}
                                        value={test.id}
                                        name={test.role}
                                        required
                                        selected={test.role === role}
                                    >
                                        {test.role}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="space-y-1">
                            <select
                                className={`border-default-200 w-full`}
                                data-trigger
                                id="seatCover"
                                placeholder="This is a search placeholder"
                                value={filteredForEmployee._id}
                                name={filteredForEmployee.name}
                                onChange={(e) => selectNameChange(e)}
                                style={{ borderColor: "#4D44B5", borderRadius: '5px' }}
                            >
                                <option value="0" hidden selected>
                                    Choose Employee Name
                                </option>
                                {filteredForEmployee.map((test, index) => (
                                    <option
                                        key={index}
                                        value={test.id}
                                        name={test.name}
                                        required
                                        selected={test.name === name}
                                    >
                                        {test.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div> */}
                </div>
                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50">
                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Reg No.
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Requester Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Requester Branch
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Mobile
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Field Service By
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Create Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Vehicle Preview
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-2 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Signature
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Payment Status
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Payment Remark
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {filteredData &&
                                            filteredData.map((admin, index) => (
                                                <tr key={index}>
                                                    <td className="px-6  text-center text-default-600 font-semibold whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        <b>{admin.caseId}</b>
                                                    </td>
                                                    <td className="px-6  text-center text-default-600 font-semibold whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        {admin.regdNo}
                                                    </td>
                                                    <td className="px-6  text-center text-default-600 font-semibold whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        {admin.requesterName}
                                                    </td>
                                                    <td className="px-6  text-center text-default-600 font-semibold whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        {admin.requesterBranch}
                                                    </td>
                                                    <td className="px-6  text-center whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        <h6 className="text-sm font-semibold text-default-700">
                                                            {admin.name.length > 30 ? (
                                                                <>{admin.name.slice(0, 30)}...</>
                                                            ) : (
                                                                <>{admin.name.slice(0, 30)}</>
                                                            )}
                                                        </h6>
                                                    </td>
                                                    <td className="px-6  text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        {admin.mobile}
                                                    </td>
                                                    <td className="px-6  text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        {admin.email.length > 18 ? (
                                                            <>{admin.email.slice(0, 18)}...</>
                                                        ) : (
                                                            <>{admin.email.slice(0, 18)}</>
                                                        )}
                                                    </td>
                                                    <td className="px-6  text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        <div className="d-flex m-auto">
                                                            {admin.fieldServiceBy}
                                                        </div>
                                                    </td>
                                                    <td className="px-6  text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        <div className="d-flex m-auto">
                                                            {admin.createdAt.slice(0, 10)}
                                                        </div>
                                                    </td>
                                                    <td className="px-6  text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        <div className="d-flex m-auto">
                                                            <img
                                                                src={admin.images.frontImage}
                                                                style={{ height: "50px", width: "80px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="px-6  text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        <div className="d-flex m-auto">
                                                            <img
                                                                src={admin.signNdoc.sign}
                                                                style={{ height: "50px", width: "80px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        <div className="flex items-center justify-center gap-2">
                                                            <Switch checked={admin?.paymentStatus ? true : false} />
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                    >
                                                        <div className="d-flex m-auto">
                                                            {admin.paymentRemark}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
                            {/* <h6 className="text-default-600">Showing 1 to 5 of 12</h6> */}
                            {/* <nav className="flex items-center gap-1">
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-left text-base" />
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border rounded-md transition-all duration-200 bg-primary text-white border-primary"
                                    to="#"
                                    aria-current="page"
                                >
                                    1
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    2
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    ...
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    12
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-right text-base" />
                                </a>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GetAllCase;