import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  uploadAnyDamage,
  UploadChasisImage,
  uploadExtraImage,
  uploadExtraImage1,
  uploadExtraImage2,
  uploadExtraImage3,
  uploadformatImage,
  UploadFrontImage,
  uploadFrontUnderCar,
  uploadGlassInsideImage,
  uploadGlassOutside,
  uploadLeft45,
  uploadLeftImage,
  uploadOdometerImage,
  UploadOpenBonnet,
  uploadOpenDickey,
  uploadRcBackImage,
  uploadRcFront,
  uploadRearImage,
  UploadRearUnderPart,
  UploadRight45Image,
  uploadRightImage,
  uploadRpmImage,
  uploadSignature,
  uploadTyreImage,
} from '../../redux/Cases/CaseSlice';

const ImageUpload = () => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const actionsArray = [
    (img) => dispatch(UploadChasisImage({ file: img })),
    (img) => dispatch(uploadformatImage({ file: img })),
    (img) => dispatch(UploadOpenBonnet({ file: img })),
    (img) => dispatch(UploadRight45Image({ file: img })),
    (img) => dispatch(uploadFrontUnderCar({ file: img })),
    (img) => dispatch(uploadLeft45({ file: img })),
    (img) => dispatch(uploadLeftImage({ file: img })),
    (img) => dispatch(uploadRearImage({ file: img })),
    (img) => dispatch(UploadRearUnderPart({ file: img })),
    (img) => dispatch(uploadOpenDickey({ file: img })),
    (img) => dispatch(uploadRightImage({ file: img })),
    (img) => dispatch(uploadTyreImage({ file: img })),
    (img) => dispatch(uploadGlassOutside({ file: img })),
    (img) => dispatch(uploadGlassInsideImage({ file: img })),
    (img) => dispatch(uploadOdometerImage({ file: img })),
    (img) => dispatch(uploadRpmImage({ file: img })),
    (img) => dispatch(uploadAnyDamage({ file: img })),
    (img) => dispatch(uploadExtraImage({ file: img })),
    (img) => dispatch(uploadExtraImage1({ file: img })),
    (img) => dispatch(uploadExtraImage2({ file: img })),
    (img) => dispatch(uploadExtraImage3({ file: img })),
    (img) => dispatch(uploadSignature({ file: img })),
    (img) => dispatch(uploadRcFront({ file: img })),
    (img) => dispatch(uploadRcBackImage({ file: img })),
  ];

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files); // Reset images with new files
  };

  const updateImage = async () => {
    setLoading(true); // Start loading
    const promises = images.map((image, index) => {
      if (actionsArray[index]) {
        return actionsArray[index](image); // Handle each image upload
      }
      return Promise.resolve(); // Resolve empty promises for unused actions
    });

    try {
      await Promise.all(promises); // Wait for all uploads to finish
      alert('All images uploaded successfully!'); // Notify user
    } catch (error) {
      console.error('Upload failed:', error);
      alert('Some images failed to upload.'); // Notify user of error
    } finally {
      setLoading(false); // Stop loading
      setImages([]); // Clear images
    }
  };

  return (
    <div>
      <div style={{ color: '#000', marginBottom: '5px' }} className="text-sm font-medium text-default-900">
        Upload Multiple Images
        <span style={{ color: 'red', paddingLeft: '5px' }}>***</span>
      </div>
      <input type="file" multiple onChange={handleFileChange} />
      <button onClick={updateImage} style={{ backgroundColor: '#4d44b5', color: '#fff', padding: '10px 5px', borderRadius: '5px' }}>
        Upload Images
      </button>
      {loading && <p>Uploading...</p>}
    </div>
  );
};

export default ImageUpload;