import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminPost, valiadteEmail, valiadteNumber } from "../../redux/Authentication/AuthSlice";
import { createEmployee, emailExists, getIndividualEmployee, mobileExists, updateEmployee } from "../../redux/Employee/employeeSlice";
import { useParams } from "react-router-dom";
// import {
//   adminPost,
//   valiadteEmail,
//   valiadteNumber,
// } from "../../../redux/Authentication/AuthSlice";

const UpdateEmployee = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const [email, setEmail] = useState("");
    const [Cnfemail, setCnfEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileCnf, setMobileCnf] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [Password, setPassword] = useState("");
    const [PasswordError, setPasswordError] = useState("");
    const [allowedState, setAllowedState] = useState([])
    const [allowedStateError, setAllowedStateError] = useState('')

    const [role, setRole] = useState("")
    // const [manager, setManager] = useState(false);
    // const [managerError, setManagerError] = useState("");
    // const [executive, setExecutive] = useState(false);
    // const [executiveError, setExecutiveError] =
    //     useState("");
    // const [backendEmployee, setBackendEmployee] = useState(false);
    // const [backendEmployeeError, setBackendEmployeeError] = useState("");
    // const [agent, setAgent] = useState(false);
    // const [agentError, setAgentError] = useState("");

    const stateList = [
        { id: 1, name: "Andhra Pradesh" },
        { id: 2, name: "Arunachal Pradesh" },
        { id: 3, name: "Assam" },
        { id: 4, name: "Bihar" },
        { id: 5, name: "Chhattisgarh" },
        { id: 6, name: "Goa" },
        { id: 7, name: "Gujarat" },
        { id: 8, name: "Haryana" },
        { id: 9, name: "Himachal Pradesh" },
        { id: 10, name: "Jharkhand" },
        { id: 11, name: "Karnataka" },
        { id: 12, name: "Kerala" },
        { id: 13, name: "Madhya Pradesh" },
        { id: 14, name: "Maharashtra" },
        { id: 15, name: "Manipur" },
        { id: 16, name: "Meghalaya" },
        { id: 17, name: "Mizoram" },
        { id: 18, name: "Nagaland" },
        { id: 19, name: "Odisha" },
        { id: 20, name: "Punjab" },
        { id: 21, name: "Rajasthan" },
        { id: 22, name: "Sikkim" },
        { id: 23, name: "Tamil Nadu" },
        { id: 24, name: "Telangana" },
        { id: 25, name: "Tripura" },
        { id: 26, name: "Uttar Pradesh" },
        { id: 27, name: "Uttarakhand" },
        { id: 28, name: "West Bengal" },
        { id: 29, name: "Andaman and Nicobar Islands" },
        { id: 30, name: "Chandigarh" },
        { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
        { id: 32, name: "Delhi" },
        { id: 33, name: "Ladakh" },
        { id: 34, name: "Lakshadweep" },
        { id: 35, name: "Puducherry" }
    ];

    const [btnPress, setbtnPress] = useState(false);

    const handleState = (state) => {
        if (!allowedState.includes(state)) {
            setAllowedState([...allowedState, state])
        } else {
            const stateList = allowedState.filter((indState) => indState !== state)
            setAllowedState(stateList)
        }
    }

    useEffect(() => {
        const getEmployee = async () => {
            const getEmployee = await dispatch(getIndividualEmployee(params._id))
            console.log(getEmployee.payload.employee, 'hiii');
            if (getEmployee.payload.success) {
                setName(getEmployee.payload.employee.name);
                setMobile(getEmployee.payload.employee.phone);
                setEmail(getEmployee.payload.employee.email);
                setRole(getEmployee.payload.employee.role);
                setCnfEmail(getEmployee.payload.employee.email);
                setMobileCnf(getEmployee.payload.employee.phone);
                setAllowedState(getEmployee.payload.employee.permittedState);
                // setAllowedState(getEmployee.payload)
            }
        }
        getEmployee()
    }, [])

    useEffect(() => {
        if (btnPress === true) {
            if (name === "") {
                setNameError("please enter title");
            } else {
                setNameError("");
            }
            if (Password === "") {
                setPasswordError("please enter Password");
            } else {
                setPasswordError("");
            }
            if (Cnfemail === "") {
                setEmailError("please enter email");
            } else {
                setEmailError("");
            }
            if (mobileCnf.length !== 10) {
                setMobileError("please enter mobile");
            } else {
                setMobileError("");
            }
        }
    }, [btnPress, name, Password, Cnfemail, mobileCnf]);

    const setNumberChange = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMobileError("");
        setMobile(value);
        if (value.length === 10) {
            const verifyNumber = await dispatch(mobileExists(value));
            if (verifyNumber.payload.success) {
                setMobileError("Number already exist");
            } else {
                setMobileError("");
                setMobileCnf(value);
            }
        } else {
            setMobileError("Please Enter Valid Number");
        }
    };

    const emailChange = async (e) => {
        const value = e;
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmail(value);
        setEmailError("");
        if (value.length > 0) {
            if (value.match(mailformat)) {
                const verifyEmail = await dispatch(emailExists(value));
                if (verifyEmail.payload.success) {
                    setEmailError("Number already exist");
                } else {
                    setEmailError("");
                    setCnfEmail(value);
                }
            } else {
                setEmailError("please enter correct Email format");
            }
        } else {
            setEmailError("please enter correct Email format");
        }
    };

    const cancelClick = async (e) => {
        e.preventDefault();
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setPassword("");
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        if (name === "") {
            setNameError("please enter title");
        } else {
            setNameError("");
        }
        if (Cnfemail === "") {
            setEmailError("please enter email");
        } else {
            setEmailError("");
        }
        if (mobileCnf.length !== 10) {
            setMobileError("please enter mobile");
        } else {
            setMobileError("");
        }
        if (name !== "" && Cnfemail !== "" && mobile !== "") {
            const formData = {
                _id: params._id,
                name: name,
                role: role,
                email: Cnfemail,
                phone: mobile,
            };
            console.log(formData, "formData");
            const admin = await dispatch(updateEmployee(formData));
            if (admin.payload.success) {
                alert("Employee updated successfully");
                setEmail("");
                setCnfEmail("");
                setMobile("");
                setMobileCnf("");
                setName("");
                setPassword("");
                setRole("")
            }
        } else {
            setbtnPress(true);
        }
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Update Employee Details
                        </h4>
                    </div>
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-4 px-5">
                            <h4 className="text-lg font-medium text-default-950 capitalize">
                                Update Employee Details
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-2 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Name *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            nameError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fName"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value.trim())}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Phone *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            mobileError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="lName"
                                        placeholder="Enter Phone"
                                        minLength={10}
                                        maxLength={10}
                                        value={mobile}
                                        onChange={(e) => setNumberChange(e)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Email *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            emailError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="rollNo"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) => emailChange(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between py-4 px-5">
                            <h4 className="text-lg font-medium text-default-950 capitalize">
                                Role Assign
                            </h4>
                        </div>
                    </div>
                    <div className="p-5 border-t border-dashed border-default-200">
                        <div className="grid md:grid-cols-3 gap-4">
                            <div className="space-y-1">
                                <div className="radio-wrapper-6">
                                    <input
                                        className="tgl tgl-light"
                                        id="businessAssociate"
                                        type="radio"
                                        name="role"
                                        value="businessAssociate"
                                        checked={role === "businessAssociate"}
                                        onChange={(e) => {
                                            setRole(e.target.value)
                                        }}
                                    />
                                    <label
                                        className="text-sm font-medium text-default-900 ps-2"
                                        htmlFor="businessAssociate">Business Associate
                                    </label>
                                </div>
                            </div>
                            <div className="space-y-1">
                                <div className="radio-wrapper-6">
                                    <input
                                        className="tgl tgl-light"
                                        id="executive"
                                        type="radio"
                                        name="role"
                                        value="executive"
                                        checked={role === "executive"}
                                        onChange={(e) => {
                                            setRole(e.target.value);
                                        }}
                                    />
                                    <label
                                        className="text-sm font-medium text-default-900 ps-2"
                                        htmlFor="executive">
                                        Executive
                                    </label>
                                </div>
                            </div>
                            <div className="space-y-1">
                                <div className="radio-wrapper-6">
                                    <input
                                        className="tgl tgl-light"
                                        id="agent"
                                        type="radio"
                                        name="role"
                                        value="agent"
                                        checked={role === "agent"}
                                        onChange={(e) => {
                                            setRole(e.target.value);
                                        }}
                                    />
                                    <label
                                        className="text-sm font-medium text-default-900 ps-2"
                                        htmlFor="agent">
                                        Agent
                                    </label>
                                </div>
                            </div>
                            <div className="space-y-1">
                                <div className="radio-wrapper-6">
                                    <input
                                        className="tgl tgl-light"
                                        id="agent"
                                        type="radio"
                                        name="role"
                                        value="Coordinater"
                                        checked={role === "Coordinater"}
                                        onChange={(e) => {
                                            setRole(e.target.value);
                                        }}
                                    />
                                    <label
                                        className="text-sm font-medium text-default-900 ps-2"
                                        htmlFor="agent"
                                    >
                                        Coordinater
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between py-1 px-5">
                        <h4 className="text-lg font-medium text-default-950 capitalize">
                            Choose State
                        </h4>
                    </div>
                    <div className="px-5" style={{ color: 'red' }}>{allowedStateError}</div>
                    <div className="p-5 border-t border-dashed border-default-200">
                        <div className="grid md:grid-cols-1 gap-4">
                            <div className="space-y-1">
                                <div className="radio-wrapper-6" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                    {
                                        stateList.map((indState, index) =>
                                            <span style={{ margin: '10px', width: '15%' }}>
                                                <input
                                                    className="tgl tgl-light"
                                                    id={`state-${index}`}
                                                    type="checkbox"
                                                    name="state"
                                                    value={indState.name}
                                                    checked={allowedState.includes(indState.name)}
                                                    onChange={(e) => {
                                                        handleState(e.target.value)
                                                    }}
                                                />
                                                <label
                                                    className="text-sm font-medium text-default-900 ps-2"
                                                    htmlFor={`state-${index}`}
                                                >
                                                    {indState.name}
                                                </label>
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '60px' }}>
                        <button
                            onClick={(e) => cancelClick(e)}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                        >
                            <i className="ph-bold ph-x text-lg" /> cancel
                        </button>
                        <button
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                            style={{ background: "#d93c92" }}
                            onClick={(e) => handlesubmit(e)}
                        >
                            <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateEmployee;
