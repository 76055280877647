import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const VehicleInfo = () => {
    const { vehicleInfo } = useSelector((store) => store.cases)
    const [caseinfo, setCaseInfo] = useState('')
    useEffect(() => {
        if (vehicleInfo) {
            setCaseInfo(vehicleInfo)
        }
    }, [])

    return (
        <>
            {
                caseinfo ? (<>
                    <div className="min-h-screen flex flex-col w-full" style={{ marginBottom: '60px' }}>
                        <div className="p-6 space-y-6">
                            <table
                                className="rcrowheight rtborder"
                                style={{ borderCollapse: "collapse" }}
                                width="100%"
                            >
                                <tbody>
                                    <tr style={{ height: "10px" }}>
                                        <td>
                                            <strong>REGISTRATION DETAILS</strong>
                                        </td>
                                        <td>
                                            <strong>AS PER VAHAN</strong>
                                        </td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Vehicle Registration No</td>
                                        <td>{caseinfo.regNo}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Vehicle Mfg Name </td>
                                        <td>{caseinfo.vehicleManufacturerName}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%" >Vehicle Model </td>
                                        <td>{caseinfo.model}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Chasis No&nbsp;:&nbsp;</td>
                                        <td>{caseinfo.chassis}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Engine No&nbsp;:&nbsp;</td>
                                        <td> {caseinfo.engine}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Vehicle Color</td>
                                        <td>{caseinfo.vehicleColour}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Fuel Type&nbsp;:&nbsp;</td>
                                        <td>{caseinfo.type}</td>
                                    </tr>

                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Owner Serial No</td>
                                        <td>{caseinfo.ownerCount}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Reg Owner Name</td>
                                        <td style={{ fontSize: '12px' }}>{caseinfo.owner}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Date Of Registration</td>
                                        <td> {caseinfo.regDate}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">RTA Name</td>
                                        <td style={{ fontSize: '12px' }}> {caseinfo.regAuthority}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Year Of Manufacture</td>
                                        <td>{caseinfo.vehicleManufacturingMonthYear}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Fitness Expiry Date</td>
                                        <td>{caseinfo.rcExpiryDate}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Insurance Exp Date</td>
                                        <td>{caseinfo.vehicleInsuranceUpto}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Tax Valid Up To</td>
                                        <td>{caseinfo.vehicleTaxUpto}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Permit Up To</td>
                                        <td>{caseinfo.permitValidUpto}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Permit Type</td>
                                        <td>{caseinfo.permitType}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Hypothecation</td>
                                        <td>{caseinfo.rcFinancer}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Cubic Capacity</td>
                                        <td>{caseinfo.vehicleCubicCapacity}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Gross Vehicle Weight</td>
                                        <td>{caseinfo.grossVehicleWeight}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Seating Capacity</td>
                                        <td> {caseinfo.vehicleSeatCapacity}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Number Plate</td>
                                        <td>{caseinfo.vehicleColour}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Status</td>
                                        <td>{caseinfo.status}</td>
                                    </tr>
                                    <tr style={{ height: "10px" }}>
                                        <td width="35%">Blacklist Status</td>
                                        <td>{caseinfo.blacklistStatus}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>) : (<></>)
            }
        </>
    )
}
export default VehicleInfo