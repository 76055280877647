import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { CSVLink } from "react-csv";

const MonthlyAssignedCase = () => {
    const { caseCreated } = useSelector((store) => store.cases);
    const { loginData } = useSelector((store) => store.auth)
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [filteredCase, setFilteredCase] = useState([]);
    const [loadData, setLoadData] = useState([]);

    useEffect(() => {
        const getAllReports=caseCreated.slice().reverse()
        if(loginData ?.role === 'businessAssociate'){
            const data=getAllReports.filter((indCase)=>
                loginData.permittedState.includes(indCase.state)
            )
            setFilteredData(data)
            setFilteredCase(data)
        }else{
            setFilteredData(getAllReports)
            setFilteredCase(getAllReports)
        }
    }, [caseCreated])

    useEffect(() => {
        let finalexport = [];
        let formdata = {};
        for (let index = 0; index < filteredData.length; index++) {
            
            const currentDate = new Date(filteredData[index].submittedAt);
            const createdDate = new Date(filteredData[index].createdAt);

            const element = filteredData[index];
            formdata = {
                "No": index + 1,
                "Case Id": element.caseId,
                "Name": element.name,
                "Mobile": element.mobile,
                "Email": element.email,
                "Client Name":element.requesterName,
                "Client Branch":element.requesterBranch,
                "Client Phone":element.requesterPhone,
                "Loan No": element.loanAgreementNo,
                "Case Type": element.caseType,
                "Vehicle Type": element.assetType,
                "Vehicle Make": element.brand,
                "Vehicle Model": element.model,
                "Vehicle Variant": element.variant,
                "Reg No.": element.regdNo,
                "Chasis No.": element.chasisNo,
                "Engine No.": element.engNo,
                "Meter Reading": element.meterReading,
                "Date Of Reg": element.regdDate,
                "Fuel Type": element.fueltype,
                "Vehicle Color": element.vehicleColor,
                "Number Plate": element.numberPlate,
                "Insurance Expiry": element.otherDetails.insuranceExpiry,
                "Vehicle Exshowroom Price": element.marketPrice,
                "Estimated Market Price": element.valuation,
                "Vehicle Location": element.vehicleLocation,
                "Vehicle City": element.city,
                "Vehicle State": element.state,
                "Case Created Date": createdDate.toDateString(),
                "Case Created Time": createdDate.toLocaleTimeString(),
                "Field Service By": element.fieldServiceBy,
                "Field Service Done Date": currentDate.toDateString(),
                "Field Service Done Time": currentDate.toLocaleTimeString(),
                "Field Service Remark": element.yourRemark,
                "Pdf Report Url": element.reportUrl,
                "Selfie With Vehicle": element.selfieWithVehicle,
                "Video Url": element.videos,
            }
            if (element.fieldServiceBy === "executive") {
                formdata.fieldServiceByName = element.executive.name
            } else if (element.fieldServiceBy === "agent") {
                formdata.fieldServiceByName = element.agent.name
            } else if (element.fieldServiceBy === "client") {
                formdata.fieldServiceByName = element.client.name
            } else if (element.fieldServiceBy === "customer") {
                formdata.fieldServiceByName = element.customer.name
            }
            finalexport = [...finalexport, formdata];
        }
        setLoadData([...finalexport]);
    }, [filteredData])
  

    const handleStartDateChange = (e) => {
        const date = e.target.value;
        setStartDate(date);
        filterData(date, endDate);
    };

    const handleEndDateChange = (e) => {
        const date = e.target.value;
        setEndDate(date);
        filterData(startDate, date);
    };

    const filterData = (start, end) => {
        if (start && end) {
            const startDate = new Date(start);
            const endDate = new Date(end);
            if (startDate === endDate) {
                const filtered = filteredCase.filter((item) =>
                    item.createdAt === startDate
                )
                setFilteredData(filtered);
            } else {
                const filtered = filteredCase.filter(item => {
                    const itemDate = new Date(item.createdAt);
                    return itemDate >= startDate && itemDate <= endDate;
                });
                setFilteredData(filtered);
            }
        }
    };


    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px' }}>
                            {/* Start Date Input */}
                            <label htmlFor="startDateInput">Start Date:</label>
                            <input
                                type="date"
                                id="startDateInput"
                                value={startDate}
                                onChange={handleStartDateChange}
                                style={{ borderColor: "#4D44B5", borderRadius: '5px' }}
                            />

                            {/* End Date Input */}
                            <label htmlFor="endDateInput">End Date:</label>
                            <input
                                type="date"
                                id="endDateInput"
                                value={endDate}
                                onChange={handleEndDateChange}
                                style={{ borderColor: "#4D44B5", borderRadius: '5px' }}
                            />
                        </div>
                        <div className="p-1">
                            <CSVLink
                                data={loadData}
                                filename={"instavaluer-casecreated-data.csv"}
                                onClick={() => { }}
                            >
                                <button
                                    className="mt-0 btn btn-primary mb-3 download-responses-button"
                                    style={{
                                        background: "#3270fc",
                                        border: "1px solid #3270fc",
                                        padding: "12px",
                                        color: "#fff",
                                        borderRadius: "3px",
                                    }}
                                >
                                    Download Report
                                </button>
                            </CSVLink>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50">

                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Reg No.
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Requester Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Requester Branch
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Mobile
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Status
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Agent
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {filteredData &&
                                            filteredData
                                                .map((admin, index) => (
                                                    <tr key={index}>
                                                        <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            <b>{admin.caseId}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.regdNo}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.requesterName}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.requesterBranch}
                                                        </td>
                                                        <td className="px-6 py-3 text-center whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            <h6 className="text-sm font-semibold text-default-700">
                                                                {admin.name.length > 30 ? (
                                                                    <>{admin.name.slice(0, 30)}...</>
                                                                ) : (
                                                                    <>{admin.name.slice(0, 30)}</>
                                                                )}
                                                            </h6>
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.mobile}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.email.length > 18 ? (
                                                                <>{admin.email.slice(0, 18)}...</>
                                                            ) : (
                                                                <>{admin.email.slice(0, 18)}</>
                                                            )}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.statusText}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.agent ?
                                                                (<></>) :
                                                                (<>Not Assigned</>)
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
                            <h6 className="text-default-600">Showing {filteredData.length} results</h6>
                            {/* <nav className="flex items-center gap-1">
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-left text-base" />
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border rounded-md transition-all duration-200 bg-primary text-white border-primary"
                                    to="#"
                                    aria-current="page"
                                >
                                    1
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    2
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    ...
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    12
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-right text-base" />
                                </a>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MonthlyAssignedCase