import React from 'react'
import ClientHeader from './ClientHeader'
import Footer from '../../component/layout/Footer'
import ClientDashboard from './ClientDashboard'

const ClientPage = () => {
  return (
    <div>
        <ClientHeader/>
        <ClientDashboard/>
        <Footer/>
    </div>
  )
}

export default ClientPage