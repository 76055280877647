import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminPost,
  valiadteEmail,
  valiadteNumber,
} from "../../redux/Authentication/AuthSlice";
import { createEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import {
  CaseActions,
  UploadChasisImage,
  UploadFrontImage,
  UploadOpenBonnet,
  UploadRearUnderPart,
  UploadRight45Image,
  createCase,
  selfieWithVehicle,
  selfieWithVehicleUpload,
  sendVehicleNumber,
  uploadAnyDamage,
  uploadExtraImage,
  uploadExtraImage1,
  uploadExtraImage2,
  uploadExtraImage3,
  uploadFrontUnderCar,
  uploadGlassInsideImage,
  uploadGlassOutside,
  uploadLeft45,
  uploadLeftImage,
  uploadOdometerImage,
  uploadOpenDickey,
  uploadRcBackImage,
  uploadRcFront,
  uploadRearImage,
  uploadRightImage,
  uploadRpmImage,
  uploadSignature,
  uploadTyreImage,
  uploadformatImage,
} from "../../redux/Cases/CaseSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import FourWheelerEditReport4 from "../Reports/FourWheelerEditReport4";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { TextField } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import ImageUpload from "./UploadImage";

const AddFourWheelerCase = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { totalClients } = useSelector((store) => store.client)
  const {
    selfieWithVehicleImage,
    isSelfieWithAgentLoading,
    chasisImage,
    isChasisImageLoading,
    IsformatImageLoading,
    formatImage,
    isOpenBonnetLoading,
    openBonnet,
    isRight45ImageLoading,
    right45image,
    frontimage,
    isFrontImageLoading,
    frontUnderCarImage,
    isFrontUnderCarImageLoading,
    isLeft45Loading,
    left45image,
    isLeftImageLoading,
    leftImage,
    rearImage,
    isRearImageLoading,
    rearUnderPartImage,
    isRearUnderPartLoading,
    openDickeyImage,
    isOpenDickeyImageLoading,
    rightImage,
    isRightImageLoading,
    tyreImage,
    isTyreImageLoading,
    glassOutsideImage,
    isGlassOutsideImageLoading,
    glassInsideImage,
    isGlassInsideImageLoading,
    odometerImage,
    isOdometerImageLoading,
    rpmImage,
    isRpmImageLoading,
    anyDamage,
    isAnyDamageLoading,
    extraImage,
    isExtraImageLoading,
    extraImage1,
    isExtraImage1Loading,
    extraImage2,
    isExtraImage2Loading,
    extraImage3,
    isExtraImage3Loading,
    signatureImage,
    isSignatureImageLoading,
    rcFrontImage,
    isRcFrontImageLoading,
    rcBackImage,
    isRcBackImageLoading,
  } = useSelector((store) => store.cases);
  const [assetType, setAssetType] = useState("");
  const [assetTypeError, setAssetTypeError] = useState("");
  const [caseType, setCaseType] = useState("");
  const [caseTypeError, setCaseTypeError] = useState("");
  const [valuationPurpose, setValuationPurpose] = useState(
    "Assesment of fair market value"
  );
  const [valuationPurposeError, setValuationPurposeError] = useState("");
  const [requesterID, setRequesterID] = useState("");
  const [requesterName, setRequesterName] = useState("");
  const [requesterNameError, setRequesterNameError] = useState("");
  const [requesterBranch, setRequesterBranch] = useState("");
  const [requesterBranchError, setRequesterBranchError] = useState("");
  const [requesterPhone, setRequesterPhone] = useState("");
  const [requesterPhoneError, setRequesterPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [Cnfemail, setCnfEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCnf, setMobileCnf] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [productType, setProductType] = useState("");
  const [productTypeError, setProductTypeError] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productBrandError, setProductBrandError] = useState("");
  const [vehicleLocation, setVehicleLocation] = useState("");
  const [vehicleLocationError, setVehicleLocationError] = useState("");
  const [model, setModel] = useState("");
  const [ModelError, setModelError] = useState("");
  const [variant, setVariant] = useState("");
  const [variantError, setVariantError] = useState("");
  const [loanNo, setLoanNo] = useState("");
  const [loanNoError, setLoanNoError] = useState("");
  const [docVerified, setDocVerified] = useState("");
  const [docVerifiedError, setDocVerifiedError] = useState("");
  const [regdOwner, setRegdOwner] = useState("");
  const [regdOwnerError, setRegdOwnerError] = useState("");
  const [regdDate, setRegdDate] = useState("");
  const [regdDateError, setRegdDateError] = useState("");
  const [mfgDate, setMfgdate] = useState("");
  const [mfgDateError, setMfgdateError] = useState("");
  const [numberPlateType, setNumberPlateType] = useState("");
  const [numberPlateTypeError, setNumberPlateTypeError] = useState("");
  const [regdNo, setRegdNo] = useState("");
  const [regdNoError, setRegdNoError] = useState("");
  const [vehicleData, setVehicleData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [engNo, setEngNo] = useState("");
  const [engNoError, setEngNoError] = useState("");
  const [chasisNo, setChasisNo] = useState("");
  const [chasisNoError, setChasisNoError] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [bodyTypeError, setBodyTypeError] = useState("");
  const [meterReading, setMeterReading] = useState("");
  const [meterReadingError, setMeterReadingError] = useState("");
  const [fueltype, setFueltype] = useState("");
  const [fueltypeError, setFueltypeError] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleColorError, setVehicleColorError] = useState("");
  const [ownerSerialNo, setOwnerSerialNo] = useState("");
  const [ownerSerialNoError, setOwnerSerialNoError] = useState("");
  const [hypothecationBank, setHypothecationBank] = useState("");
  const [hypothecationBankError, setHypothecationBankError] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [insuranceCompanyError, setInsuranceCompanyError] = useState("");
  const [insuranceStatus, setInsuranceStatus] = useState("");
  const [insuranceStatusError, setInsuranceStatusError] = useState("");
  const [cubicCapacity, setCubicCapacity] = useState("");
  const [cubicCapacityError, setCubicCapacityError] = useState("");
  const [seatingCapacity, setSeatingCapacity] = useState("");
  const [eatingCapacityError, setSeatingCapacityError] = useState("");
  const [vehicleWeight, setVehicleWeight] = useState("");
  const [vehicleWeightError, setVehicleWeightError] = useState("");

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [videoRecord, setVideoRecord] = useState("");

  const [role, setRole] = useState("");
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [airConditioner, setAirConditioner] = useState("");
  const [airConditionerError, setAirConditionerError] = useState("");
  const [cdCharge, setCdCharge] = useState("");
  const [cdChargeError, setCdChargeError] = useState("");
  const [seatCover, setSeatCover] = useState("");
  const [seatCoverError, setSeatCoverError] = useState("");
  const [fogLamps, setFogLamps] = useState("");
  const [fogLampsError, setFogLampsError] = useState("");
  const [transmission, setTransmission] = useState("");
  const [transmissionError, setTransmissionError] = useState("");
  const [odometer, setOdometer] = useState("");
  const [odometerError, setOdometerError] = useState("");
  const [accidental, setAccidental] = useState("");
  const [accidentalError, setAccidentalError] = useState("");
  const [otherRepair, setotherRepair] = useState("");
  const [otherRepairError, setotherRepairError] = useState("");
  const [fitnessExpiry, setFitnessExpiry] = useState("");
  const [fitnessExpiryError, setFitnessExpiryError] = useState("");
  const [roadtaxValidity, setroadtaxValidity] = useState("");
  const [roadtaxValidityError, setroadtaxValidityError] = useState("");
  const [idvValue, setIdvValue] = useState("");
  const [idvValueError, setIdvValueError] = useState("");
  const [insuranceExpiry, setInsuranceExpiry] = useState("");
  const [insuranceExpiryError, setInsuranceExpiryError] = useState("");
  const [engineCondition, setEngineCondition] = useState("");
  const [engineConditionError, setEngineConditionError] = useState("");
  const [wheelType, setWheelType] = useState("");
  const [wheelTypeError, setWheelTypeError] = useState("");
  const [totalTyre, setTotalTyre] = useState("");
  const [totalTyreError, setTotalTyreError] = useState("");
  const [availableTyre, setAvailableTyre] = useState("");
  const [availableTyreError, setAvailableTyreError] = useState("");
  const [missingTyre, setMissingTyre] = useState("");
  const [missingTyreError, setMissingTyreError] = useState("");
  const [spareTyre, setSpareTyre] = useState("");
  const [spareTyreError, setSpareTyreError] = useState("");
  const [interiorCondition, setInteriorCondition] = useState("");
  const [interiorConditionError, setInteriorConditionError] = useState("");
  const [exteriorCondition, setExteriorCondition] = useState("");
  const [exteriorConditionError, setExteriorConditionError] = useState("");
  const [headLamp, setHeadLamp] = useState("");
  const [headLampError, setHeadLampError] = useState("");
  const [tailLamp, setTailLamp] = useState("");
  const [tailLampError, setTailLampError] = useState("");
  const [frontIndicators, setFrontIndicators] = useState("");
  const [frontIndicatorsError, setFrontIndicatorsError] = useState("");
  const [rearIndicators, setRearIndicators] = useState("");
  const [rearIndicatorsError, setRearIndicatorsError] = useState("");
  const [maintenanceLevel, setMaintenanceLevel] = useState("");
  const [maintenanceLevelError, setMaintenanceLevelError] = useState("");
  const [frontBreakCondition, setFrontBreakCondition] = useState("");
  const [frontBreakConditionError, setFrontBreakConditionError] = useState("");
  const [rearBreakCondition, setRearBreakCondition] = useState("");
  const [rearBreakConditionError, setRearBreakConditionError] = useState("");
  const [engineThere, setEngineThere] = useState("");
  const [engineThereError, setEngineThereError] = useState("");
  const [fluidLeaks, setFluidLeaks] = useState("");
  const [fluidLeaksError, setFluidLeaksError] = useState("");
  const [engineShield, setEngineShield] = useState("");
  const [engineShieldError, setEngineShieldError] = useState("");
  const [transmissionCondition, setTransmissionCondition] = useState("");
  const [transmissionConditionError, setTransmissionConditionError] =
    useState("");
  const [suspension, setSuspension] = useState("");
  const [suspensionError, setSuspensionError] = useState("");
  const [handBrake, setHandBrake] = useState("");
  const [handBrakeError, setHandBrakeError] = useState("");
  const [carTyreType, setCarTyreType] = useState("");
  const [carTyreTypeError, setCarTyreTypeError] = useState("");
  const [carLhFront, setCarLhFront] = useState("");
  const [carLhFrontError, setCarLhFrontError] = useState("");
  const [carRhFront, setCarRhFront] = useState("");
  const [carRhFrontError, setCarRhFrontError] = useState("");
  const [carLhRear, setCarLhRear] = useState("");
  const [carLhRearError, setCarLhRearError] = useState("");
  const [carRhRear, setCarRhRear] = useState("");
  const [carRhRearError, setCarRhRearError] = useState("");
  const [carSpareTyre, setCarSpareTyre] = useState("");
  const [carSpareTyreError, setCarSpareTyreError] = useState("");
  const [carSteering, setCarSteering] = useState("");
  const [carSteeringError, setCarSteeringError] = useState("");
  const [carSteeringCondition, setCarSteeringCondition] = useState("");
  const [carSteeringConditionError, setCarSteeringConditionError] = useState("");
  const [carDashboard, setCarDashboard] = useState("");
  const [carDashboardError, setCarDashboardError] = useState("");
  const [carSeat, setCarSeat] = useState("");
  const [carSeatError, setCarSeatError] = useState("");
  const [carInterior, setCarInterior] = useState("");
  const [carInteriorError, setCarInteriorError] = useState("");
  const [carAirbag, setCarAirbag] = useState("");
  const [carAirbagError, setCarAirbagError] = useState("");
  const [carAntiLock, setCarAntiLock] = useState("");
  const [carAntiLockError, setCarAntiLockError] = useState("");
  const [carPowerWindowFront, setCarPowerWindowFront] = useState("");
  const [carPowerWindowFrontError, setCarPowerWindowFrontError] = useState("");
  const [carPowerWindowRear, setCarPowerWindowRear] = useState("");
  const [carPowerWindowRearError, setCarPowerWindowRearError] = useState("");
  const [carSeatBelt, setCarSeatBelt] = useState("");
  const [carSeatBeltError, setCarSeatBeltError] = useState("");
  const [carInsideRearViewMirror, setCarInsideRearViewMirror] = useState("");
  const [carInsideRearViewMirrorError, setCarInsideRearViewMirrorError] = useState("");
  const [carBonnet, setCarBonnet] = useState("");
  const [carBonnetError, setCarBonnetError] = useState("");
  const [carFrontBumper, setCarFrontBumper] = useState("");
  const [carFrontBumperError, setCarFrontBumperError] = useState("");
  const [carRearBumper, setCarRearBumper] = useState("");
  const [carRearBumperError, setCarRearBumperError] = useState("");
  const [carWindScreen, setCarWindScreen] = useState("");
  const [carWindScreenError, setCarWindScreenError] = useState("");
  const [carWindScreenGlass, setCarWindScreenGlass] = useState("");
  const [carWindScreenGlassError, setCarWindScreenGlassError] = useState("");
  const [carRearWindScreenGlass, setCarRearWindScreenGlass] = useState("");
  const [carRearWindScreenGlassError, setCarRearWindScreenGlassError] = useState("");
  const [carRhDoorGlass, setCarRhDoorGlass] = useState("");
  const [carRhDoorGlassError, setCarRhDoorGlassError] = useState("");
  const [carRhSideBody, setCarRhSideBody] = useState("");
  const [carRhSideBodyError, setCarRhSideBodyError] = useState("");
  const [carLhSideBody, setCarLhSideBody] = useState("");
  const [carLhSideBodyError, setCarLhSideBodyError] = useState("");
  const [carLhDoorGlass, setCarLhDoorGlass] = useState("");
  const [carLhDoorGlassError, setCarLhDoorGlassError] = useState("");
  const [carOutsideRearView, setcarOutsideRearView] = useState("");
  const [carOutsideRearViewError, setcarOutsideRearViewError] = useState("");
  const [carPaint, setcarPaint] = useState("");
  const [carPaintError, setcarPaintError] = useState("");
  const [carPaintCondition, setcarPaintCondition] = useState("");
  const [carPaintConditionError, setcarPaintConditionError] = useState("");
  const [carOverAllBodyCondition, setcarOverAllBodyCondition] = useState("");
  const [carOverAllBodyConditionError, setcarOverAllBodyConditionError] = useState("");
  const [carBattery, setcarBattery] = useState("");
  const [carBatteryError, setcarBatteryError] = useState("");
  const [carInstrumentCluster, setCarInstrumentCluster] = useState("");
  const [carInstrumentClusterError, setCarInstrumentClusterError] = useState("");
  const [carFogLamp, setCarFogLamp] = useState("");
  const [carFogLampError, setCarFogLampError] = useState("");
  const [carFogLampCondition, setCarFogLampCondition] = useState("");
  const [carFogLampConditionError, setCarFogLampConditionError] = useState("");
  const [carSunroof, setCarSunroof] = useState("");
  const [carSunroofError, setCarSunroofError] = useState("");
  const [carMusicSyetem, setCarMusicSyetem] = useState("");
  const [carMusicSyetemError, setCarMusicSyetemError] = useState("");
  const [carRoofRails, setCarRoofRails] = useState("");
  const [carRoofRailsError, setCarRoofRailsError] = useState("");
  const [carAntenna, setCarAntenna] = useState("");
  const [carAntennaError, setCarAntennaError] = useState("");
  const [carReverseCamera, setCarReverseCamera] = useState("");
  const [carReverseCameraError, setCarReverseCameraError] = useState("");
  const [yourRemark, setYourRemark] = useState("");
  const [yourRemarkError, setYourRemarkError] = useState("");
  const [exShowRoomPrice, setExShowRoomPrice] = useState("");
  const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [estimatedPriceError, setEstimatedPriceError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false)
  const [clientList, setClientList] = useState([])
  useEffect(() => {
    const client = totalClients.map((indClient, index) => ({
      id: index,
      _id: indClient._id,
      name: indClient.name,
      phone: indClient.phone,
      organisation: indClient.organisation
    }));
    setClientList(client);
  }, [totalClients])
  const handleRequester = (e) => {
    const { organisation, phone, _id } = e.target.value
    setRequesterID(_id)
    setRequesterPhone(phone)
  }
  console.log(requesterID, 'requesterID');

  const selectCaseType = [
    {
      id: 1,
      name: "Revaluation",
    },
    {
      id: 2,
      name: "Repossession",
    },
    {
      id: 3,
      name: "Retail",
    },
    {
      id: 4,
      name: "Insurance Idv",
    },
    {
      id: 5,
      name: "Repurchase",
    },
  ];
  const selectCaseChange = (e) => {
    const { value } = e.target;
    setCaseType(value);
  };
  const selectAssetType = [
    {
      id: 1,
      name: "Two Wheeler",
    },
    {
      id: 2,
      name: "Four Wheeler",
    },
    {
      id: 3,
      name: "Commercial",
    },
    {
      id: 4,
      name: "Construction Equipment",
    },
    {
      id: 5,
      name: "Three Wheeler",
    },
    {
      id: 6,
      name: "Farm Equipment",
    },
  ];
  const selectAssetChange = (e) => {
    const { value } = e.target;

    setAssetType(value);
  };

  const handleRequesterPhn = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setRequesterPhone(value);
  };

  const stateList = [
    { id: 1, name: "Andhra Pradesh" },
    { id: 2, name: "Arunachal Pradesh" },
    { id: 3, name: "Assam" },
    { id: 4, name: "Bihar" },
    { id: 5, name: "Chhattisgarh" },
    { id: 6, name: "Goa" },
    { id: 7, name: "Gujarat" },
    { id: 8, name: "Haryana" },
    { id: 9, name: "Himachal Pradesh" },
    { id: 10, name: "Jharkhand" },
    { id: 11, name: "Karnataka" },
    { id: 12, name: "Kerala" },
    { id: 13, name: "Madhya Pradesh" },
    { id: 14, name: "Maharashtra" },
    { id: 15, name: "Manipur" },
    { id: 16, name: "Meghalaya" },
    { id: 17, name: "Mizoram" },
    { id: 18, name: "Nagaland" },
    { id: 19, name: "Odisha" },
    { id: 20, name: "Punjab" },
    { id: 21, name: "Rajasthan" },
    { id: 22, name: "Sikkim" },
    { id: 23, name: "Tamil Nadu" },
    { id: 24, name: "Telangana" },
    { id: 25, name: "Tripura" },
    { id: 26, name: "Uttar Pradesh" },
    { id: 27, name: "Uttarakhand" },
    { id: 28, name: "West Bengal" },
    { id: 29, name: "Andaman and Nicobar Islands" },
    { id: 30, name: "Chandigarh" },
    { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
    { id: 32, name: "Delhi" },
    { id: 33, name: "Ladakh" },
    { id: 34, name: "Lakshadweep" },
    { id: 35, name: "Puducherry" },
  ];
  const selectStateChange = (e) => {
    const { value } = e.target;

    setState(value);
  };
  useEffect(() => {
    if (state) {
      const formData = {
        country: "India",
        state: state.toLocaleUpperCase(),
      };
      const getCity = async () => {
        try {
          const config = {
            headers: { "Content-Type": "application/json" },
          };
          const url =
            "https://countriesnow.space/api/v0.1/countries/state/cities";
          const resp = await axios.post(url, formData, config);
          if (!resp.data.error) {
            const citiesData = resp.data.data.map((city, index) => ({
              id: index + 1,
              name: city.toLowerCase().replace(/\s/g, "_"),
            }));
            setCityList(citiesData);
          } else {
            console.error("Error in response:", resp.data.error); // Log the error for debugging
          }
        } catch (error) {
          console.error("Error:", error); // Log any other errors for debugging
        }
      };

      getCity();
    }
  }, [state]);

  const selectCityChange = (e) => {
    const { value } = e.target;

    setCity(value);
  };

  const handleRegdNo = async (e) => {
    const inputValue = e.target.value.toUpperCase();
    setRegdNo(inputValue);
    setDataFetched(false); // Reset data fetched status

    if (inputValue.length === 9 || inputValue.length === 10) {
      try {
        // let newData = await fetchVehicleData(inputValue);
        const formData = {
          regdNo: inputValue,
        };
        const vehicle = await dispatch(sendVehicleNumber(formData));
        setVehicleData(vehicle.payload.response.result);
        setDataFetched(true);
      } catch (error) {
        setVehicleData(null); // Reset vehicle data
      }
    } else {
      setVehicleData(null); // Reset vehicle data if input length is invalid
    }
  };
  useEffect(() => {
    // Fetch data again if required properties are null after initial fetch
    if (dataFetched && vehicleData) {
      const {
        rcFinancer,
        vehicleTaxUpto,
        permitValidUpto,
        permitType,
        vehicleSeatCapacity,
        grossVehicleWeight,
        regAuthority,
      } = vehicleData;

      if (
        !rcFinancer ||
        !vehicleTaxUpto ||
        !permitValidUpto ||
        !permitType ||
        !vehicleSeatCapacity ||
        !grossVehicleWeight ||
        !regAuthority
      ) {
        // Required properties are still null, fetch again
        handleRegdNo({ target: { value: regdNo } });
      }
    }
  }, [dataFetched, vehicleData, regdNo]);

  const documents = [
    { name: "Verified", id: 1 },
    { name: "Not Verified", id: 2 },
  ];
  const selectDocuementVerifiedTypeChange = (e) => {
    const { value } = e.target;
    // const index = e.nativeEvent.target.selectedIndex;
    // const text = e.nativeEvent.target[index].text;
    setDocVerified(value);
  };
  const number = [
    { name: "White", id: 1 },
    { name: "Yellow", id: 2 },
    { name: "Blue", id: 3 },
    { name: "Black", id: 4 },
  ];
  const selectNumberPlateTypeChange = (e) => {
    const { value } = e.target;
    // const index = e.nativeEvent.target.selectedIndex;
    // const text = e.nativeEvent.target[index].text;
    setNumberPlateType(value);
  };
  const options = [
    { name: "Yes", id: 1 },
    { name: "No", id: 2 },
  ];
  const Features = [
    { name: "Digital", id: 1 },
    { name: "Normal", id: 2 },
  ];
  const Transmission = [
    { name: "Manual", id: 1 },
    { name: "Automatic", id: 2 }
  ];
  const condition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
    { name: "NA", id: 6 },
  ];
  const reqCondition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
  ];
  const list = [
    { name: "10", id: 1 },
    { name: "20", id: 2 },
    { name: "30", id: 3 },
    { name: "40", id: 4 },
    { name: "50", id: 5 },
    { name: "60", id: 6 },
    { name: "70", id: 7 },
    { name: "80", id: 8 },
    { name: "90", id: 9 },
    { name: "100", id: 10 },
  ];
  const qualityList = [
    { name: "Original", id: 1 },
    { name: "Replaced", id: 2 },
  ];
  const wheelTypeList = [
    { name: "Alloy", id: 1 },
    { name: "Steel", value: 2 },
    { name: "Rim", value: 3 },
  ];

  const selectInsuranceStatusChange = (e) => {
    const { value } = e.target;
    setInsuranceStatus(value);
  };

  const handleFileChange = (event) => {
    setSelectedVideo(event.target.files[0]);
  };

  const handleUpload = async () => {
    setShowLoader(true);
    if (selectedVideo) {
      const formData = new FormData();
      formData.append("file", selectedVideo);
      try {
        const res = await axios.post(`${Baseurl}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setVideoRecord(res.data.filePath);
        document.getElementById("videouploading").value = "";
        setShowLoader(false);
        alert('file uploaded')
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.log("No video selected.");
    }
  };

  const handleSelfieWithVehicleChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    await dispatch(selfieWithVehicleUpload(formData));
  };
  const handleChasisChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    dispatch(UploadChasisImage(formData));
  };
  const handleFormatChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    dispatch(uploadformatImage(formData));
  };
  const handleOpenBonnetChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(UploadOpenBonnet(formData));
  };
  const handleright45Change = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    dispatch(UploadRight45Image(formData));
  };
  const frontImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(UploadFrontImage(formData));
  };
  const frontUnderCarImg = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadFrontUnderCar(formData));
  };
  const handlesetLeft45Image = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadLeft45(formData));
  };
  const handlesetLeftImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadLeftImage(formData));
  };
  const handlesetRearImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRearImage(formData));
  };
  const handlerearUnderPartImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(UploadRearUnderPart(formData));
  };
  const handlerearOpenDickeyImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadOpenDickey(formData));
  };
  const HandlerightImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRightImage(formData));
  };
  const handlesetTyreImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadTyreImage(formData));
  };
  const handleGlassOutsideImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadGlassOutside(formData));
  };
  const handleGlassInsideImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadGlassInsideImage(formData));
  };
  const handleOdometerImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadOdometerImage(formData));
  };
  const handleRpmImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRpmImage(formData));
  };
  const handleAnyDamage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadAnyDamage(formData));
  };
  const handleExtraImage = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadExtraImage(formData));
  };
  const handleExtraImage1 = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadExtraImage1(formData));
  };
  const handleExtraImage2 = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadExtraImage2(formData));
  };
  const handleExtraImage3 = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadExtraImage3(formData));
  };
  const handleSignature = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadSignature(formData));
  };
  const handleFrontRc = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRcFront(formData));
  };
  const handleBackRc = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
    dispatch(uploadRcBackImage(formData));
  };

  const selectAirConditionerChange = (e) => {
    const { value } = e.target;

    setAirConditioner(value);
  };
  const selectCdChargeChange = (e) => {
    const { value } = e.target;

    setCdCharge(value);
  };
  const selectSeatCoverChange = (e) => {
    const { value } = e.target;

    setSeatCover(value);
  };
  const selectFogLampsChange = (e) => {
    const { value } = e.target;

    setFogLamps(value);
  };
  const selectTransmissionChange = (e) => {
    const { value } = e.target;

    setTransmission(value);
  };
  const selectOdometerChange = (e) => {
    const { value } = e.target;

    setOdometer(value);
  };
  const selectAccidentalChange = (e) => {
    const { value } = e.target;

    setAccidental(value);
  };
  const selectotherRepairlChange = (e) => {
    const { value } = e.target;

    setotherRepair(value);
  };
  const selectEngineConditionChange = (e) => {
    const { value } = e.target;

    setEngineCondition(value);
  };
  const selectWheelTypeChange = (e) => {
    const { value } = e.target;

    setWheelType(value);
  };
  const selectInteriorConditionChange = (e) => {
    const { value } = e.target;

    setInteriorCondition(value);
  };
  const selectExteriorConditionChange = (e) => {
    const { value } = e.target;

    setExteriorCondition(value);
  };
  const selectHeadLampChange = (e) => {
    const { value } = e.target;

    setHeadLamp(value);
  };
  const selectTailLampChange = (e) => {
    const { value } = e.target;

    setTailLamp(value);
  };
  const selectFrontIndicatorsChange = (e) => {
    const { value } = e.target;

    setFrontIndicators(value);
  };
  const selectRearIndicatorsChange = (e) => {
    const { value } = e.target;

    setRearIndicators(value);
  };
  const selectMaintenanceLevelChange = (e) => {
    const { value } = e.target;

    setMaintenanceLevel(value);
  };
  const selectFrontBreakConditionChange = (e) => {
    const { value } = e.target;

    setFrontBreakCondition(value);
  };
  const selectRearBreakConditionChange = (e) => {
    const { value } = e.target;

    setRearBreakCondition(value);
  };
  const selectEngineThereChange = (e) => {
    const { value } = e.target;

    setEngineThere(value);
  };
  const selectFluidLeaksChange = (e) => {
    const { value } = e.target;

    setFluidLeaks(value);
  };
  const selectEngineShieldChange = (e) => {
    const { value } = e.target;

    setEngineShield(value);
  };
  const selectTransmissionConditionChange = (e) => {
    const { value } = e.target;

    setTransmissionCondition(value);
  };
  const selectSuspensionChange = (e) => {
    const { value } = e.target;

    setSuspension(value);
  };
  const selectHandBrakeChange = (e) => {
    const { value } = e.target;

    setHandBrake(value);
  };
  const selectCarLhFrontChange = (e) => {
    const { value } = e.target;

    setCarLhFront(value);
  };
  const selectCarRhFrontChange = (e) => {
    const { value } = e.target;

    setCarRhFront(value);
  };
  const selectCarLhRearChange = (e) => {
    const { value } = e.target;
    setCarLhRear(value);
  };
  const selectCarRhRearChange = (e) => {
    const { value } = e.target;

    setCarRhRear(value);
  };
  const selectCarSpareTyreChange = (e) => {
    const { value } = e.target;

    setCarSpareTyre(value);
  };
  const selectCarSteeringConditionChange = (e) => {
    const { value } = e.target;

    setCarSteeringCondition(value);
  };
  const selectCarDashboardChange = (e) => {
    const { value } = e.target;

    setCarDashboard(value);
  };
  const selectCarSeatChange = (e) => {
    const { value } = e.target;

    setCarSeat(value);
  };
  const selectCarInteriorChange = (e) => {
    const { value } = e.target;

    setCarInterior(value);
  };
  const selectCarAirbagChange = (e) => {
    const { value } = e.target;

    setCarAirbag(value);
  };
  const selectCarAntiLockChange = (e) => {
    const { value } = e.target;

    setCarAntiLock(value);
  };
  const selectCarPowerWindowFront = (e) => {
    const { value } = e.target;

    setCarPowerWindowFront(value);
  };
  const selectCarPowerWindowRear = (e) => {
    const { value } = e.target;

    setCarPowerWindowRear(value);
  };
  const selectCarSeatBeltChange = (e) => {
    const { value } = e.target;

    setCarSeatBelt(value);
  };
  const selectCarInsideRearViewMirrorChange = (e) => {
    const { value } = e.target;

    setCarInsideRearViewMirror(value);
  };
  const selectCarBonnetChange = (e) => {
    const { value } = e.target;

    setCarBonnet(value);
  };
  const selectCarFrontBumperChange = (e) => {
    const { value } = e.target;

    setCarFrontBumper(value);
  };
  const selectCarRearBumperChange = (e) => {
    const { value } = e.target;
    setCarRearBumper(value);
  };
  const selectCarWindScreenChange = (e) => {
    const { value } = e.target;
    setCarWindScreen(value);
  };
  const selectCarWindScreenGlassChange = (e) => {
    const { value } = e.target;
    setCarWindScreenGlass(value);
  };
  const selectCarRearWindScreenGlassChange = (e) => {
    const { value } = e.target;

    setCarRearWindScreenGlass(value);
  };
  const selectCarRhDoorGlassChange = (e) => {
    const { value } = e.target;

    setCarRhDoorGlass(value);
  };
  const selectCarRhSideBodyChange = (e) => {
    const { value } = e.target;

    setCarRhSideBody(value);
  };
  const selectCarLhSideBodyChange = (e) => {
    const { value } = e.target;

    setCarLhSideBody(value);
  };
  const selectCarLhDoorGlassChange = (e) => {
    const { value } = e.target;
    setCarLhDoorGlass(value);
  };
  const selectcarOutsideRearViewChange = (e) => {
    const { value } = e.target;
    setcarOutsideRearView(value);
  };
  const selectcarPaintChange = (e) => {
    const { value } = e.target;
    setcarPaint(value);
  };
  const selectcarPaintConditionChange = (e) => {
    const { value } = e.target;
    setcarPaintCondition(value);
  };
  const selectcarOverAllBodyConditionChange = (e) => {
    const { value } = e.target;
    setcarOverAllBodyCondition(value);
  };
  const selectcarBatteryChange = (e) => {
    const { value } = e.target;
    setcarBattery(value);
  };
  const selectCarInstrumentClusterChange = (e) => {
    const { value } = e.target;
    setCarInstrumentCluster(value);
  };
  const selectCarFogLampChange = (e) => {
    const { value } = e.target;
    setCarFogLamp(value);
  };
  const selectCarFogLampConditionChange = (e) => {
    const { value } = e.target;
    setCarFogLampCondition(value);
  };
  const selectCarSunroofChange = (e) => {
    const { value } = e.target;
    setCarSunroof(value);
  };
  const selectCarMusicSyetemChange = (e) => {
    const { value } = e.target;
    setCarMusicSyetem(value);
  };
  const selectCarRoofRailsChange = (e) => {
    const { value } = e.target;
    setCarRoofRails(value);
  };
  const selectCarAntennaChange = (e) => {
    const { value } = e.target;
    setCarAntenna(value);
  };
  const selectCarReverseCameraChange = (e) => {
    const { value } = e.target;
    setCarReverseCamera(value);
  };
  const selectYourRemarkChange = (e) => {
    const { value } = e.target;
    setYourRemark(value);
  };

  const [btnPress, setbtnPress] = useState(false);

  const setNumberChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileError("");
    setMobile(value);
    if (value.length === 10) {
      // const verifyNumber = await dispatch(valiadteNumber(value));
      // if (verifyNumber.payload.success) {
      //     setMobileError("Number already exist");
      // } else {
      // }
      setMobileError("");
      setMobileCnf(value);
    } else {
      setMobileError("Please Enter Valid Number");
    }
  };

  const emailChange = async (e) => {
    const value = e;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setEmailError("");
    if (value.length > 0) {
      if (value.match(mailformat)) {
        // const verifyEmail = await dispatch(valiadteEmail(value));
        // if (verifyEmail.payload.success) {
        //     setEmailError("Number already exist");
        // } else {
        // }
        setEmailError("");
        setCnfEmail(value);
      } else {
        setEmailError("please enter correct Email format");
      }
    } else {
      setEmailError("please enter correct Email format");
    }
  };

  const cancelClick = async (e) => {
    e.preventDefault();
    setEmail("");
    setCnfEmail("");
    setMobile("");
    setMobileCnf("");
    setName("");
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    // Estimated Price
    setButtonLoading(true)
    if (name !== "" && Cnfemail !== "" && mobileCnf !== "") {
      const formData = {
        assetType: assetType,
        caseType: caseType,
        valuationPurpose: valuationPurpose,
        vehicleLocation: vehicleLocation,
        requesterID: requesterID,
        requesterName: requesterName,
        requesterBranch: requesterBranch,
        requesterPhone: requesterPhone,
        name: name,
        mobile: mobileCnf,
        email: Cnfemail,
        address: address,
        state: state,
        city: city,
        regdNo: regdNo,
        productType: productType,
        brand: productBrand,
        model: model,
        variant: variant,
        loanAgreementNo: loanNo,
        documentVerification: docVerified,
        regdOwner: regdOwner,
        regdDate: regdDate,
        numberPlate: numberPlateType,
        engNo: engNo,
        chasisNo: chasisNo,
        bodyType: bodyType,
        manufacturingDate: mfgDate,
        fueltype: fueltype,
        vehicleColor: vehicleColor,
        ownerSerialNo: ownerSerialNo,
        hypothecationBank: hypothecationBank,
        cubicCapacity: cubicCapacity,
        seatingCapacity: seatingCapacity,
        vehicleWeight: vehicleWeight,
        videos: videoRecord,
        images: {
          selfieWithVehicle: selfieWithVehicleImage,
          chasisImage: chasisImage,
          format: formatImage,
          openBonnet: openBonnet,
          right45: right45image,
          frontImage: frontimage,
          frontUnderCar: frontUnderCarImage,
          left45: left45image,
          leftImage: leftImage,
          rearImage: rearImage,
          rearUnderPart: rearUnderPartImage,
          openDickey: openDickeyImage,
          rightImage: rightImage,
          tyreImage: tyreImage,
          glassOutside: glassOutsideImage,
          glassInside: glassInsideImage,
          odometerImage: odometerImage,
          rpmImage: rpmImage,
          anyDamage: anyDamage,
          extraImage: extraImage,
          extraImage1: extraImage1,
          extraImage2: extraImage2,
          extraImage3: extraImage3,
        },
        signNdoc: {
          sign: signatureImage,
          rcFront: rcFrontImage,
          rcBack: rcBackImage,
        },
        accessories: {
          ac: airConditioner,
          cdCharger: cdCharge,
          seatCover: seatCover,
          fogLamps: fogLamps,
        },
        additionalFeatures: {
          transmission: transmission,
          odometer: odometer,
        },
        otherDetails: {
          accidental: accidental,
          otherRepair: otherRepair,
          fitnessExpiry: fitnessExpiry,
          roadTaxValidity: roadtaxValidity,
          IDVValue: idvValue,
          insuranceExpiry: insuranceExpiry,
          engineCondition: engineCondition,
          frontBrakeCondition: frontBreakCondition,
          rearBrakeCondition: rearBreakCondition,
          wheelsType: wheelType,
          totalTyre: Number(totalTyre),
          availableTyre: Number(availableTyre),
          missingTyre: Number(missingTyre),
          spareTyre: Number(spareTyre),
          interiorCondition: interiorCondition,
          exteriorCondition: exteriorCondition,
          headLamp: headLamp,
          tailLamp: tailLamp,
          frontIndicators: frontIndicators,
          rearIndicators: rearIndicators,
          levelOfMaintenance: maintenanceLevel,
        },
        privateCar: {
          engine: {
            engine: engineThere,
            fluidLeak: fluidLeaks,
            engineShield: engineShield,
          },
          transmission: {
            transmissionCondition: transmissionCondition,
          },
          suspension: {
            suspensionCondition: suspension,
          },
          brakes: {
            handBrake: handBrake,
          },
          TyresNConditions: {
            type: carTyreType,
            lhFront: carLhFront,
            rhfront: carRhFront,
            lhRear: carLhRear,
            rhRear: carRhRear,
            spareTyre: carSpareTyre,
          },
          vehicleInterior: {
            steering: carSteering,
            steeringCondition: carSteeringCondition,
            dashboard: carDashboard,
            seats: carSeat,
            interiorsTrims: carInterior,
            airBag: carAirbag,
            antilockBrakeSystem: carAntiLock,
            powerWindowRear: carPowerWindowRear,
            powerWindowFront: carPowerWindowFront,
            seatBelts: carSeatBelt,
            insideRearViewMirror: carInsideRearViewMirror,
          },
          vehicleExterior: {
            bonnet: carBonnet,
            frontBumper: carFrontBumper,
            rearBumper: carRearBumper,
            windScreen: carWindScreen,
            windscreenGlass: carWindScreenGlass,
            rearWindScreen: carRearWindScreenGlass,
            rhSideBody: carRhSideBody,
            lhSideBody: carLhSideBody,
            lhDoorGlasses: carLhDoorGlass,
            outsideRearViewMirror: carOutsideRearView,
            paint: carPaint,
            paintCondition: carPaintCondition,
            overallBodyCondition: carOverAllBodyCondition,
          },
          electricals: {
            battery: carBattery,
            instrumentClustor: carInstrumentCluster,
            fogLamps: carFogLamp,
            fogLampCondition: carFogLampCondition,
          },
          accessories: {
            sunroof: carSunroof,
            musicStytem: carMusicSyetem,
            roofRails: carRoofRails,
            antenna: carAntenna,
            reverseCamera: carReverseCamera,
          },
        },
        yourRemark: yourRemark,
        valuation: Number(estimatedPrice),
        marketPrice: Number(exShowRoomPrice),
        status: 4,
        statusText: "Case Submitted",
      };
      console.log(formData, "formData");
      const newcase = await dispatch(createCase(formData))
      if (newcase.payload.success) {
        alert("Case created successfully");
        dispatch(CaseActions.afterPost())
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setRole("")
        setAddress("")
        setState("")
        setCity("")
        setProductType("")
        setProductBrand("")
        setModel("")
        setVariant("")
        setLoanNo("")
        setDocVerified("")
        setRegdOwner("")
        setRegdDate("")
        setMfgdate("")
        setNumberPlateType("")
        setRegdNo("")
        setEngNo("")
        setChasisNo("")
        setBodyType("")
        setFueltype("")
        setVehicleColor("")
        setOwnerSerialNo("")
        setHypothecationBank("")
        setCubicCapacity("")
        setSeatingCapacity("")
        setVehicleWeight("")
        setSeatCover("")
        setFogLamps("")
        setTransmission("")
        setAccidental("")
        setFitnessExpiry("")
        setInsuranceExpiry("")
        setButtonLoading(false)
        navigate("/admin/submittedcases")
      }
    } else {
      setbtnPress(true);
      setButtonLoading(false)
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff" }}
              >
                Add Case Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Case Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-select-label">
                      Case Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={caseType}
                      label="Case Type"
                      onChange={(e) => selectCaseChange(e)}
                    >
                      {selectCaseType.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={
                                            caseTypeError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="choices-single-default"
                                        placeholder="This is a search placeholder"
                                        value={selectCaseType.id}
                                        name={selectCaseType.name}
                                        onChange={(e) => selectCaseChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Case Type
                                        </option>
                                        {selectCaseType.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fAssetType"
                    className="text-sm font-medium text-default-900"
                  >
                    Asset Type *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Asset-Type">
                      Asset Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Asset-Type"
                      id="demo-simple-select"
                      value={assetType}
                      label="Case Type"
                      onChange={(e) => selectAssetChange(e)}
                    >
                      {selectAssetType.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={
                                            assetTypeError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="fAssetType"
                                        placeholder="This is a search placeholder"
                                        value={selectAssetType.id}
                                        name={selectAssetType.name}
                                        onChange={(e) => selectAssetChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Asset Type
                                        </option>
                                        {selectAssetType.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fValuationPurpose"
                    className="text-sm font-medium text-default-900"
                  >
                    Purpose Of Valuation *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            valuationPurposeError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fValuationPurpose"
                                        placeholder="Enter Valuation Purpose"
                                        value={valuationPurpose}
                                        onChange={(e) => setValuationPurpose(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Purpose Of Valuation"
                      variant="outlined"
                      className={
                        valuationPurposeError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={valuationPurpose}
                      onChange={(e) => setValuationPurpose(e.target.value)}
                    // className={classes.textfield}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Valuation Requested By *
                  </label>
                  <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                    <InputLabel id="demo-simple-requesterName" className="inputcenter">Valuation Requested By</InputLabel>
                    <Select
                      labelId="demo-simple-requesterName"
                      id="demo-simple-select"
                      value={requesterName}
                      label="requesterName"
                      onChange={(e) => { handleRequester(e); setRequesterName(e.target.value.name) }}
                      style={{ height: '38px' }}
                      inputProps={{ style: { textAlign: 'center' } }}
                    >
                      {clientList.map((test, index) =>
                        <MenuItem value={test} key={index}>{test.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Name"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterName}
                      onChange={(e) => setRequesterName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Branch Name *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Branch Name"
                      variant="outlined"
                      className={
                        requesterBranchError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterBranch}
                      onChange={(e) => setRequesterBranch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Phone *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            requesterPhoneError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="lName"
                                        placeholder="Enter Requester Phone"
                                        minLength={10}
                                        maxLength={10}
                                        value={requesterPhone}
                                        onChange={(e) => handleRequesterPhn(e)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Requester Phone"
                      variant="outlined"
                      className={
                        requesterPhoneError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={requesterPhone}
                      onChange={(e) => handleRequesterPhn(e)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Name *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            nameError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fName"
                                        placeholder="Enter Customer Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value.trim())}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Name"
                      variant="outlined"
                      className={
                        nameError !== "" ? "textfield error_class" : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={name}
                      onChange={(e) => setName(e.target.value.trim())}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Phone *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            mobileError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="lName"
                                        placeholder="Enter Customer Phone"
                                        minLength={10}
                                        maxLength={10}
                                        value={mobile}
                                        onChange={(e) => setNumberChange(e)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Phone"
                      variant="outlined"
                      className={
                        mobileError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={mobile}
                      onChange={(e) => setNumberChange(e)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Email *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            emailError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="rollNo"
                                        placeholder="Enter Customer Email"
                                        value={email}
                                        onChange={(e) => emailChange(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Email"
                      variant="outlined"
                      className={
                        emailError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={email}
                      onChange={(e) => emailChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="Address"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Address *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            addressError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="Address"
                                        placeholder="Enter Customer Address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Customer Address"
                      variant="outlined"
                      className={
                        addressError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fState"
                    className="text-sm font-medium text-default-900"
                  >
                    Select State *
                  </label>
                  {/* <select
                                        className={
                                            stateError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="fState"
                                        placeholder="This is a search placeholder"
                                        value={stateList.id}
                                        name={stateList.name}
                                        onChange={(e) => selectStateChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose State Type
                                        </option>
                                        {stateList.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-select-state">
                      select state
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-state"
                      id="demo-simple-select"
                      value={state}
                      label="Select State"
                      onChange={(e) => selectStateChange(e)}
                    >
                      {stateList.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="City"
                    className="text-sm font-medium text-default-900"
                  >
                    Select City *
                  </label>
                  {/* <select
                                        className={
                                            cityError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="fCity"
                                        placeholder="This is a search placeholder"
                                        value={cityList.id}
                                        name={cityList.name}
                                        onChange={(e) => selectCityChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose City Type
                                        </option>
                                        {cityList.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-select-state">
                      select city
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-city"
                      id="demo-simple-select"
                      value={city}
                      label="Select city"
                      onChange={(e) => selectCityChange(e)}
                    >
                      {cityList.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="regdNo"
                    className="text-sm font-medium text-default-900"
                  >
                    RegdNo *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            regdNoError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="regdNo"
                                        placeholder="Enter Regd No"
                                        value={regdNo}
                                        onChange={(e) => setRegdNo(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Regd No"
                      variant="outlined"
                      className={
                        regdNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={regdNo}
                      onChange={(e) => setRegdNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehcileLocation"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Location *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            vehicleLocationError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="vehcileLocation"
                                        placeholder="Enter Vehcile Location"
                                        value={vehicleLocation}
                                        onChange={(e) => setVehicleLocation(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Location"
                      variant="outlined"
                      className={
                        vehicleLocationError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={vehicleLocation}
                      onChange={(e) => setVehicleLocation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="productType"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle brand *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            productBrandError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="productType"
                                        placeholder="Enter Vehicle Brand"
                                        value={productBrand}
                                        onChange={(e) => setProductBrand(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle brand"
                      variant="outlined"
                      className={
                        productBrandError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={productBrand}
                      onChange={(e) => setProductBrand(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="model"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle model *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            ModelError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="model"
                                        placeholder="Enter Vehicle Model"
                                        value={model}
                                        onChange={(e) => setModel(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Model"
                      variant="outlined"
                      className={
                        ModelError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="variant"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle variant *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            variantError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="variant"
                                        placeholder="Enter Vehicle Variant"
                                        value={variant}
                                        onChange={(e) => setVariant(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle variant"
                      variant="outlined"
                      className={
                        variantError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={variant}
                      onChange={(e) => setVariant(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="loanNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Loan Agreement No *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            loanNoError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="loanNo"
                                        placeholder="Enter Loan Agreement No"
                                        value={loanNo}
                                        onChange={(e) => setLoanNo(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Loan Agreement No"
                      variant="outlined"
                      className={
                        loanNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={loanNo}
                      onChange={(e) => setLoanNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fDocVerified"
                    className="text-sm font-medium text-default-900"
                  >
                    Document Verified *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="fDocVerified"
                                        placeholder="This is a search placeholder"
                                        value={documents.id}
                                        name={documents.name}
                                        onChange={(e) =>
                                            selectDocuementVerifiedTypeChange(e)
                                        }
                                    >
                                        <option value="0" hidden selected>
                                            Choose Status
                                        </option>
                                        {documents.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === docVerified}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Document-Verified">
                      Doc Verified
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Document-Verified"
                      id="demo-simple-select"
                      value={docVerified}
                      label="Doc Verified"
                      onChange={(e) => selectDocuementVerifiedTypeChange(e)}
                    >
                      {documents.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="regdOwner"
                    className="text-sm font-medium text-default-900"
                  >
                    Regd Owner *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            regdOwnerError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="regdOwner"
                                        placeholder="Enter regd owner"
                                        value={regdOwner}
                                        onChange={(e) => setRegdOwner(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Regd Owner"
                      variant="outlined"
                      className={
                        regdOwnerError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={regdOwner}
                      onChange={(e) => setRegdOwner(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="regdOwner"
                    className="text-sm font-medium text-default-900"
                  >
                    Regd Date *
                  </label>
                  <input
                    type="date"
                    className={
                      regdDateError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="regdDate"
                    placeholder="Enter product regd date"
                    value={regdDate}
                    onChange={(e) => setRegdDate(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fManufacturingDate"
                    className="text-sm font-medium text-default-900"
                  >
                    Manufacturing Date <span style={{ color: "red" }}>**</span>
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fManufacturingDate"
                    placeholder="Enter Manufacturing Date"
                    value={mfgDate}
                    onChange={(e) => setMfgdate(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fNumberPlate"
                    className="text-sm font-medium text-default-900"
                  >
                    Number Plate Type *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="fNumberPlate"
                                        placeholder="This is a search placeholder"
                                        value={number.id}
                                        name={number.name}
                                        onChange={(e) =>
                                            selectNumberPlateTypeChange(e)
                                        }
                                    >
                                        <option value="0" hidden selected>
                                            Choose Number Plate Type
                                        </option>
                                        {number.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === numberPlateType}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Number-Plate-Type">
                      Number Plate Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Number-Plate-Type"
                      id="demo-simple-select"
                      value={numberPlateType}
                      label="Number Plate Type"
                      onChange={(e) => selectNumberPlateTypeChange(e)}
                    >
                      {number.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine No *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            engNoError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="engNo"
                                        placeholder="Enter vehicle engine no"
                                        value={engNo}
                                        onChange={(e) => setEngNo(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Engine No"
                      variant="outlined"
                      className={
                        engNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={engNo}
                      onChange={(e) => setEngNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="chasisNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Chasis No *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            chasisNoError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="chasisNo"
                                        placeholder="Enter chasis no"
                                        value={chasisNo}
                                        onChange={(e) => setChasisNo(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Chasis No"
                      variant="outlined"
                      className={
                        chasisNoError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={chasisNo}
                      onChange={(e) => setChasisNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="bodyType"
                    className="text-sm font-medium text-default-900"
                  >
                    Body Type *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            bodyTypeError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="bodyType"
                                        placeholder="Enter body type"
                                        value={bodyType}
                                        onChange={(e) => setBodyType(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Body Type"
                      variant="outlined"
                      className={
                        bodyTypeError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={bodyType}
                      onChange={(e) => setBodyType(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMeterReading"
                    className="text-sm font-medium text-default-900"
                  >
                    Meter Reading *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fMeterReading"
                                        placeholder="Enter Meter Reading"
                                        value={meterReading}
                                        onChange={(e) => setMeterReading(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Meter Reading"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={meterReading}
                      onChange={(e) => setMeterReading(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fueltype"
                    className="text-sm font-medium text-default-900"
                  >
                    Fuel Type*
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            fueltypeError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fueltype"
                                        placeholder="Enter vehicle fuel type"
                                        value={fueltype}
                                        onChange={(e) => setFueltype(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Body Type"
                      variant="outlined"
                      className={
                        fueltypeError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={fueltype}
                      onChange={(e) => setFueltype(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fueltype"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Color *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            vehicleColorError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fueltype"
                                        placeholder="Enter vehicle color "
                                        value={vehicleColor}
                                        onChange={(e) => setVehicleColor(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Color"
                      variant="outlined"
                      className={
                        vehicleColorError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={vehicleColor}
                      onChange={(e) => setVehicleColor(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                  >
                    Gross Weight Of Vehicle *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="vehicleWeight"
                                        placeholder="Enter vehicle weight"
                                        value={vehicleWeight}
                                        onChange={(e) => setVehicleWeight(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Gross Weight Of Vehicle"
                      variant="outlined"
                      className={
                        vehicleWeightError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={vehicleWeight}
                      onChange={(e) => setVehicleWeight(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="ownerSerialNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Owner Serial No *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            ownerSerialNoError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="ownerSerialNo"
                                        placeholder="Enter vehicle owner serial no "
                                        value={ownerSerialNo}
                                        onChange={(e) => setOwnerSerialNo(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Vehicle Owner Serial No"
                      variant="outlined"
                      className={
                        ownerSerialNoError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={ownerSerialNo}
                      onChange={(e) => setOwnerSerialNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="ownerSerialNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Hypothecation Bank Name *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            hypothecationBankError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="ownerSerialNo"
                                        placeholder="Enter vehicle hypothecation bank name "
                                        value={hypothecationBank}
                                        onChange={(e) => setHypothecationBank(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Hypothecation Bank"
                      variant="outlined"
                      className={
                        hypothecationBankError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={hypothecationBank}
                      onChange={(e) => setHypothecationBank(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fInsuranceCompany"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Company Name *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fInsuranceCompany"
                                        placeholder="Enter Insurance Company Name"
                                        value={insuranceCompany}
                                        onChange={(e) => setInsuranceCompany(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Hypothecation Bank"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={insuranceCompany}
                      onChange={(e) => setInsuranceCompany(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fInsuranceStatus"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Expired *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="fInsuranceStatus"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) =>
                                            selectInsuranceStatusChange(e)
                                        }
                                    >
                                        <option value="0" hidden selected>
                                            Choose Insurance Status
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === insuranceStatus}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Insurance-Expired">
                      Insurance Expired
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Insurance-Expired"
                      id="demo-simple-select"
                      value={insuranceStatus}
                      label="Insurance Status"
                      onChange={(e) => selectInsuranceStatusChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="cubicCapacity"
                    className="text-sm font-medium text-default-900"
                  >
                    Cubic Capacity *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            cubicCapacityError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="cubicCapacity"
                                        placeholder="Enter product Cubic Capacity"
                                        value={cubicCapacity}
                                        onChange={(e) => setCubicCapacity(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Cubic Capacity"
                      variant="outlined"
                      className={
                        cubicCapacityError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={cubicCapacity}
                      onChange={(e) => setCubicCapacity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="seatingCapacity"
                    className="text-sm font-medium text-default-900"
                  >
                    Seating Capacity *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            eatingCapacityError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="seatingCapacity"
                                        placeholder="Enter product seating capacity"
                                        value={seatingCapacity}
                                        onChange={(e) => setSeatingCapacity(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Seating Capacity"
                      variant="outlined"
                      className={
                        eatingCapacityError !== ""
                          ? "textfield error_class"
                          : "textfield "
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={seatingCapacity}
                      onChange={(e) => setSeatingCapacity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-span-3 space-y-1">
                  <label
                    htmlFor="fLoanAgreement"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Video *
                  </label>
                  {showLoader === true ? (
                    <>
                      <div
                        style={{
                          height: "250px",
                          width: "50%",
                          backgroundColor: "#000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    </>
                  ) : (
                    <>
                      <iframe
                        title="Video Player"
                        width="50%" // Set width to 100% to fill the container
                        height="250px" // Set height to auto to maintain aspect ratio
                        src={videoRecord}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </>
                  )}
                  <input
                    type="file"
                    id="videouploading"
                    onChange={handleFileChange}
                    accept="video/*"
                  />
                  {selectedVideo ? (
                    <div>
                      <button
                        onClick={handleUpload}
                        style={{
                          backgroundColor: "#ff6600",
                          color: "#fff",
                          padding: "5px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        Upload Video
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        style={{
                          backgroundColor: "#ff6600",
                          opacity: ".5",
                          color: "#fff",
                          padding: "5px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        Upload Video
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-span-3 space-y-1">
                  <ImageUpload />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                  >
                    Selfie With Vehicle *
                  </label>
                  <input
                    type="file"
                    onChange={(e) => handleSelfieWithVehicleChange(e, 'image')}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isSelfieWithAgentLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={selfieWithVehicleImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="Chasis Image"
                    className="text-sm font-medium text-default-900"
                  >
                    Chasis Image *
                  </label>
                  <input
                    type="file"
                    onChange={handleChasisChange}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isChasisImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={chasisImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="formatfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Product Format Image *
                  </label>
                  <input
                    type="file"
                    onChange={handleFormatChange}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {IsformatImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={formatImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="openBonnetfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Open Bonnet Image *
                  </label>
                  <input
                    type="file"
                    onChange={handleOpenBonnetChange}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isOpenBonnetLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={openBonnet}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="openBonnetfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Right 45* Image *
                  </label>
                  <input
                    type="file"
                    onChange={handleright45Change}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRight45ImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={right45image}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontImage"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Image *
                  </label>
                  <input
                    type="file"
                    onChange={frontImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isFrontImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={frontimage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontUnderCar"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Under Car Image *
                  </label>
                  <input
                    type="file"
                    onChange={frontUnderCarImg}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isFrontUnderCarImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={frontUnderCarImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="left45file"
                    className="text-sm font-medium text-default-900"
                  >
                    Left 45* Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlesetLeft45Image}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isLeft45Loading ? (
                      <div></div>
                    ) : (
                      <img
                        src={left45image}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="leftfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Left Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlesetLeftImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isLeftImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={leftImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Rear Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlesetRearImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRearImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rearImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearunderpartfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Rear Under Part Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlerearUnderPartImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRearUnderPartLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rearUnderPartImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearunderpartfile"
                    className="text-sm font-medium text-default-900"
                  >
                    Open Dickey Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlerearOpenDickeyImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isOpenDickeyImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={openDickeyImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="handlerightImage"
                    className="text-sm font-medium text-default-900"
                  >
                    Open Handle Right Image *
                  </label>
                  <input
                    type="file"
                    onChange={HandlerightImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRightImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rightImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="tyreImage"
                    className="text-sm font-medium text-default-900"
                  >
                    Tyre Image *
                  </label>
                  <input
                    type="file"
                    onChange={handlesetTyreImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isTyreImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={tyreImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="glassOutside"
                    className="text-sm font-medium text-default-900"
                  >
                    Glass Outside Image*
                  </label>
                  <input
                    type="file"
                    onChange={handleGlassOutsideImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isGlassOutsideImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={glassOutsideImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="glassOutside"
                    className="text-sm font-medium text-default-900"
                  >
                    Glass Inside Image*
                  </label>
                  <input
                    type="file"
                    onChange={handleGlassInsideImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isGlassInsideImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={glassInsideImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="odometer"
                    className="text-sm font-medium text-default-900"
                  >
                    Odometer Image*
                  </label>
                  <input
                    type="file"
                    onChange={handleOdometerImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isOdometerImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={odometerImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rpmFile"
                    className="text-sm font-medium text-default-900"
                  >
                    RPM Image*
                  </label>
                  <input
                    type="file"
                    onChange={handleRpmImage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRpmImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rpmImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Any Damage *
                  </label>
                  <input
                    type="file"
                    onChange={handleAnyDamage}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isAnyDamageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={anyDamage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Extra Damage *
                  </label>
                  <input
                    type="file"
                    onChange={handleExtraImage}
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isExtraImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={extraImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Extra Image 1 *
                  </label>
                  <input
                    type="file"
                    onChange={handleExtraImage1}
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isExtraImage1Loading ? (
                      <div></div>
                    ) : (
                      <img
                        src={extraImage1}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Extra Image 2 *
                  </label>
                  <input
                    type="file"
                    onChange={handleExtraImage2}
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isExtraImage2Loading ? (
                      <div></div>
                    ) : (
                      <img
                        src={extraImage2}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyDamage"
                    className="text-sm font-medium text-default-900"
                  >
                    Extra Image 3 *
                  </label>
                  <input
                    type="file"
                    onChange={handleExtraImage3}
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isExtraImage3Loading ? (
                      <div></div>
                    ) : (
                      <img
                        src={extraImage3}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="signature"
                    className="text-sm font-medium text-default-900"
                  >
                    Upload Your Signature*
                  </label>

                  <input
                    type="file"
                    onChange={handleSignature}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isSignatureImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={signatureImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontRc"
                    className="text-sm font-medium text-default-900"
                  >
                    Upload Frontside of your RC*
                  </label>
                  <input
                    type="file"
                    onChange={handleFrontRc}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRcFrontImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rcFrontImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="backRc"
                    className="text-sm font-medium text-default-900"
                  >
                    Upload Frontside of your RC*
                  </label>
                  <input
                    type="file"
                    onChange={handleBackRc}
                    className={
                      vehicleWeightError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                  />
                  <div>
                    {isRcBackImageLoading ? (
                      <div></div>
                    ) : (
                      <img
                        src={rcBackImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="airConditioner"
                    className="text-sm font-medium text-default-900"
                  >
                    Air Conditioner *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="airConditioner"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectAirConditionerChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Air Conditioner
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === airConditioner}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-AC">Air Conditioner</InputLabel>
                    <Select
                      labelId="demo-simple-AC"
                      id="demo-simple-select"
                      value={airConditioner}
                      label="Air Conditioner"
                      onChange={(e) => selectAirConditionerChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="cdCharge"
                    className="text-sm font-medium text-default-900"
                  >
                    Cd Charge *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Cd">Cd Charge</InputLabel>
                    <Select
                      labelId="demo-simple-Cd"
                      id="demo-simple-select"
                      value={cdCharge}
                      label="Cd Charge"
                      onChange={(e) => selectCdChargeChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="cdCharge"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCdChargeChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Cd Charge
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === cdCharge}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="seatCover"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat Cover *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Seat">Seat Cover</InputLabel>
                    <Select
                      labelId="demo-simple-Seat"
                      id="demo-simple-select"
                      value={seatCover}
                      label="Seat Cover"
                      onChange={(e) => selectSeatCoverChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="seatCover"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectSeatCoverChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Seat Cover
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === seatCover}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fogLamps"
                    className="text-sm font-medium text-default-900"
                  >
                    Fog Lamps *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Lamps">Fog Lamps</InputLabel>
                    <Select
                      labelId="demo-simple-Lamps"
                      id="demo-simple-select"
                      value={fogLamps}
                      label="Fog Lamps"
                      onChange={(e) => selectFogLampsChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="fogLamps"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectFogLampsChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Fog Lamps
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === fogLamps}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="transmission"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Trans">Trasmission</InputLabel>
                    <Select
                      labelId="demo-simple-Trans"
                      id="demo-simple-select"
                      value={transmission}
                      label="Trasmission"
                      onChange={(e) => selectTransmissionChange(e)}
                    >
                      {Transmission.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="transmission"
                                        placeholder="This is a search placeholder"
                                        value={Features.id}
                                        name={Features.name}
                                        onChange={(e) => selectTransmissionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Transmission
                                        </option>
                                        {Features.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === transmission}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="odometer"
                    className="text-sm font-medium text-default-900"
                  >
                    Odometer *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Odo">Odo Meter</InputLabel>
                    <Select
                      labelId="demo-simple-Odo"
                      id="demo-simple-select"
                      value={odometer}
                      label="Odo Meter"
                      onChange={(e) => selectOdometerChange(e)}
                    >
                      {Features.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="odometer"
                                        placeholder="This is a search placeholder"
                                        value={Features.id}
                                        name={Features.name}
                                        onChange={(e) => selectOdometerChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Odometer
                                        </option>
                                        {Features.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === odometer}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="accidental"
                    className="text-sm font-medium text-default-900"
                  >
                    Accidental *
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Accident">
                      Accidental
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Accident"
                      id="demo-simple-select"
                      value={accidental}
                      label="Accidental"
                      onChange={(e) => selectAccidentalChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="accidental"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectAccidentalChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Accidental
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === accidental}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="otherRepair"
                    className="text-sm font-medium text-default-900"
                  >
                    Other Repair *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="otherRepair"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectotherRepairlChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Other Repair
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === otherRepair}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Repair">
                      Other Repair
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Repair"
                      id="demo-simple-select"
                      value={otherRepair}
                      label="Other Repair"
                      onChange={(e) => selectotherRepairlChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="fitnessExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Fitness Expiry *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fitnessExpiry"
                    placeholder="Enter FitnessExpiry"
                    value={fitnessExpiry}
                    onChange={(e) => setFitnessExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="roadtaxValidity"
                    className="text-sm font-medium text-default-900"
                  >
                    Roadtax Validity *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="roadtaxValidity"
                    placeholder="Enter RoadtaxValidity"
                    value={roadtaxValidity}
                    onChange={(e) => setroadtaxValidity(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fIdvValue"
                    className="text-sm font-medium text-default-900"
                  >
                    Idv Value *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fIdvValue"
                                        placeholder="Enter Idv Value"
                                        value={idvValue}
                                        onChange={(e) => setIdvValue(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Gross Weight Of Vehicle"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={idvValue}
                      onChange={(e) => setIdvValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fInsuranceExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Expiry *
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fInsuranceExpiry"
                    placeholder="Enter Insurance Expiry"
                    value={insuranceExpiry}
                    onChange={(e) => setInsuranceExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engineCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Condition <span style={{ color: "red" }}>**</span>
                  </label>
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Engine">
                      Engine Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Engine"
                      id="demo-simple-select"
                      value={engineCondition}
                      label="Engine Condition"
                      onChange={(e) => selectEngineConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <select
                                        className={
                                            engineConditionError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="engineCondition"
                                        placeholder="This is a search placeholder"
                                        value={reqCondition.id}
                                        name={reqCondition.name}
                                        onChange={(e) => selectEngineConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Engine Condition
                                        </option>
                                        {reqCondition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === engineCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="wheelType"
                    className="text-sm font-medium text-default-900"
                  >
                    Wheel Type *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="wheelType"
                                        placeholder="This is a search placeholder"
                                        value={wheelTypeList.id}
                                        name={wheelTypeList.name}
                                        onChange={(e) => selectWheelTypeChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Wheel Type
                                        </option>
                                        {wheelTypeList.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === wheelType}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-wheel">Wheel Type</InputLabel>
                    <Select
                      labelId="demo-simple-wheel"
                      id="demo-simple-select"
                      value={wheelType}
                      label="Wheel Type"
                      onChange={(e) => selectWheelTypeChange(e)}
                    >
                      {wheelTypeList.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="fTotalTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Total Tyre * (Type In Numbers)
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fTotalTyre"
                                        placeholder="Enter Total Tyre"
                                        value={totalTyre}
                                        onChange={(e) => setTotalTyre(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Total Tyre"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={totalTyre}
                      onChange={(e) => setTotalTyre(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="availableTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Available Tyre * (Type In Numbers)
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="availableTyre"
                                        placeholder="Enter Available Tyre"
                                        value={availableTyre}
                                        onChange={(e) => setAvailableTyre(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Available Tyre"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={availableTyre}
                      onChange={(e) => setAvailableTyre(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMissingTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Missing Tyre * (Type In Numbers)
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fMissingTyre"
                                        placeholder="Enter Missing Tyre"
                                        value={missingTyre}
                                        onChange={(e) => setMissingTyre(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Missing Tyre"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={missingTyre}
                      onChange={(e) => setMissingTyre(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fSpareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare Tyre * (Type In Numbers)
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fSpareTyre"
                                        placeholder="Enter Spare Tyre"
                                        value={spareTyre}
                                        onChange={(e) => setSpareTyre(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Spare Tyre"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={spareTyre}
                      onChange={(e) => setSpareTyre(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="interiorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Interior Condition <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            interiorConditionError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="interiorCondition"
                                        placeholder="This is a search placeholder"
                                        value={reqCondition.id}
                                        name={reqCondition.name}
                                        onChange={(e) => selectInteriorConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Interior Condition
                                        </option>
                                        {reqCondition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === interiorCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Interior">
                      Interior Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Interior"
                      id="demo-simple-select"
                      value={interiorCondition}
                      label="Interior Condition"
                      onChange={(e) => selectInteriorConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="exteriorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Exterior Condition <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            exteriorConditionError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="exteriorCondition"
                                        placeholder="This is a search placeholder"
                                        value={reqCondition.id}
                                        name={reqCondition.name}
                                        onChange={(e) => selectExteriorConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Exterior Condition
                                        </option>
                                        {reqCondition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === exteriorCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Exterior">
                      Exterior Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Exterior"
                      id="demo-simple-select"
                      value={exteriorCondition}
                      label="Exterior Condition"
                      onChange={(e) => selectExteriorConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="headLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Head Lamp *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="headLamp"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectHeadLampChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Head Lamp
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === headLamp}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Accident">Head Lamp</InputLabel>
                    <Select
                      labelId="demo-simple-Accident"
                      id="demo-simple-select"
                      value={headLamp}
                      label="Head Lamp"
                      onChange={(e) => selectHeadLampChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="tailLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Tail Lamp *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="tailLamp"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectTailLampChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Tail Lamp
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === tailLamp}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}

                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Tail">Tail Lamp</InputLabel>
                    <Select
                      labelId="demo-simple-Tail"
                      id="demo-simple-select"
                      value={tailLamp}
                      label="Tail Lamp"
                      onChange={(e) => selectTailLampChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Indicators *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="frontIndicators"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectFrontIndicatorsChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Front Indicators
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === frontIndicators}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-FrontIn">
                      Front Indicators
                    </InputLabel>
                    <Select
                      labelId="demo-simple-FrontIn"
                      id="demo-simple-select"
                      value={frontIndicators}
                      label="Front Indicators"
                      onChange={(e) => selectFrontIndicatorsChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Indicators *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="rearIndicators"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectRearIndicatorsChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Rear Indicators
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === rearIndicators}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-RearIn">
                      Rear Indicators
                    </InputLabel>
                    <Select
                      labelId="demo-simple-RearIn"
                      id="demo-simple-select"
                      value={rearIndicators}
                      label="Rear Indicators"
                      onChange={(e) => selectRearIndicatorsChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="maintenanceLevel"
                    className="text-sm font-medium text-default-900"
                  >
                    Maintenance Level *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="maintenanceLevel"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectMaintenanceLevelChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Maintenance Level
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === maintenanceLevel}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Maintanance">
                      Maintanance Level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Maintanance"
                      id="demo-simple-select"
                      value={maintenanceLevel}
                      label="Maintanance Level"
                      onChange={(e) => selectMaintenanceLevelChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontBreakCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    FrontBreak Condition{" "}
                    <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            frontBreakConditionError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="frontBreakCondition"
                                        placeholder="This is a search placeholder"
                                        value={reqCondition.id}
                                        name={reqCondition.name}
                                        onChange={(e) => selectFrontBreakConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose FrontBreak Condition
                                        </option>
                                        {reqCondition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === frontBreakCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Front-Break">
                      Front Break Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Front-Break"
                      id="demo-simple-select"
                      value={frontBreakCondition}
                      label="Front Break Condition"
                      onChange={(e) => selectFrontBreakConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearBreakCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Break Condition{" "}
                    <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            rearBreakConditionError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="rearBreakCondition"
                                        placeholder="This is a search placeholder"
                                        value={reqCondition.id}
                                        name={reqCondition.name}
                                        onChange={(e) => selectRearBreakConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Rear Break Condition
                                        </option>
                                        {reqCondition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === rearBreakCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Rear-break">
                      Rear break Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Rear-break"
                      id="demo-simple-select"
                      value={rearBreakCondition}
                      label="Rear break Condition"
                      onChange={(e) => selectRearBreakConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engineThere"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine There *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="engineThere"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectEngineThereChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Engine There
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === engineThere}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}

                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Engine-there">
                      Engine There
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Engine-there"
                      id="demo-simple-select"
                      value={engineThere}
                      label="Engine There"
                      onChange={(e) => selectEngineThereChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fluidLeaks"
                    className="text-sm font-medium text-default-900"
                  >
                    Fluid Leaks *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="fluidLeaks"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectFluidLeaksChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Fluid Leaks
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === fluidLeaks}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Fluid-leaks">
                      Fluid Leaks
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Fluid-leaks"
                      id="demo-simple-select"
                      value={fluidLeaks}
                      label="Fluid Leaks"
                      onChange={(e) => selectFluidLeaksChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engineShield"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Engine Shield *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="engineShield"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectEngineShieldChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Engine Shield
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === engineShield}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Engine">
                      Engine Shield
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Engine"
                      id="demo-simple-select"
                      value={engineShield}
                      label="Engine Shield"
                      onChange={(e) => selectEngineShieldChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="transmissionCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission Condition{" "}
                    <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            transmissionConditionError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="transmissionCondition"
                                        placeholder="This is a search placeholder"
                                        value={reqCondition.id}
                                        name={reqCondition.name}
                                        onChange={(e) => selectTransmissionConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Transmission Condition
                                        </option>
                                        {reqCondition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === transmissionCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Transmission-Cond">
                      Transmission Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Transmission-Cond"
                      id="demo-simple-select"
                      value={transmissionCondition}
                      label="Transmission Condition"
                      onChange={(e) => selectTransmissionConditionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="suspension"
                    className="text-sm font-medium text-default-900"
                  >
                    Suspension Condition{" "}
                    <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            suspensionError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="suspension"
                                        placeholder="This is a search placeholder"
                                        value={reqCondition.id}
                                        name={reqCondition.name}
                                        onChange={(e) => selectSuspensionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Suspension
                                        </option>
                                        {reqCondition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === suspension}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Suspension">
                      Suspention Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Suspension"
                      id="demo-simple-select"
                      value={suspension}
                      label="Suspention Condition"
                      onChange={(e) => selectSuspensionChange(e)}
                    >
                      {reqCondition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="handBrake"
                    className="text-sm font-medium text-default-900"
                  >
                    Hand Brake *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="handBrake"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectHandBrakeChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Hand Brake
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === handBrake}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Hand-Brake">
                      Hand Brake
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Hand-Brake"
                      id="demo-simple-select"
                      value={handBrake}
                      label="Hand Brake"
                      onChange={(e) => selectHandBrakeChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fCarTyreType"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Tyre Type *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fCarTyreType"
                                        placeholder="Enter Car Tyre Type"
                                        value={carTyreType}
                                        onChange={(e) => setCarTyreType(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Car Typre Type"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={carTyreType}
                      onChange={(e) => setCarTyreType(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carLhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Lh Front <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <div style={{ color: 'red' }} >{carLhFrontError}</div> */}
                  {/* <select
                                        className={
                                            carLhFrontError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="carLhFront"
                                        placeholder="This is a search placeholder"
                                        value={list.id}
                                        name={list.name}
                                        onChange={(e) => selectCarLhFrontChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Lh Front
                                        </option>
                                        {list.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={Number(test.name) === carLhFront}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Lh-Front">
                      Car Lh Front
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Lh-Front"
                      id="demo-simple-select"
                      value={carLhFront}
                      label="Car Lh Front"
                      onChange={(e) => selectCarLhFrontChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carRhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Rh Front <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            carRhFrontError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="carRhFront"
                                        placeholder="This is a search placeholder"
                                        value={list.id}
                                        name={list.name}
                                        onChange={(e) => selectCarRhFrontChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Rh Front
                                        </option>
                                        {list.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={Number(test.name) === carRhFront}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Rh-Front">
                      Car Rh Front
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Rh-Front"
                      id="demo-simple-select"
                      value={carRhFront}
                      label="Car Rh Front"
                      onChange={(e) => selectCarRhFrontChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carLhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Lh Rear <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            carLhRearError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="carLhRear"
                                        placeholder="This is a search placeholder"
                                        value={list.id}
                                        name={list.name}
                                        onChange={(e) => selectCarLhRearChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Lh Rear
                                        </option>
                                        {list.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={Number(test.name) === carLhRear}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Lh-Rear">
                      Car Lh Rear
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Lh-Rear"
                      id="demo-simple-select"
                      value={carLhRear}
                      label="Car Lh Rear"
                      onChange={(e) => selectCarLhRearChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carRhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Rh Rear <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            carRhRearError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="carRhRear"
                                        placeholder="This is a search placeholder"
                                        value={list.id}
                                        name={list.name}
                                        onChange={(e) => selectCarRhRearChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Rh Rear
                                        </option>
                                        {list.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={Number(test.name) === carRhRear}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Rh-Rear">
                      Car Rh Rear
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Rh-Rear"
                      id="demo-simple-select"
                      value={carRhRear}
                      label="Car Rh Rear"
                      onChange={(e) => selectCarRhRearChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carSpareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Spare Tyre <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <select
                                        className={
                                            carSpareTyreError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="carSpareTyre"
                                        placeholder="This is a search placeholder"
                                        value={list.id}
                                        name={list.name}
                                        onChange={(e) => selectCarSpareTyreChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Spare Tyre
                                        </option>
                                        {list.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={Number(test.name) === carSpareTyre}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Spare-Tyre">
                      Car Spare Tyre
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Spare-Tyre"
                      id="demo-simple-select"
                      value={carSpareTyre}
                      label="Car Spare Tyre"
                      onChange={(e) => selectCarSpareTyreChange(e)}
                    >
                      {list.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fCarSteering"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Steering *
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fCarSteering"
                                        placeholder="Enter Car Steering"
                                        value={carSteering}
                                        onChange={(e) => setCarSteering(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Car Steering"
                      variant="outlined"
                      className="textfield"
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={carSteering}
                      onChange={(e) => setCarSteering(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carSteeringCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Steering Condition *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carSteeringCondition"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarSteeringConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Steering Condition
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carSteeringCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car-Steering">
                      Car Steering Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car-Steering"
                      id="demo-simple-select"
                      value={carSteeringCondition}
                      label="Car Steering Condition"
                      onChange={(e) => selectCarSteeringConditionChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carDashboard"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Dashboard *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carDashboard"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarDashboardChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Dashboard
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carDashboard}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Dashboard">
                      Car Dashboard
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Dashboard"
                      id="demo-simple-select"
                      value={carDashboard}
                      label="Car Dashboard"
                      onChange={(e) => selectCarDashboardChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carSeat"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Seat *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carSeat"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarSeatChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Seat
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carSeat}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car-Seat">Car Seat</InputLabel>
                    <Select
                      labelId="demo-simple-Car-Seat"
                      id="demo-simple-select"
                      value={carSeat}
                      label="Car Seat"
                      onChange={(e) => selectCarSeatChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carInterior"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Interior *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carInterior"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarInteriorChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Interior
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carInterior}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car-Interior">
                      Car Interior
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car-Interior"
                      id="demo-simple-select"
                      value={carInterior}
                      label="Car Interior"
                      onChange={(e) => selectCarInteriorChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carAirbag"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Airbag *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carAirbag"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarAirbagChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Airbag
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carAirbag}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-AirBag">Car Air Bag</InputLabel>
                    <Select
                      labelId="demo-simple-AirBag"
                      id="demo-simple-select"
                      value={carAirbag}
                      label="Car Air Bag"
                      onChange={(e) => selectCarAirbagChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carAntiLock"
                    className="text-sm font-medium text-default-900"
                  >
                    Car AntiLock *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carAirbag"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarAntiLockChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car AntiLock
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carAntiLock}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-AntiLock">
                      Car AntiLock
                    </InputLabel>
                    <Select
                      labelId="demo-simple-AntiLock"
                      id="demo-simple-select"
                      value={carAntiLock}
                      label="Car AntiLock"
                      onChange={(e) => selectCarAntiLockChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fCarPowerWindowFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Power Window Front *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="fCarPowerWindowFront"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarPowerWindowFront(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Power Window Front Available
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carPowerWindowFront}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Power-Front">
                      Car Power Window Front
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Power-Front"
                      id="demo-simple-select"
                      value={carPowerWindowFront}
                      label="Car Power Window Front"
                      onChange={(e) => selectCarPowerWindowFront(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fCarPowerWindowRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Power Window Rear Available *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="fCarPowerWindowRear"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarPowerWindowRear(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Power Window Rear
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carPowerWindowRear}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Power-Rear">
                      Car Power Window Rear
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Power-Rear"
                      id="demo-simple-select"
                      value={carPowerWindowRear}
                      label="Car Power Window Rear"
                      onChange={(e) => selectCarPowerWindowRear(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carSeatBelt"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Seat Belt *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carSeatBelt"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarSeatBeltChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Seat Belt
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carSeatBelt}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Seat-belt">
                      Car Seat Belt
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Seat-belt"
                      id="demo-simple-select"
                      value={carSeatBelt}
                      label="Car Seat Belt"
                      onChange={(e) => selectCarSeatBeltChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carInsideRearViewMirror"
                    className="text-sm font-medium text-default-900"
                  >
                    Inside Rear View Mirror Condition *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carInsideRearViewMirror"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarInsideRearViewMirrorChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Inside Rear View Mirror Condition
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carInsideRearViewMirror}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Rear-View">
                      Car Inside Rear View Mirror
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Rear-View"
                      id="demo-simple-select"
                      value={carInsideRearViewMirror}
                      label="Car Inside Rear View Mirror"
                      onChange={(e) => selectCarInsideRearViewMirrorChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carBonnet"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Bonnet *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carBonnet"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarBonnetChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Bonnet
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carBonnet}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Bonnet">Car Bonnet</InputLabel>
                    <Select
                      labelId="demo-simple-Bonnet"
                      id="demo-simple-select"
                      value={carBonnet}
                      label="Car Bonnet"
                      onChange={(e) => selectCarBonnetChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carFrontBumper"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Front Bumper *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carFrontBumper"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarFrontBumperChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Bonnet
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carFrontBumper}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-CarFront-Bumper">
                      Car Front Bumper
                    </InputLabel>
                    <Select
                      labelId="demo-simple-CarFront-Bumper"
                      id="demo-simple-select"
                      value={carFrontBumper}
                      label="Car Front Bumper"
                      onChange={(e) => selectCarFrontBumperChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carRearBumper"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Rear Bumper *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carRearBumper"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarRearBumperChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Rear Bumper
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carRearBumper}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car-Rear-Bumper">
                      Car Rear Bumper
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car-Rear-Bumper"
                      id="demo-simple-select"
                      value={carRearBumper}
                      label="Car Rear Bumper"
                      onChange={(e) => selectCarRearBumperChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carWindScreen"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Wind Screen *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carWindScreen"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarWindScreenChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Wind Screen
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carWindScreen}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car Wind Screen">
                      Car Wind Screen
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car Wind Screen"
                      id="demo-simple-select"
                      value={carWindScreen}
                      label="Car Wind Screen"
                      onChange={(e) => selectCarWindScreenChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carWindScreenGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Wind Screen Glass *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carWindScreenGlass"
                                        placeholder="This is a search placeholder"
                                        value={qualityList.id}
                                        name={qualityList.name}
                                        onChange={(e) => selectCarWindScreenGlassChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Wind Screen Glass
                                        </option>
                                        {qualityList.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carWindScreenGlass}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car Wind">
                      Car Wind Screen Glass{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car Wind"
                      id="demo-simple-select"
                      value={carWindScreenGlass}
                      label="Car Wind Screen Glass "
                      onChange={(e) => selectCarWindScreenGlassChange(e)}
                    >
                      {qualityList.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carRearWindScreenGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Rear Wind Screen Glass *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carRearWindScreenGlass"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarRearWindScreenGlassChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Rear Wind Screen Glass
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carRearWindScreenGlass}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Wind-Screen">
                      Car Rear Wind Screen Screen
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Wind-Screen"
                      id="demo-simple-select"
                      value={carRearWindScreenGlass}
                      label="Car Rear Wind Screen Screen"
                      onChange={(e) => selectCarRearWindScreenGlassChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carRhDoorGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    Car RhDoor Glass *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carRhDoorGlass"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarRhDoorGlassChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car RhDoor Glass
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carRhDoorGlass}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Rh-Door">
                      Car Rh Door Glass
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Rh-Door"
                      id="demo-simple-select"
                      value={carRhDoorGlass}
                      label="Car Rh Door Glass"
                      onChange={(e) => selectCarRhDoorGlassChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carRhSideBody"
                    className="text-sm font-medium text-default-900"
                  >
                    Car RhSide Body *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carRhSideBody"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarRhSideBodyChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car RhSide Body
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carRhSideBody}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Rh-Side">
                      Car Rh Side Body
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Rh-Side"
                      id="demo-simple-select"
                      value={carRhSideBody}
                      label="Car Rh Side Body"
                      onChange={(e) => selectCarRhSideBodyChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carLhSideBody"
                    className="text-sm font-medium text-default-900"
                  >
                    Car LhSide Body *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carLhSideBody"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarLhSideBodyChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car LhSide Body
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carLhSideBody}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-LhSide">
                      Car Lh Side Body
                    </InputLabel>
                    <Select
                      labelId="demo-simple-LhSide"
                      id="demo-simple-select"
                      value={carLhSideBody}
                      label="Car Lh Side Body"
                      onChange={(e) => selectCarLhSideBodyChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carLhDoorGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    Car LhDoor Glass *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carLhDoorGlass"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarLhDoorGlassChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car LhDoor Glass
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carLhDoorGlass}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Lh-Door">
                      Car Lh Door Glass
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Lh-Door"
                      id="demo-simple-select"
                      value={carLhDoorGlass}
                      label="Car Lh Door Glass"
                      onChange={(e) => selectCarLhDoorGlassChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carOutsideRearView"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Outside Rear View *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carOutsideRearView"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectcarOutsideRearViewChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Outside Rear View
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carOutsideRearView}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Outside-View">
                      Car Outside Rear View
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Outside-View"
                      id="demo-simple-select"
                      value={carOutsideRearView}
                      label="Car Outside Rear View"
                      onChange={(e) => selectcarOutsideRearViewChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carPaint"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Paint *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carPaint"
                                        placeholder="This is a search placeholder"
                                        value={qualityList.id}
                                        name={qualityList.name}
                                        onChange={(e) => selectcarPaintChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Paint
                                        </option>
                                        {qualityList.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carPaint}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car-Paint">
                      Car Paint
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car-Paint"
                      id="demo-simple-select"
                      value={carPaint}
                      label="Car Paint"
                      onChange={(e) => selectcarPaintChange(e)}
                    >
                      {qualityList.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carPaintCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Paint Condition *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carPaintCondition"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectcarPaintConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Paint Condition
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carPaintCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Paint">
                      Car Paint Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Paint"
                      id="demo-simple-select"
                      value={carPaintCondition}
                      label="Car Paint Condition"
                      onChange={(e) => selectcarPaintConditionChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carOverAllBodyCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Over AllBody Condition *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carOverAllBodyCondition"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectcarOverAllBodyConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car  Over AllBody Condition
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carOverAllBodyCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Overall">
                      Car Overall Body Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Overall"
                      id="demo-simple-select"
                      value={carOverAllBodyCondition}
                      label="Car Overall Body Condition"
                      onChange={(e) => selectcarOverAllBodyConditionChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carBattery"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Battery *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carBattery"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectcarBatteryChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Battery
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carBattery}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Battery">
                      Car Battery
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Battery"
                      id="demo-simple-select"
                      value={carBattery}
                      label="Car Battery"
                      onChange={(e) => selectcarBatteryChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carInstrumentCluster"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Instrument Cluster *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carInstrumentCluster"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarInstrumentClusterChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Car Instrument Cluster
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carInstrumentCluster}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Instrument">
                      Car Instrumentor Clustor
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Instrument"
                      id="demo-simple-select"
                      value={carInstrumentCluster}
                      label="Car Instrumentor Clustor"
                      onChange={(e) => selectCarInstrumentClusterChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carFogLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Fog Lamp *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carFogLamp"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarFogLampChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose  Car Fog Lamp
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carFogLamp}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car-Fog">
                      Car Fog Lamp
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car-Fog"
                      id="demo-simple-select"
                      value={carFogLamp}
                      label="Car Fog Lamp"
                      onChange={(e) => selectCarFogLampChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carFogLampCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Car FogLamp Condition *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carFogLampCondition"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectCarFogLampConditionChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose  Car FogLamp Condition
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carFogLampCondition}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Fog-Cond">
                      Car Fog Lamp Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Fog-Cond"
                      id="demo-simple-select"
                      value={carFogLampCondition}
                      label="Car Fog Lamp Condition"
                      onChange={(e) => selectCarFogLampConditionChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carSunroof"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Sunroof *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carSunroof"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarSunroofChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose  Car Sunroof
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carSunroof}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Sunroof">
                      Car sunroof
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Sunroof"
                      id="demo-simple-select"
                      value={carSunroof}
                      label="Car sunroof"
                      onChange={(e) => selectCarSunroofChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carMusicSyetem"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Music Syetem *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carMusicSyetem"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarMusicSyetemChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose  Car Music Syetem
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carMusicSyetem}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Music">
                      Car Music System
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Music"
                      id="demo-simple-select"
                      value={carMusicSyetem}
                      label="Car Music System"
                      onChange={(e) => selectCarMusicSyetemChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carRoofRails"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Roof Rails *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carRoofRails"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarRoofRailsChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose  Car Roof Rails
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carRoofRails}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car-Roof">
                      Car Roof Rails
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car-Roof"
                      id="demo-simple-select"
                      value={carRoofRails}
                      label="Car Roof Rails"
                      onChange={(e) => selectCarRoofRailsChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carAntenna"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Antenna *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carAntenna"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarAntennaChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose  Car Antenna
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carAntenna}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Antenna">
                      Car Antenna
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Antenna"
                      id="demo-simple-select"
                      value={carAntenna}
                      label="Car Antenna"
                      onChange={(e) => selectCarAntennaChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="carReverseCamera"
                    className="text-sm font-medium text-default-900"
                  >
                    Car Reverse Camera *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="carReverseCamera"
                                        placeholder="This is a search placeholder"
                                        value={options.id}
                                        name={options.name}
                                        onChange={(e) => selectCarReverseCameraChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose  Car Reverse Camera
                                        </option>
                                        {options.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === carReverseCamera}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Car-Reverse">
                      Car Reverse Camera
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Car-Reverse"
                      id="demo-simple-select"
                      value={carReverseCamera}
                      label="Car Reverse Camera"
                      onChange={(e) => selectCarReverseCameraChange(e)}
                    >
                      {options.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="yourRemark"
                    className="text-sm font-medium text-default-900"
                  >
                    Your Remark *
                  </label>
                  {/* <select
                                        className={`border-default-200 w-full`}
                                        data-trigger
                                        id="yourRemark"
                                        placeholder="This is a search placeholder"
                                        value={condition.id}
                                        name={condition.name}
                                        onChange={(e) => selectYourRemarkChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose  Your Remark
                                        </option>
                                        {condition.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                                selected={test.name === yourRemark}
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select> */}
                  <FormControl
                    fullWidth
                    style={{ marginTop: "12px" }}
                    className="textfield"
                  >
                    <InputLabel id="demo-simple-Remark">Your Remark</InputLabel>
                    <Select
                      labelId="demo-simple-Remark"
                      id="demo-simple-select"
                      value={yourRemark}
                      label="Your Remark"
                      onChange={(e) => selectYourRemarkChange(e)}
                    >
                      {condition.map((test, index) => (
                        <MenuItem value={test.name} key={index}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Ex-ShowRoom Price <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            exShowRoomPriceError === ""
                                                ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                                                : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                                        }
                                        id="fExShowRoomPrice"
                                        placeholder="Enter Ex-ShowRoom Price"
                                        value={exShowRoomPrice}
                                        onChange={(e) => setExShowRoomPrice(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Ex ShowRoom Price"
                      variant="outlined"
                      className={
                        exShowRoomPriceError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={exShowRoomPrice}
                      onChange={(e) => setExShowRoomPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fEstimatedPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Estimated Price <span style={{ color: "red" }}>**</span>
                  </label>
                  {/* <input
                                        type="text"
                                        className={
                                            estimatedPriceError === ""
                                                ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                                                : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                                        }
                                        id="fEstimatedPrice"
                                        placeholder="Enter Estimated Price"
                                        value={estimatedPrice}
                                        onChange={(e) => setEstimatedPrice(e.target.value)}
                                    /> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Estimated Price"
                      variant="outlined"
                      className={
                        estimatedPriceError !== ""
                          ? "textfield error_class"
                          : "textfield"
                      }
                      style={{ marginTop: "5px" }}
                      type="text"
                      id="text"
                      value={estimatedPrice}
                      onChange={(e) => setEstimatedPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "80px" }}
          >
            <button
              onClick={(e) => cancelClick(e)}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
              style={{
                outline: "none",
                border: "none",
                color: "#fff",
                backgroundColor: "red",
              }}
            >
              <i className="ph-bold ph-x text-lg" /> cancel
            </button>
            {
              buttonLoading ?
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{ background: "grey", outline: "none", border: "none" }}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Submitting
                </button>
                :
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  onClick={(e) => handlesubmit(e)}
                  style={{ background: "#4D44B5", outline: "none", border: "none" }}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Submit
                </button>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default AddFourWheelerCase;
