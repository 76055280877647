import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import { updateAdmin } from "../../../redux/Authentication/AuthSlice";

const EmployeeList = () => {
  const { employeeList } = useSelector((store) => store.employee);
  const { loginData } = useSelector((store) => store.auth);

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (loginData && loginData.permittedState && loginData.role === 'businessAssociate') {
      const data = employeeList.filter(indEmployee =>
        loginData.permittedState.some(state =>
          indEmployee.permittedState.includes(state)
        )
      );
      console.log(data, 'data');
      setFilterData(data);
    } else {
      setFilterData(employeeList);
    }
  }, [search, employeeList]);

  const changewm = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    const formData = {
      _id: data._id,
      WebsiteManagement: value,
    };
    // const updateprod = await dispatch(updateAdmin(formData));
  };
  const changesm = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    const formData = {
      _id: data._id,
      StudentManagent: value,
    };
    // const updateprod = await dispatch(updateAdmin(formData));
  };
  const changeum = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    const formData = {
      _id: data._id,
      UniversityManagement: value,
    };
    // const updateprod = await dispatch(updateAdmin(formData));
  };
  const changetm = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    const formData = {
      _id: data._id,
      TestManagement: value,
    };
    // const updateprod = await dispatch(updateAdmin(formData));
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
            <div className="flex flex-wrap items-center justify-end py-1 px-5">
              <span
                onClick={() => navigate("/admin/addemployee")}
                style={{ cursor: "pointer", backgroundColor: '#4d44b5', color: '#fff' }}
                className="py-2 px-5 inline-flex items-center justify-center font-semibold tracking-wide align-middle duration-500 text-sm text-center bg-primary hover:bg-primary-500 text-white rounded"
              >
                <i className="ph ph-plus-circle text-lg/none me-3" /> Add Employee
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div className="shadow rounded-lg bg-white dark:bg-default-50" style={{ marginBottom: '80px' }}>
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Mobile
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Permitted State
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-dashed divide-default-200">
                    {filterData &&
                      filterData
                        .map((admin, index) => (
                          <tr key={index}>
                            <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              <b>{index + 1}</b>
                            </td>
                            <td className="px-6 py-3 whitespace-nowrap"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              <h6 className="text-sm font-semibold text-default-700">
                                {admin.name.length > 30 ? (
                                  <>{admin.name.slice(0, 30)}...</>
                                ) : (
                                  <>{admin.name.slice(0, 30)}</>
                                )}
                              </h6>
                            </td>
                            <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              {admin.phone}
                            </td>
                            <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              {admin.email.length > 18 ? (
                                <>{admin.email.slice(0, 18)}...</>
                              ) : (
                                <>{admin.email.slice(0, 18)}</>
                              )}
                            </td>
                            <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              {admin.role === "businessAssociate" ? "Businss Associate" : admin.role
                              }
                            </td>
                            <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              {admin?.permittedState.map((indState, index) =>
                                <div key={index} style={{ fontSize: '10px' }}>{indState}</div>
                              )
                              }
                            </td>
                            <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              <div className="flex items-center justify-center gap-2">
                                <button className="inline-flex items-center justify-center h-8 w-8 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white">
                                  <Link
                                    to={`/admin/updateemployee/${admin._id}`}
                                  >
                                    <i className="ph-duotone ph-pencil-simple-line text-base" />
                                  </Link>
                                </button>
                                {/* <button className="inline-flex items-center justify-center h-8 w-8 rounded-md bg-red-500/20 text-red-500 transition-all duration-200 hover:bg-red-500 hover:text-white">
                                  <i className="ph-duotone ph-trash text-base" />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
              <h6 className="text-default-600">Showing {filterData.length} results</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
