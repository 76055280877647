import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { createBrandLogo, logoActions, updateLogo, uploadBrandLogo } from '../../redux/Logo/BrandLogoSlice';

const BrandLogo = () => {
    const { isBrandLoading, brandLogo, logoId } = useSelector((store) => store.brandLogo);
    const dispatch = useDispatch()
    const [brandError, setBrandError] = useState("")
    const [btnPress, setbtnPress] = useState(false);

    useEffect(() => {
        if (btnPress === true) {
            if (isBrandLoading) {
                setBrandError("Please upload a valid image")
            } else {
                setBrandError("")
            }
        }
    }, [btnPress, isBrandLoading])

    const handleBrandLogo = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadBrandLogo({ logo: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isBrandLoading) {
            setBrandError("Please upload a image to submit")
        } else {
            setBrandError("")
        }
        if (!isBrandLoading) {
            const formData = {
                _id: logoId,
                logo: brandLogo
            }
            const logo = await dispatch(updateLogo(formData))
            if (logo.payload.success) {
                alert("Logo uploaded successfully")
                dispatch(logoActions.removeBrndLogo())
                setbtnPress(false)
                setBrandError("")
            }
        } else {
            setbtnPress(true)
        }
    }
    return (
        <>
            <div className="space-y-1" style={{borderTop:'1px dashed #555',paddingTop:'50px',marginBottom:'50px'}}>
                <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                >
                    Upload brand Logo *
                </label>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                    <input type="file" onChange={handleBrandLogo}
                        className={
                            brandError === ""
                                ? "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                                : "block rounded py-2.5 px-4 text-default-800 text-sm  focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        }
                    />
                    <button style={{ backgroundColor: '#4D44B5', padding: '8px 15px', color: '#fff', borderRadius: '10px' }} onClick={(e) => handleSubmit(e)}>Submit</button>
                </div>
                <div>
                    {isBrandLoading ? (
                        <div></div>
                    ) : (
                        <img src={brandLogo} style={{ height: '100px', width: '100px' }} alt="img" />
                    )}
                </div>
            </div>
        </>
    )
}

export default BrandLogo