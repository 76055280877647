import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee, updateEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import { CaseActions, UploadChasisImage, UploadFrontImage, UploadOpenBonnet, UploadRearUnderPart, UploadRight45Image, createCase, selfieWithVehicle, sendVehicleNumber, uploadAnyDamage, uploadFrontUnderCar, uploadGlassInsideImage, uploadGlassOutside, uploadLeft45, uploadLeftImage, uploadOdometerImage, uploadOpenDickey, uploadRcBackImage, uploadRcFront, uploadRearImage, uploadRightImage, uploadRpmImage, uploadSignature, uploadTyreImage, uploadformatImage, validateEmail, validateMobile, validateNumber } from "../../redux/Cases/CaseSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ChooseCase = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [assetType, setAssetType] = useState('');
    const [assetTypeError, setAssetTypeError] = useState('');

    const selectAssetType = [
        {
            id: 1,
            name: "Two Wheeler",
        },
        {
            id: 2,
            name: "Four Wheeler",
        },
        {
            id: 3,
            name: "Commercial",
        },
        {
            id: 4,
            name: "Construction Equipment",
        },
        {
            id: 5,
            name: "Three Wheeler",
        },
        {
            id: 6,
            name: "Farm Equipment",
        },
    ];
    const selectAssetChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setAssetType(text);
    };
    const handlesubmit = async (e) => {
        e.preventDefault();
        if (assetType === '') {
            alert('Please select asset type')
        }else{
            if (assetType === 'Four Wheeler') {
                navigate('/admin/addfourwheelercase')
            }else if(assetType === 'Two Wheeler'){
                navigate('/admin/addtwowheelercase')
            }else if(assetType === 'Commercial'){
                navigate('/admin/addcommercialcase')
            }else if(assetType === 'Construction Equipment'){
                navigate('/admin/addconstructioncase')
            }else if(assetType === 'Three Wheeler'){
                navigate('/admin/addthreewheelercase')
            }else if(assetType === 'Farm Equipment'){
                navigate('/admin/addfarmequipmentcase')
            }
        }
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-2 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-2 px-5"
                        style={{backgroundColor:'#4D44B5',color:'#fff',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}
                        >
                            <h4 className="text-lg font-medium text-default-950 capitalize" style={{color:'#fff'}}>
                                Choose Asset Type
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-2 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fAssetType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Asset Type *
                                    </label>
                                    <select
                                        className={
                                            assetTypeError === ""
                                                ? `border-default-200 w-full`
                                                : `border-default-200 w-full error_class`
                                        }
                                        data-trigger
                                        id="fAssetType"
                                        placeholder="This is a search placeholder"
                                        value={selectAssetType.id}
                                        name={selectAssetType.name}
                                        onChange={(e) => selectAssetChange(e)}
                                    >
                                        <option value="0" hidden selected>
                                            Choose Asset Type
                                        </option>
                                        {selectAssetType.map((test, index) => (
                                            <option
                                                key={index}
                                                value={test.id}
                                                name={test.name}
                                                required
                                            >
                                                {test.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex items-center gap-2 justify-end"
                        style={{ marginBottom: "60px" }}
                    >
                        <button
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                            style={{
                                background: "#8481ff",
                                border: "none",
                                outline: "none",
                            }}
                            onClick={(e) => handlesubmit(e)}
                        >
                            <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChooseCase;
