import React, { useState } from 'react';
import axios from 'axios';
import { Baseurl } from '../config/BaseUrl';

const MediaUploadAndDisplay = () => {
  // State variables to store file paths
  const [imagePath, setImagePath] = useState('');
  const [videoPath, setVideoPath] = useState('');
  const [selectedMedia, setSelectedMedia] = useState(null); // For showing selected media

  // Handle file upload
  const handleFileUpload = async (e, type) => {
    console.log(e.target.files,'e.target.files');
    const formData = new FormData();
    formData.append('file', e.target.files[0]); // 'file' should match multer's fieldname
    console.log(formData,'formData');
    try {
      const res = await axios.post(`${Baseurl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(res.data,'res.data');
      console.log(res.data.filePath,'res.data.filePath');
      // Store the path under the appropriate variable
      if (type === 'image') {
        setImagePath(res.data.filePath);
      } else if (type === 'video') {
        setVideoPath(res.data.filePath);
      }
      alert('file uploaded')
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };


  // Display the selected media (image or video)
  const handleDisplayMedia = (mediaType) => {
    if (mediaType === 'image') {
      setSelectedMedia(imagePath);
    } else if (mediaType === 'video') {
      setSelectedMedia(videoPath);
    }
  };
  console.log(selectedMedia, 'selectedMedia');

  return (
    <div style={{ paddingLeft: '250px' }}>
      <h2>Upload and Display Media</h2>

      {/* Upload buttons for image and video */}
      <div>
        <h3>Upload an Image</h3>
        <input type="file" onChange={(e) => handleFileUpload(e, 'image')} accept="image/*" />
        {/* <input type="file" multiple onChange={handleFileChange} /> */}
      </div>

      <div>
        <h3>Upload a Video</h3>
        <input type="file" onChange={(e) => handleFileUpload(e, 'video')} accept="video/*" />
      </div>

      {/* Display buttons to show media */}
      <div>
        <button onClick={() => handleDisplayMedia('image')} disabled={!imagePath}>
          Show Uploaded Image
        </button>
        <button onClick={() => handleDisplayMedia('video')} disabled={!videoPath}>
          Show Uploaded Video
        </button>
      </div>

      {/* Media display section */}
      {selectedMedia && (
        <div>
          {selectedMedia.endsWith('.mp4') ? (
            <video width="600" height='200' controls>
              <source src={selectedMedia} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={selectedMedia} alt="Uploaded media" width="600" />
          )}
        </div>
      )}
    </div>
  );
};

export default MediaUploadAndDisplay;
