import React, { useEffect, useState } from 'react'
import { FaChevronRight } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../../component/layout/Footer';
import ClientHeader from './ClientHeader';

const ClientDashboard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { employeeList } = useSelector((store) => store.employee);
    const { caseCreated, pendingCases, submittedCases, readyReport } = useSelector((store) => store.cases);
    const [todaySubmitCase, setTodaySubmitCase] = useState([])
    const [todayReadyReport, setTodayReadyReport] = useState([])

    useEffect(() => {
        let date = new Date();

        // Filter cases submitted today
        const todayCases = submittedCases.filter((individualCase) => {
            const date2 = new Date(individualCase.createdAt);
            // Compare the dates
            return (
                date.getDate() === date2.getDate() &&
                date.getMonth() === date2.getMonth() &&
                date.getFullYear() === date2.getFullYear()
            );
        });

        // Set today's submitted cases
        setTodaySubmitCase(todayCases);
    }, [submittedCases]);

    useEffect(() => {
        let date = new Date();

        // Filter cases submitted today
        const todayCases = readyReport.filter((individualCase) => {
            const date2 = new Date(individualCase.createdAt);
            // Compare the dates
            return (
                date.getDate() === date2.getDate() &&
                date.getMonth() === date2.getMonth() &&
                date.getFullYear() === date2.getFullYear()
            );
        });

        // Set today's Ready Report
        setTodayReadyReport(todayCases);
    }, [readyReport]);


    const handleSubmitCase = () => {
        if (todaySubmitCase.length > 0) {
            navigate('/admin/todaysubmittedcases')
        } else {
            toast('Oops no submited cases for today!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    backgroundColor: '#ff3333', // Set background color to red
                    color: 'white', // Set text color to white
                },
                transition: Bounce,
            });
        }
    }
    const handleReadyReport = () => {
        if (todayReadyReport.length > 0) {
            navigate('/admin/todayreadyreport')
        } else {
            toast('Oops no ready reports are there !', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    backgroundColor: 'red', // Set background color to red
                    color: 'white', // Set text color to white
                },
                transition: Bounce,
            });
        }
    }

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">Dashboard</h4>
                        <ol aria-label="Breadcrumb" className="hidden md:flex items-center whitespace-nowrap min-w-0 gap-2">
                            <li className="text-sm">
                                <a className="flex items-center gap-2 align-middle text-default-800 font-medium transition-all hover:text-primary-500" href="javascript:void(0)">
                                    Instavaluer
                                    <FaChevronRight />
                                </a>
                            </li>
                            <li className="text-sm">
                                <a className="flex items-center gap-2 align-middle text-default-800 font-medium transition-all hover:text-primary-500" href="javascript:void(0)">
                                    Client
                                    <FaChevronRight />
                                </a>
                            </li>
                            <li aria-current="page" className="text-sm font-medium text-primary-600 truncate hover:text-primary-500">
                                Dashboard
                            </li>
                        </ol>
                    </div>
                    <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-6" style={{ marginBottom: '60px' }}>
                        <div className="shadow rounded-lg bg-white dark:bg-default-50">
                            <div className="p-5">
                                <div className="flex flex-wrap items-center justify-between">
                                    <div>
                                        <p className="text-base font-semibold text-default-900">
                                            Report Ready
                                        </p>
                                        <span className="inline-block text-2xl font-bold text-default-950 mt-3">{readyReport.length}</span>
                                    </div>
                                    <span className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80" style={{ overflow: 'hidden' }}>
                                        <img src="/assets/checklist.jpg" alt style={{ height: '100%', width: '100%', objectPosition: 'center' }} />
                                    </span>
                                </div>
                                <Link to='/admin/readyreport' className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2">View data
                                    <i data-lucide="move-right" className="h-4 w-4 inline align-middle ms-1" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default ClientDashboard