import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import { adminPost, valiadteEmail, valiadteNumber } from "../../redux/Authentication/AuthSlice";
import { createEmployee, emailExists, mobileExists } from "../../redux/Employee/employeeSlice";
import { clientEmailExists, clientMobileExists, createClient } from "../../redux/ClientAuthentication/ClientSlice";
// import {
//   adminPost,
//   valiadteEmail,
//   valiadteNumber,
// } from "../../../redux/Authentication/AuthSlice";

const AddEmployee = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [Cnfemail, setCnfEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailExistError, setEmailExistError] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileCnf, setMobileCnf] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [mobileExistError, setMobileExistError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [organistaionName, setOrganistaionName] = useState("");
    const [organistaionNameError, setOrganistaionNameError] = useState("");
    const [Password, setPassword] = useState("");
    const [PasswordError, setPasswordError] = useState("");
    const [twoWheelerCharge, setTwoWheelerCharge] = useState('');
    const [threeWheelerCharge, setThreeWheelerCharge] = useState('');
    const [FourWheelerCharge, setFourWheelerCharge] = useState('');
    const [constructionEqpCharge, setConstructionEqpCharge] = useState('');
    const [comvehicleSCVCharge, setComvehicleSCVCharge] = useState('');
    const [comvehicleHCVCharge, setComvehicleHCVCharge] = useState('');
    const [comvehicleLCVCharge, setComvehicleLCVCharge] = useState('');
    const [farmEqpCharge, setFarmEqpCharge] = useState('');

    const [role, setRole] = useState("")
    const [roleError, setRoleError] = useState("")

    const [btnPress, setbtnPress] = useState(false);

    useEffect(() => {
        if (btnPress === true) {
            if (name === "") {
                setNameError("please enter title");
            } else {
                setNameError("");
            }
            if (Password === "") {
                setPasswordError("Please enter Password");
            } else {
                setPasswordError("");
            }
            if (Cnfemail === "") {
                setEmailError("please enter email");
            } else {
                setEmailError("");
            }
            if (mobileCnf.length !== 10) {
                setMobileError("please enter mobile");
            } else {
                setMobileError("");
            }
            if (role === "") {
                setRoleError("Please select one of the role")
            } else {
                setRoleError("")
            }
            if (organistaionName === '') {
                setOrganistaionNameError('please enter organisation name')
            } else {
                setOrganistaionNameError('')
            }
        }
    }, [btnPress, name, Password, Cnfemail, mobileCnf, role, organistaionName]);

    const setNumberChange = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMobileError("");
        setMobileExistError('')
        setMobileCnf(value)
        if (value.length === 10) {
            const verifyNumber = await dispatch(clientMobileExists(value));
            if (verifyNumber.payload.success) {
                setMobileError("Number already exist");
                setMobileExistError("Mobile number already exists");
            } else {
                setMobileExistError("");
                setMobileError("");
                setMobileCnf(value);
            }
        } else {
            setMobileError("");
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const emailChange = async (email) => {
        setEmail(email);
        setEmailError('');
        setEmailExistError('');

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        const verifyEmail = await dispatch(clientEmailExists(email));

        if (verifyEmail.payload.success) {
            setEmailExistError('Email already exists.');
            setEmailError('Email already exists.')
        } else {
            setCnfEmail(email);
        }
    };


    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/;
        if (!passwordRegex.test(password)) {
            setPasswordError('Password must be at least 8 characters long and contain at least one number and one special character.');
        } else {
            setPasswordError('');
        }
    };

    const handleKeyUp = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        // Validate password on each keyup event
        // validatePassword(newPassword);
    };

    const handleBlur = () => {
        // Validate password on blur event
        validatePassword(Password);
    };

    const handleChange = (e, vehicleType) => {
        const inputValue = e.target.value;

        const setCharge = {
            twoWheelerCharge: setTwoWheelerCharge,
            threeWheelerCharge: setThreeWheelerCharge,
            FourWheelerCharge: setFourWheelerCharge,
            constructionEqpCharge: setConstructionEqpCharge,
            comvehicleSCVCharge: setComvehicleSCVCharge,
            comvehicleHCVCharge: setComvehicleHCVCharge,
            comvehicleLCVCharge: setComvehicleLCVCharge,
            farmEqpCharge: setFarmEqpCharge,
        }[vehicleType];

        if (!isNaN(inputValue) && inputValue.trim() !== '') {
            setCharge(inputValue);
        } else {
            alert("Please enter a valid number");
            setCharge('');
        }
    };

    const cancelClick = async (e) => {
        e.preventDefault();
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setPassword("");
        setRole("")
        setOrganistaionName('')
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        if (name === "") {
            setNameError("please enter title");
        } else {
            setNameError("");
        }
        if (Password === "") {
            setPasswordError("Please enter Password");
        } else {
            setPasswordError("");
        }
        if (Cnfemail === "") {
            setEmailError("please enter email");
        } else {
            setEmailError("");
        }
        if (mobileCnf.length !== 10) {
            setMobileError("please enter mobile");
        } else {
            setMobileError("");
        }
        if (role === "") {
            setRoleError("Please select role")
        } else {
            setRoleError("")
        }
        if (organistaionName === '') {
            setOrganistaionNameError('please enter organisation name')
        } else {
            setOrganistaionNameError('')
        }
        if (name !== "" && Password !== "" && Cnfemail !== "" && mobileCnf !== "" && role !== "" && organistaionName !== '') {
            const formData = {
                name: name,
                password: Password,
                role: role,
                email: Cnfemail,
                phone: mobileCnf,
                organisation: organistaionName,
                twoWheelerCharge:twoWheelerCharge,
                fourWheelerCharge:FourWheelerCharge,
                threeWheelerCharge:threeWheelerCharge,
                constructionEqpCharge:constructionEqpCharge,
                farmEqpCharge:farmEqpCharge,
                commercialSCVVehicleCharge:comvehicleSCVCharge,
                commercialHCVVehicleCharge:comvehicleHCVCharge,
                commercialLCVVehicleCharge:comvehicleLCVCharge
            };
            const admin = await dispatch(createClient(formData));
            if (admin.payload.success) {
                alert(`${role} created successfully`);
                setEmail("");
                setCnfEmail("");
                setMobile("");
                setMobileCnf("");
                setName("");
                setPassword("");
                setRole("")
                setOrganistaionName('')
                setTwoWheelerCharge('')
                setThreeWheelerCharge('')
                setFourWheelerCharge('')
                setComvehicleSCVCharge('')
                setComvehicleHCVCharge('')
                setComvehicleLCVCharge('')
                setConstructionEqpCharge('')
                setFarmEqpCharge('')
                setbtnPress(false)
            }
        } else {
            setbtnPress(true);
        }
    };
    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-2 px-5" style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                            <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                                Add Client Details
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-3 gap-4">
                                <div className="space-y-1">

                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Name :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Name"
                                            variant="outlined"
                                            className={
                                                nameError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        // className={classes.textfield}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Email :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Email"
                                            variant="outlined"
                                            className={
                                                emailError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={email}
                                            onChange={(e) => emailChange(e.target.value)}
                                        />
                                    </div>
                                    <div>{emailExistError}</div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Phone :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Phone"
                                            variant="outlined"
                                            className={
                                                mobileError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            minLength={10}
                                            maxLength={10}
                                            value={mobileCnf}
                                            onChange={(e) => setNumberChange(e)}
                                        />
                                    </div>
                                    <div>{mobileExistError}</div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >

                                        Password :
                                    </label>
                                    <div className="textfieldcontainer" >
                                        <TextField
                                            label="Password"
                                            variant="outlined"
                                            className={
                                                PasswordError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={Password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyUp={handleKeyUp}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <div>
                                        {PasswordError && (
                                            <div className="text-red-500 text-xs mt-1">{PasswordError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Organisation :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Organisation"
                                            variant="outlined"
                                            className={
                                                organistaionNameError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={organistaionName}
                                            onChange={(e) => setOrganistaionName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-2 px-5">
                            <div className="flex items-center justify-between py-2">
                                <h4 className="text-lg font-medium text-default-950 capitalize"
                                    style={{ fontWeight: '600', color: '#000066' }}
                                >
                                    Charges for various vehicle:
                                </h4>
                            </div>
                            <div className="grid md:grid-cols-3 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Two Wheeler Charge :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Two Wheeler Charge"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={twoWheelerCharge}
                                            onChange={(e) => handleChange(e, "twoWheelerCharge")}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Three Wheeler Charge :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Three Wheeler Charge"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={threeWheelerCharge}
                                            onChange={(e) => handleChange(e, "threeWheelerCharge")}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Four Wheeler Charge :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Four Wheeler Charge"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={FourWheelerCharge}
                                            onChange={(e) => handleChange(e, "FourWheelerCharge")}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Constuction Eqp Charge :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Constuction Eqp Charge"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={constructionEqpCharge}
                                            onChange={(e) => handleChange(e, "constructionEqpCharge")}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Com Vehicle SCV Charge :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Com Vehicle Charge"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={comvehicleSCVCharge}
                                            onChange={(e) => handleChange(e, "comvehicleCharge")}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Com Vehicle HCV Charge :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Com Vehicle Charge"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={comvehicleHCVCharge}
                                            onChange={(e) => handleChange(e, "comvehicleCharge")}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Com Vehicle LCV Charge :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Com Vehicle Charge"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={comvehicleLCVCharge}
                                            onChange={(e) => handleChange(e, "comvehicleCharge")}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="datepicker-basic"
                                        className="text-sm font-medium text-default-900"
                                        style={{ fontWeight: '600', color: '#000066' }}
                                    >
                                        Farm Eqp Charge :
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Farm Eqp Charge"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            type="text"
                                            id="text"
                                            value={farmEqpCharge}
                                            onChange={(e) => handleChange(e, "farmEqpCharge")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between py-2 px-5">
                            <h4 className="text-lg font-medium text-default-950 capitalize"
                                style={{ fontWeight: '600', color: '#000066' }}
                            >
                                Role Assign
                            </h4>
                        </div>
                        <div className="px-5">{roleError !== "" ? (<><p style={{ color: 'red' }}>Please select one of the role</p></>) : (<></>)}</div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-3 gap-4">
                                <div className="space-y-1">
                                    <div className="radio-wrapper-6">
                                        <input
                                            className="tgl tgl-light"
                                            id="manager"
                                            type="radio"
                                            name="role"
                                            value="client"
                                            checked={role === "client"}
                                            onChange={(e) => {
                                                setRole(e.target.value);
                                            }}
                                        />
                                        <label
                                            className="text-sm font-medium text-default-900 ps-2"
                                            htmlFor="manager">Client
                                        </label>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="radio-wrapper-6">
                                        <input
                                            className="tgl tgl-light"
                                            id="agent"
                                            type="radio"
                                            name="role"
                                            value="customer"
                                            checked={role === "customer"}
                                            onChange={(e) => {
                                                setRole(e.target.value);
                                            }}
                                        />
                                        <label
                                            className="text-sm font-medium text-default-900 ps-2"
                                            htmlFor="agent">
                                            Customer
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '60px' }}>
                        <button
                            onClick={(e) => cancelClick(e)}
                            style={{ background: "#ef4444", outline: 'none', border: 'none' }}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                        >
                            <i className="ph-bold ph-x text-lg" /> Reset
                        </button>
                        <button
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                            style={{ background: "#4d44b5", outline: 'none', border: 'none' }}
                            onClick={(e) => handlesubmit(e)}
                        >
                            <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddEmployee;
